import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button as MaterialButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from '@material-ui/core';
import { Button } from '../common/Button';
import { dispatch } from '../../rematch';
import { SQUARE_STATUS } from '../../constants/square.constants';
import { PHOTO_TYPE } from '../../constants/newServiceVisit.constants';
import {
  areUnitsChosenValid,
  areAnnotationsComplete,
  getSelectedAnnotatableServiceIds,
  getServiceGroupsSelectedForConsents,
  areConsentsSigned,
  isAfterPhotoRequired,
  isBeforePhotoRequired,
  hasPhotosFromType,
} from '../../utils/newServiceVisit.util';
import { requiredOptionsAreIncluded } from '../../utils/global';
import { useStyles } from './footerButtons.styles';
import { IAnnotationsState } from '../../interfaces/annotation.interfaces';
import { ROUTES } from '../../constants/routes.constants';
import { hasAccessTo } from '../../utils/auth.utils';
import { READ_TODO_LIST } from '../../constants/actions.constants';

export const FooterButtons = ({
  serviceVisitId,
  patientId,
  scrollToInvalidStep,
  hasOldAnnotations,
  isFollowUpSelectedOnly,
  isRetailSelectedOnly,
  isPrescriptionRetailOrRetailSelectedOnly,
}: any) => {
  const history = useHistory();
  const classes = useStyles();
  const patientPhotos = useSelector(({ patient }: any) => patient.photos);
  const { serviceGroups, customerConsents } = useSelector(({ patient }: any) => patient);
  const annotations = useSelector((store: any) => store.annotations as IAnnotationsState);
  const newServiceVisit = useSelector((state: any) => state.newServiceVisit);
  const permissions = useSelector(({ auth }: any) => auth.permissions);
  const {
    serviceVisit,
    signedStandingOrders,
    selectedServices,
    checkout,
    services,
    totalServicesUnits,
    totalCreditServicesUnits,
    selectedServiceGroups,
  } = newServiceVisit;

  const selectedServiceGroupsForConsents = getServiceGroupsSelectedForConsents(
    selectedServices,
    totalServicesUnits,
    services,
    serviceGroups
  );

  const { transactionStatus } = checkout;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [discardInProgress, setDiscardInProgress] = useState<boolean>(false);

  const backToPatientProfile = () => {
    setIsOpen(false);
    setDiscardInProgress(false);
    history.push(ROUTES.PATIENT_ID(patientId), { from: history.location.pathname });
  };

  const getCurrentUseServicesForStandingOrders = () => {
    const result: { [key: number]: boolean } = {};
    selectedServices?.forEach((serviceId: number) => {
      const service = services.find(
        ({ id, hasStandingOrder }: any) => id === serviceId && +totalServicesUnits[serviceId] > 0 && hasStandingOrder
      );

      result[serviceId] = !!service;
    });

    return selectedServices.filter((serviceId: number) => +totalServicesUnits[serviceId] > 0 && result[serviceId]);
  };

  const isValid = (): boolean => {
    // TODO: Add all Service visit validation in newServiceVisit.utils.ts
    const currentUseServices = selectedServices.filter((serviceId: number) => +totalServicesUnits[serviceId] > 0);
    const currentAnnotatableSelectedServices = getSelectedAnnotatableServiceIds(currentUseServices, services);
    const photosIds = serviceVisit.photos.map(({ photoId }: any) => photoId);
    const hasBeforePhotos = hasPhotosFromType(patientPhotos, photosIds, PHOTO_TYPE.BEFORE);
    const hasAfterPhotos = hasPhotosFromType(patientPhotos, photosIds, PHOTO_TYPE.AFTER);

    const isUnitsStepValid = areUnitsChosenValid(totalServicesUnits, totalCreditServicesUnits);
    const isConsentStepValid =
      isRetailSelectedOnly ||
      isFollowUpSelectedOnly ||
      areConsentsSigned(customerConsents, serviceGroups, selectedServiceGroupsForConsents, serviceVisitId);

    const isStandingOrderStepValid =
      isPrescriptionRetailOrRetailSelectedOnly ||
      isFollowUpSelectedOnly ||
      requiredOptionsAreIncluded(getCurrentUseServicesForStandingOrders(), signedStandingOrders);

    const isBeforePhotoStepValid = !isBeforePhotoRequired(selectedServiceGroups, serviceGroups, hasBeforePhotos);
    const isAfterPhotoStepValid = !isAfterPhotoRequired(selectedServiceGroups, serviceGroups, hasAfterPhotos);

    const isAnnotationStepValid =
      hasOldAnnotations ||
      isFollowUpSelectedOnly ||
      !hasAccessTo(READ_TODO_LIST, permissions) ||
      areAnnotationsComplete(currentAnnotatableSelectedServices, annotations);

    return (
      isUnitsStepValid &&
      isConsentStepValid &&
      isStandingOrderStepValid &&
      isBeforePhotoStepValid &&
      isAfterPhotoStepValid &&
      isAnnotationStepValid
    );
  };

  const isValidTransaction = () => transactionStatus === SQUARE_STATUS.OK;

  const handleClose = () => {
    setIsOpen(false);
  };

  const submitServiceVisit = () => {
    dispatch({
      type: 'newServiceVisit/submitReviewRequest',
      payload: {
        serviceVisitId,
        successCallback: () => {
          dispatch({ type: 'newServiceVisit/cleanServiceVisitData' });
          backToPatientProfile();
        },
      },
    });
  };

  const validateServiceVisit = () => {
    if (isValid() && isValidTransaction()) {
      setIsOpen(true);
    } else {
      dispatch({ type: 'newServiceVisit/serviceVisitDirty' });
      scrollToInvalidStep();
    }
  };

  return (
    <div className={classes.buttonsSection}>
      {serviceVisit.opened && (
        <>
          <Button title="REVIEW LATER" className={classes.reviewLaterButton} onClick={backToPatientProfile} />
          <Button title="SUBMIT SERVICE VISIT" onClick={validateServiceVisit} className={classes.submitButton} />
        </>
      )}
      <Dialog open={isOpen} onClose={handleClose}>
        <div className={classes.containerDialog}>
          <DialogTitle className={classes.title}>Submit service visit</DialogTitle>
          <DialogContent>
            <DialogContentText className={classes.content}>
              Are you sure you want to finalize and submit the service visit?
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <MaterialButton onClick={handleClose} className={classes.buttonLeft}>
              NO
            </MaterialButton>
            <MaterialButton
              data-cy="submitVisitBtn"
              onClick={submitServiceVisit}
              className={classes.buttonRight}
              disabled={discardInProgress}
            >
              {discardInProgress ? <CircularProgress /> : `YES, SUBMIT`}
            </MaterialButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};
