import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { CardContent } from '@material-ui/core';
import { useStyles } from './index.styles';
import { NavigationBar } from '../../components/PatientProfile/NavigationBar';
import { useCustomerMembershipConsent } from '../../hooks/queries/useCustomerMembershipConsent';
import PatientDetailHeader from '../../components/PatientProfile/PatientDetailHeader';
import { PATIENT_INFO } from '../../constants/reactQuery.keys';
import Patients from '../../services/Patients';
import MembershipDetails from './components/MembershipDetails';
import StartMembership from './components/StartMembership';
import ISquareSubscription from '../../interfaces/ISquareSubscription';
import { BEAUTY_BANK_SUBSCRIPTION_STATUS } from '../../constants/beautyBank.constants';
import { MultipleSkeleton } from '../../components/common/LoadingSkeleton';
import { PendingMembershipDetails } from './components/PendingMembershipDetails';

const PatientManageMembership = () => {
  const classes = useStyles();
  const history = useHistory();
  const { patientId } = useParams<{ patientId: string }>();
  const { data: customerMembershipConsent, isLoading } = useCustomerMembershipConsent(Number(patientId));
  const [customerSubscription, setCustomerSubscription] = useState<ISquareSubscription>();
  const {
    data: patient,
    isLoading: patientLoading,
    refetch,
  } = useQuery([PATIENT_INFO, patientId], () => Patients.getPatientInfo(patientId), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const { metadataPdfUrl } = customerMembershipConsent || {};

  useEffect(() => {
    const subscription = patient?.basicInfo?.subscription;
    if (subscription && subscription.status === BEAUTY_BANK_SUBSCRIPTION_STATUS.ACTIVE) {
      setCustomerSubscription(subscription);
    } else if (
      subscription &&
      subscription.status === BEAUTY_BANK_SUBSCRIPTION_STATUS.CREATED &&
      (!subscription.acknowledgedLastSquareError || !subscription.lastSquareError)
    ) {
      setCustomerSubscription(subscription);
    }
  }, [patient]);

  useEffect(() => {
    if (!isLoading && !customerMembershipConsent) {
      history.push(`/patient/${patientId}`);
    }
  }, [customerMembershipConsent, isLoading]);

  const navigateBack = () => {
    history.goBack();
  };

  if (patientLoading) {
    return <MultipleSkeleton />;
  }

  return (
    <div className={classes.mainContainer}>
      <div className={classes.navigationContainer}>
        <NavigationBar title="Manage Membership" onBackButtonClick={navigateBack} />
      </div>
      <PatientDetailHeader patient={patient} />
      <CardContent className={classes.card}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {customerSubscription ? (
          customerSubscription.status === BEAUTY_BANK_SUBSCRIPTION_STATUS.ACTIVE ? (
            <MembershipDetails
              patientId={patientId}
              practitionerId={patient?.contactInfo?.practitioner?.id}
              metadataPdfUrl={metadataPdfUrl}
              accountBalance={patient?.basicInfo?.currentBalance || 0}
              subscription={customerSubscription}
              customerCards={patient?.basicInfo.creditCards || []}
              refetch={refetch}
              paymentProcessor={patient?.basicInfo.paymentProcessor as string}
            />
          ) : (
            <PendingMembershipDetails
              patientId={patientId}
              subscription={customerSubscription}
              refetch={refetch}
              accountBalance={patient?.basicInfo?.currentBalance || 0}
              customerCards={patient?.basicInfo.creditCards || []}
              metadataPdfUrl={metadataPdfUrl}
              setCustomerSubscription={setCustomerSubscription}
              paymentProcessor={patient?.basicInfo.paymentProcessor as string}
            />
          )
        ) : (
          <StartMembership patient={patient} refetch={refetch} patientId={patientId} />
        )}
      </CardContent>
    </div>
  );
};

export default PatientManageMembership;
