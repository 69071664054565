import { yupResolver } from '@hookform/resolvers';
import { Grid, MenuItem, Box, CircularProgress, FormHelperText } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NewCreditCard from 'src/components/common/NewCreditCard';
import { EHRTextField, EHRSelect, EHRCheckboxWithText } from 'src/components/ui/v1';
import { useStates } from 'src/hooks/queries/useStates';
import { StepStatus } from 'src/interfaces/onboarding.interface';
import * as yup from 'yup';
// eslint-disable-next-line import/no-extraneous-dependencies
import Square from '@square/web-sdk';
import { STEP_COMPLETED, STEP_EMPTY, STEP_LOADING } from 'src/components/StepsTracker';
import CreditCardDetails from 'src/components/common/CreditCardDetails';
import { useUpdateMedspaAdminOnboardingProgress } from 'src/hooks/queries/useMedspaAdmins';
import { ONBOARDING_STEPS } from 'src/pages/MedspaAdmin/Onboarding/constants';
import { useSelector } from 'react-redux';
import { useAddCard } from 'src/hooks/queries/medspaAdmins/useMedspaPaymentMethods';
import { RootState } from 'src/rematch';
import { INewCard, IPaymentCard } from 'src/interfaces/IMedspaAdminList';
import { PAYMENT_AUTHORIZATION_AGREEMENT } from 'src/constants/onboardingAgreements.constant';
import { STRIPE_PAYMENT_PROCESSOR } from 'src/constants/general.constants';

interface PaymentMethodProps {
  stepId: number;
  onStepUpdate: (stepId: number, status: StepStatus) => void;
  progressPercentage?: number;
  isLoadingCcs?: boolean;
  refetchData?: () => void;
  creditCards?: IPaymentCard[];
}

const defaultValues = {
  cardholderName: '',
  billingAddress: {
    address: '',
    city: '',
    state: '',
  },
};

const PaymentMethodSchema = yup.object().shape({
  cardholderName: yup
    .string()
    .required('Cardholder name is required')
    .matches(/^[a-zA-Z\s]+$/, 'Cardholder Name can not contain numbers.'),
  billingAddress: yup.object().shape({
    address: yup.string().required('Address is required'),
    city: yup.string().required('City is required'),
    state: yup.string().required('State is required'),
  }),
});

const PaymentMethod = ({
  stepId,
  onStepUpdate,
  progressPercentage,
  creditCards,
  isLoadingCcs = false,
  refetchData,
}: PaymentMethodProps) => {
  const { paymentProcessor, userGroupId } = useSelector(({ auth }: RootState) => auth);

  const [cardOnFile, setCardOnFile] = useState<IPaymentCard | null>(null);
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const { data: states = [], isFetching: isFetchingStates } = useStates();
  const { mutateAsync: addNewCardMutation, isLoading, isError, isSuccess } = useAddCard(userGroupId ?? 0);
  const { mutateAsync: updateProgress } = useUpdateMedspaAdminOnboardingProgress();
  const [authorized, setAuthorized] = useState(false);

  const { control, errors, handleSubmit, trigger, watch } = useForm({
    resolver: yupResolver(PaymentMethodSchema),
    defaultValues,
  });

  const onDelete = async () => {
    setCardOnFile(null);
    onStepUpdate(stepId, STEP_EMPTY);
  };

  const onSubmit = async (formData: INewCard) => {
    await addNewCardMutation(formData);

    await updateProgress({
      stepName: ONBOARDING_STEPS.ACCOUNT_SETTINGS_NAME,
      percentage: progressPercentage as number,
    });
  };

  const handleSave = (details: Square.TokenDetails | undefined, token: string) => {
    const { postalCode, brand, expMonth, expYear } = { ...details?.billing, ...details?.card };

    trigger().then((isValid) => {
      if (isValid) {
        if (postalCode && brand && expMonth && expYear) {
          handleSubmit((formData) => {
            const { billingAddress, cardholderName } = formData;
            return onSubmit({
              cardNonce: token,
              cardholderName,
              billingAddress: {
                ...billingAddress,
                postalCode,
              },
            });
          })();
        }
      }
    });
  };

  const cardholderName = watch('cardholderName');
  const { city, state, address } = watch('billingAddress');

  useEffect(() => {
    setDisableButton(!cardholderName || !city || !state || !address);
  }, [cardholderName, city, state, address]);

  useEffect(() => {
    if (isLoading) {
      onStepUpdate(stepId, STEP_LOADING);
    } else if (isError) {
      onDelete();
    } else if (isSuccess) {
      refetchData?.();
    }
  }, [isLoading, isError, isSuccess]);

  useEffect(() => {
    if (isLoadingCcs) {
      onStepUpdate(stepId, STEP_LOADING);
    } else if (creditCards?.length) {
      setCardOnFile(creditCards?.[0]);
      onStepUpdate(stepId, STEP_COMPLETED);
    } else {
      onStepUpdate(stepId, STEP_EMPTY);
    }
  }, [creditCards?.length, isLoadingCcs]);

  const renderPaymentForm = () => {
    if (paymentProcessor === STRIPE_PAYMENT_PROCESSOR) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <p>Stripe component placeholder</p>
          </Grid>
        </Grid>
      );
    }

    return cardOnFile && <CreditCardDetails cardOnFile={cardOnFile} onDelete={onDelete} hideRemove />;
  };

  return (
    <Grid container spacing={2}>
      {cardOnFile ? (
        renderPaymentForm()
      ) : (
        <>
          <Grid item xs={12}>
            <EHRCheckboxWithText
              name="confirm"
              dataCy="transactions_authorization_checkbox"
              label="Future transactions and charges authorization."
              checked={authorized}
              onChange={() => setAuthorized((prev) => !prev)}
              disabled={isLoading || isLoadingCcs}
              text={PAYMENT_AUTHORIZATION_AGREEMENT}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="cardholderName"
              render={({ onChange, value }) => (
                <EHRTextField
                  label="Cardholder Name"
                  error={!!errors?.cardholderName}
                  helperText={errors?.cardholderName?.message}
                  value={value}
                  onChange={onChange}
                  dataCy=""
                  placeholder="As Shown on Card"
                  disabled={isLoading}
                  onKeyPress={(event) => {
                    if (!/^[a-zA-Z\s]+$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="billingAddress.address"
              render={({ onChange, value }) => (
                <EHRTextField
                  label="Address"
                  error={!!errors?.billingAddress?.address}
                  helperText={errors?.billingAddress?.address?.message}
                  value={value}
                  onChange={onChange}
                  dataCy=""
                  disabled={isLoading}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="billingAddress.city"
              control={control}
              render={({ onChange, value }) => (
                <EHRTextField
                  data-testid="input-medspa-city"
                  dataCy="input-medspa-city"
                  onChange={onChange}
                  value={value}
                  fullWidth
                  label="City"
                  disabled={isLoading}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="billingAddress.state"
              control={control}
              render={({ onChange, value }) => (
                <EHRSelect
                  data-testid="select-medspa-state"
                  dataCy="select-medspa-state"
                  displayEmpty
                  onChange={onChange}
                  value={value}
                  fullWidth
                  label="State"
                  error={!!errors?.billingAddress?.state}
                  helperText={errors?.billingAddress?.state?.message}
                  disabled={isFetchingStates || isLoading}
                  startAdornment={
                    isFetchingStates ? (
                      <Box width="40px" textAlign="center">
                        {' '}
                        <CircularProgress size={20} />
                      </Box>
                    ) : null
                  }
                >
                  {(states || []).map((st) => (
                    <MenuItem key={st.code} value={st.code}>
                      {st.name}
                    </MenuItem>
                  ))}
                </EHRSelect>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <NewCreditCard onSave={handleSave} disabled={disableButton || isLoading || isLoadingCcs || !authorized} />
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <FormHelperText>
          This payment information is used to execute orders that you request with suppliers and process any payments
          for services that you request from Portrait, like GFEs or advertising.
        </FormHelperText>
      </Grid>

      <Grid item xs={12}>
        <FormHelperText error={isError}>
          {isError ? 'There was an error submitting credit card. Please try again.' : ''}
        </FormHelperText>
      </Grid>
    </Grid>
  );
};

export default PaymentMethod;
