import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { assignOrderStatus, orderHasShipped } from 'src/utils/inventoryOrdering';
import { DATE_FORMAT_FULL } from 'src/constants/clientLead.constants';
import { Error as ErrorIcon, MoreVert as MoreVertIcon, Close as CloseIcon } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { RootState, dispatch } from 'src/rematch';
import { USER_TYPES } from 'src/constants/general.constants';
import { MEDSPA_ADMIN_NEW_ORDER } from 'src/routes/medspaAdminRoutes';
import { useMedspaPractitioners } from 'src/hooks/queries/useMedspaAdmins';
import Page from 'src/components/common/Page';
import useCurrentUserGroup from 'src/hooks/queries/useUserGroups';
import { FEATURE_CONFIRM_DELIVERY } from 'src/constants/features.constants';
import { hasFeatureEnabled } from 'src/utils/auth.utils';
import { OrderCharges } from 'src/components/DashboardPractitioner/Tabs/OrderingTab/OrderCharges';
import {
  InventoryOrderService,
  OrderHistoryRowParams,
  OrderStatusParams,
  ConfirmationDialogState,
  InventoryOrderWithId,
} from '../../../../../interfaces/IInventoryOrder';
import IconCross from '../../../../common/IconCross';
import { IconLeftAccordionSummary, TableCellHeader, useStyle } from '../inventoryOrder.styles';
import {
  useCancelOrder,
  useFinalizeOrder,
  useGetOrderingStatus,
  useInventoryOrders,
} from '../../../../../hooks/queries/useInventoryOrders';
import { ROUTES } from '../../../../../constants/routes.constants';
import OrderStatus from '../OrderStatus';
import ConfirmDelivery from './ConfirmDelivery';
import OrderHistorySubRow from './OrderHistorySubRow';
import { DeliveryDetails } from './DeliveryDetails';
import OrderLoading from '../OrderLoading';
import OrderTrackingNumbers from '../OrderTrackingNumbers';
import OrderConfirmationNumbers from '../OrderConfirmationNumbers';

const TableHeader = ({ status }: OrderStatusParams) => {
  const classes = useStyle();

  return (
    <TableHead>
      <TableRow className={classes.grayHeader}>
        <TableCellHeader>Product Name</TableCellHeader>
        <TableCellHeader>Current Stock</TableCellHeader>
        <TableCellHeader>Items per Box</TableCellHeader>
        <TableCellHeader>Boxes</TableCellHeader>
        <TableCellHeader>Price per Box</TableCellHeader>
        <TableCellHeader>Qty</TableCellHeader>
        <TableCellHeader>Total</TableCellHeader>
        {orderHasShipped(status) ? <TableCellHeader /> : null}
      </TableRow>
    </TableHead>
  );
};

const OrderHistoryRow = ({
  orderDetails,
  autoOpen,
  setDialogParams,
  setShowLoadingSpinner,
  showDeliveryConfirmation,
}: OrderHistoryRowParams) => {
  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);
  const [expanded, setExpanded] = useState<boolean>(false);

  const { userType } = useSelector(({ auth }: RootState) => auth);

  const { mutateAsync: cancelOrder } = useCancelOrder(orderDetails.id);
  const { mutateAsync: finalizeOrder } = useFinalizeOrder(orderDetails.id);

  const history = useHistory();

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setMenuAnchor(menuAnchor ? null : event.currentTarget);

  const handleCancelOrder = async (): Promise<void> => {
    setMenuAnchor(null);
    setShowLoadingSpinner?.(true);
    await cancelOrder();
    setShowLoadingSpinner?.(false);
  };

  const handleEditOrder = async (): Promise<void> => {
    setMenuAnchor(null);
    setShowLoadingSpinner?.(true);
    await finalizeOrder(false);
    history.push(userType === USER_TYPES.PRACTITIONER ? ROUTES.NEW_ORDER : MEDSPA_ADMIN_NEW_ORDER);
  };

  const classes = useStyle();

  const status = assignOrderStatus(orderDetails);

  useEffect(() => {
    setExpanded(autoOpen);
  }, []);

  const expandIcon = (
    <IconButton size="small" onClick={() => setExpanded(!expanded)} data-testid={`order expand ${orderDetails.id}`}>
      <IconCross open={expanded} />
    </IconButton>
  );

  return (
    <Accordion expanded={expanded}>
      <IconLeftAccordionSummary expandIcon={expandIcon}>
        <Box className={classes.accordionSummary}>
          <Typography className={classes.accordionTag}>Order #{orderDetails.referenceNumber}</Typography>
          <Typography className={classes.orderDate}>
            Placed on {moment.utc(orderDetails.finalizedAt ?? orderDetails.createdAt).format(DATE_FORMAT_FULL)}
          </Typography>
          <OrderStatus status={status} />
          <IconButton onClick={handleMenuClick} className={classes.orderMenu}>
            {menuAnchor ? <CloseIcon /> : <MoreVertIcon />}
          </IconButton>
          <Menu
            open={Boolean(menuAnchor)}
            anchorEl={menuAnchor}
            onClose={handleMenuClick}
            PaperProps={{ className: classes.orderMenuOpen }}
          >
            <MenuItem className={classes.orderMenuItem} disabled={!orderDetails.editable} onClick={handleEditOrder}>
              Edit
            </MenuItem>
            <MenuItem className={classes.orderMenuItem} disabled={!orderDetails.cancelable} onClick={handleCancelOrder}>
              Cancel
            </MenuItem>
          </Menu>
        </Box>
      </IconLeftAccordionSummary>

      <AccordionDetails style={{ flexDirection: 'column' }}>
        <Box display="flex" flexDirection="row" className={classes.deliveryNotice}>
          <ErrorIcon />
          <Typography component="p">
            Please note that someone must be able to receive the package on the day of the delivery. Deliveries can
            arrive as early as <span>8:00am</span>
          </Typography>
        </Box>

        <DeliveryDetails
          adminNote={orderDetails?.adminNote}
          desiredDeliveryDate={orderDetails.desiredDeliveryDate}
          totalCost={orderDetails?.total ?? 0}
          deliveryLocation={orderDetails?.deliveryLocation}
        />

        <TableContainer data-testid={`order details ${orderDetails.id}`}>
          <Table>
            <TableHeader status={status} />
            <TableBody className={classes.historyRow}>
              {(orderDetails.services as InventoryOrderService[]).map(
                (orderItem: InventoryOrderService): React.ReactElement => (
                  <OrderHistorySubRow
                    {...orderItem}
                    status={status}
                    key={orderItem.serviceId}
                    setDialogParams={setDialogParams}
                    showDeliveryConfirmation={showDeliveryConfirmation}
                  />
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <OrderCharges charges={orderDetails.charges} colFill={5} priceLabel="Amount" readOnly />

        {!!orderDetails.approved && (
          <>
            <OrderConfirmationNumbers
              confirmationNumbers={orderDetails.orderConfirmationNumbers ?? []}
              colFill={5}
              readOnly
            />
            <OrderTrackingNumbers trackingNumbers={orderDetails.shipmentTrackingNumbers ?? []} colFill={5} readOnly />
          </>
        )}
        {orderDetails.adminNote && (
          <Box className={classes.orderNotes}>
            <Paper className={classes.orderNotes} variant="outlined">
              <Typography>Admin Note: {orderDetails.adminNote}</Typography>
            </Paper>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

const OrderHistory: React.FC = (): React.ReactElement => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState<boolean>(false);
  const [dialogParams, setDialogParams] = useState<ConfirmationDialogState | undefined>();

  const { userType, userId, featureList } = useSelector(({ auth }: RootState) => auth);
  const isMedspaAdmin = userType !== USER_TYPES.PRACTITIONER;

  const { data: userGroupData, isLoading: isLoadingUserGroup } = useCurrentUserGroup(isMedspaAdmin);
  const { data: medspaPractitioners } = useMedspaPractitioners(isMedspaAdmin);

  const adminDeliveryConfirmationAllowed =
    isMedspaAdmin && userGroupData?.features?.some((feat) => feat.key === FEATURE_CONFIRM_DELIVERY);

  const showDeliveryConfirmation = isMedspaAdmin
    ? adminDeliveryConfirmationAllowed
    : hasFeatureEnabled(FEATURE_CONFIRM_DELIVERY, featureList);

  const practitionerId = isMedspaAdmin ? (medspaPractitioners ?? [])[0]?.id ?? 0 : userId;

  const { data: orderingStatus, isLoading: isLoadingStatus, isFetching: isFetchingStatus } = useGetOrderingStatus();
  const {
    data: orderData,
    isLoading: isLoadingHistory,
    isFetching: isFetchingHistory,
    isRefetching,
  } = useInventoryOrders();

  const orderHistory = orderData?.data?.inventoryOrders;

  const isLoading = isLoadingUserGroup || isLoadingHistory || isFetchingHistory || isRefetching;

  const history = useHistory();
  const classes = useStyle();

  const gotoOrderForm = (event: React.MouseEvent) => {
    event.preventDefault();
    history.push(userType === USER_TYPES.PRACTITIONER ? ROUTES.NEW_ORDER : MEDSPA_ADMIN_NEW_ORDER);
  };

  const toggleDialog = () => setDialogOpen(!dialogOpen);

  const launchDialog = (params: ConfirmationDialogState) => {
    setDialogParams(params);
    setDialogOpen(true);
  };

  useEffect(() => {
    !isMedspaAdmin &&
      dispatch({
        type: 'inventoryDefault/getDashboardLists',
        payload: practitionerId,
      });
  }, []);

  useEffect(() => {
    setShowLoadingSpinner(isLoading);
  }, [isLoading]);

  return (
    <Page title="Orders List">
      <Box className={classes.orderDashboard} data-testid="order history">
        <Box className={classes.orderFlash}>
          {isLoadingStatus || isFetchingStatus ? (
            <CircularProgress size={22} />
          ) : (
            orderingStatus?.active && (
              <Button data-cy="view" variant="outlined" onClick={gotoOrderForm} data-testid="current order button">
                Place an Order
              </Button>
            )
          )}
        </Box>
        {orderHistory?.map(
          (orderRow: InventoryOrderWithId, index: number): React.ReactElement => (
            <OrderHistoryRow
              key={orderRow.id}
              orderDetails={orderRow}
              autoOpen={index === 0}
              setDialogParams={launchDialog}
              setShowLoadingSpinner={setShowLoadingSpinner}
              showDeliveryConfirmation={showDeliveryConfirmation}
            />
          )
        )}
        {!isLoading && orderHistory?.length === 0 && (
          <Typography className={classes.noOrders} variant="h6">
            No Orders to Display
          </Typography>
        )}
        {dialogParams && <ConfirmDelivery open={dialogOpen} toggleView={toggleDialog} {...dialogParams} />}
        <OrderLoading open={showLoadingSpinner} />
      </Box>
    </Page>
  );
};

export { OrderHistory, OrderHistory as default };
