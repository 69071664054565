import {
  ORDER_STATUS_APPROVED,
  ORDER_STATUS_CANCELED,
  ORDER_STATUS_DELIVERED,
  ORDER_STATUS_ORDERED,
  ORDER_STATUS_PAID,
  ORDER_STATUS_PARTIALLY_DELIVERED,
  ORDER_STATUS_PARTIALLY_PLACED,
  ORDER_STATUS_PATRIALLY_SHIPPED,
  ORDER_STATUS_PENDING_PAYMENT,
  ORDER_STATUS_PLACED,
  ORDER_STATUS_REJECTED,
  ORDER_STATUS_REQUESTED,
  PAYMENT_STATUS_COMPLETED,
  SHIPPED_ORDER_STATUSES,
} from 'src/constants/inventory.constants';
import {
  InventoryOrderStatus,
  InventoryOrder,
  InventoryOrderItem,
  InventoryOrderService,
} from 'src/interfaces/IInventoryOrder';
import { uniqBy } from 'lodash';
import { PORTRAIT_LEGACY, PORTRAIT_LEGACY_PLUS } from 'src/constants/general.constants';

type OrderStateCheck<T = boolean> = (order: Partial<InventoryOrder>) => T;

const isDelivered: OrderStateCheck = (order) => order.purchaseOrderTotal === order.delivered;
const isPartiallyDelivered: OrderStateCheck = (order) => !!order.delivered;
const isPaid: OrderStateCheck = (order) => order.paymentStatus === PAYMENT_STATUS_COMPLETED;
const isPlaced: OrderStateCheck = (order) => !!order.orderConfirmationNumbers?.length;
const isShipped: OrderStateCheck = (order) => {
  const hasTrackingNumbers = !!order.shipmentTrackingNumbers?.length;
  const isValidOrder = !isLegacy(order) || !!order.purchaseOrderTotal;

  return isValidOrder && hasTrackingNumbers;
};

const isLegacy: OrderStateCheck = (order) =>
  order.roleName === PORTRAIT_LEGACY || order.roleName === PORTRAIT_LEGACY_PLUS;

const hasAllTrackingNumbers: OrderStateCheck = (order) => {
  const trackedSuppliers = order.shipmentTrackingNumbers?.map((tracking) => tracking.supplierName) || [];
  return !uniqBy(order.services, 'supplierName').filter((service) => trackedSuppliers.indexOf(service.supplierName) < 0)
    .length;
};
export const hasAllConfirmationNumbers: OrderStateCheck = (order) => {
  const confirmedSuppliers = order.orderConfirmationNumbers?.map((confirmation) => confirmation.supplierName) || [];
  return !uniqBy(order.services, 'supplierName').filter(
    (service) => confirmedSuppliers.indexOf(service.supplierName) < 0
  ).length;
};

const assignLegacyOrderStatus: OrderStateCheck<InventoryOrderStatus> = (order) => {
  if (isLegacy(order) && isPartiallyDelivered(order)) {
    return isDelivered(order) ? ORDER_STATUS_DELIVERED : ORDER_STATUS_PARTIALLY_DELIVERED;
  }
  if (isShipped(order)) {
    return hasAllTrackingNumbers(order) ? ORDER_STATUS_ORDERED : ORDER_STATUS_PATRIALLY_SHIPPED;
  }
  if (isPlaced(order)) {
    return hasAllConfirmationNumbers(order) ? ORDER_STATUS_PLACED : ORDER_STATUS_PARTIALLY_PLACED;
  }
  return ORDER_STATUS_APPROVED;
};

const assignFlexLiteOrderStatus: OrderStateCheck<InventoryOrderStatus> = (order) => {
  if (isShipped(order)) {
    return hasAllTrackingNumbers(order) ? ORDER_STATUS_ORDERED : ORDER_STATUS_PATRIALLY_SHIPPED;
  }
  if (isPlaced(order)) {
    return hasAllConfirmationNumbers(order) ? ORDER_STATUS_PLACED : ORDER_STATUS_PARTIALLY_PLACED;
  }

  return isPaid(order) ? ORDER_STATUS_PAID : ORDER_STATUS_PENDING_PAYMENT;
};

export const assignOrderStatus: OrderStateCheck<InventoryOrderStatus> = (order) => {
  if (order.canceled) {
    return ORDER_STATUS_CANCELED;
  }
  if (order.rejected) {
    return ORDER_STATUS_REJECTED;
  }
  if (!order.approved) {
    return ORDER_STATUS_REQUESTED;
  }
  if (isLegacy(order)) {
    return assignLegacyOrderStatus(order);
  }
  return assignFlexLiteOrderStatus(order);
};

export const assignSupplierStatus = (order: InventoryOrder, supplierId: number, orderItem?: InventoryOrderItem) => {
  if (order.canceled) {
    return ORDER_STATUS_CANCELED;
  }
  if (order.rejected) {
    return ORDER_STATUS_REJECTED;
  }
  if (order?.approvedSupplierIds?.indexOf(supplierId) < 0) {
    return ORDER_STATUS_REQUESTED;
  }
  if (!order.approved) {
    return ORDER_STATUS_APPROVED;
  }

  if (!!orderItem && (orderItem as InventoryOrderService).confirmed) {
    return ORDER_STATUS_DELIVERED;
  }

  const trackingNumberIds = order?.shipmentTrackingNumbers?.map((tracking) => tracking.supplierId);
  const confirmationNumberIds = order?.orderConfirmationNumbers?.map((confirmation) => confirmation.supplierId);

  if (isShipped(order) && trackingNumberIds.indexOf(supplierId) > -1) {
    return ORDER_STATUS_ORDERED;
  }
  if (confirmationNumberIds.indexOf(supplierId) > -1) {
    return ORDER_STATUS_PLACED;
  }
  if (isLegacy(order)) {
    return ORDER_STATUS_APPROVED;
  }
  return isPaid(order) ? ORDER_STATUS_PAID : ORDER_STATUS_PENDING_PAYMENT;
};

export const orderHasShipped = (status: InventoryOrderStatus): boolean => SHIPPED_ORDER_STATUSES.indexOf(status) > -1;
