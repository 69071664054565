import React, { FC, useEffect, useState } from 'react';
import { Box, FormControlLabel, FormHelperText, Grid, MenuItem, Typography, Switch, Modal } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useMutation } from 'react-query';
import { InsertLinkSharp as LinkSharpIcon } from '@material-ui/icons';
import Page from 'src/components/common/Page';
import { IUserGroup } from 'src/interfaces/IUserGroup';
import CredentialsDropdown from 'src/components/common/CredentialsDropdown';
import { EHRSelect, EHRTextField } from 'src/components/ui/v1';
import { NavigationBar } from '../../PatientProfile/NavigationBar';
import BreadcrumbsContainer from '../../common/Breadcrumb/BreadcrumbContainer';
import BreadcrumbText from '../../common/Breadcrumb/BreadcrumbText';
import { useStyles } from './Practitioner.styles';
import { schemaForm, schemaFormEdit } from './schemaForm';
import { ROLE as ROLE_ERROR } from '../../../constants/schemaForm.constants';
import { useCustomRoles } from '../../../hooks/queries/useCustomRoles';
import { PORTRAIT_LEGACY, PORTRAIT_LEGACY_PLUS, SNACKBAR_INFO, USER_TYPES } from '../../../constants/general.constants';
import { usePhysicians } from '../../../hooks/queries/usePhysicians';
import { useUserGroups } from '../../../hooks/queries/useUserGroups';
import ICommission from '../../../interfaces/ICommission';
import { IFeatureSetting } from '../../../interfaces/IFeatureSetting';
import { usePractitionerInfo } from '../../../hooks/queries/usePractitioners';
import { useServicesCommissions } from '../../../hooks/queries/useCommissions';
import { DEFAULT_FLEX_LUXE_FEATURES, DEFAULT_LEGACY_FEATURES } from '../../../constants/features.constants';
import { IRoles } from '../../../interfaces/IRoles.interfaces';
import UploadImageV2 from '../../common/UploadImageV2';
import { formatPhone } from '../../../utils/formatNumber';
import { IPractitionerPost } from '../../../interfaces/IPractitioner';
import { dispatch } from '../../../rematch';
import { ADMIN_PRACTITIONER_LIST_PATH } from '../../../routes/administratorRoutes';
import compile from '../../../utils/toastMessagesCompiler';
import { Practitioners } from '../../../services/Practitioners';
import { useCreatePractitioner } from '../../../hooks/practitioner/usePractitioner';
import { showSnackbar } from '../../../utils/global';
import { MultipleSkeleton } from '../../common/LoadingSkeleton';
import { copyToClipboard } from '../../../utils/clipboard.utils';
import { SIGN_UP_ROUTES } from '../../../constants/routes.constants';
import { hasAccessTo } from '../../../utils/auth.utils';
import { IS_SUPPORT } from '../../../constants/actions.constants';

const MyBreadcrumb = ({ editing }: { editing: boolean }) => (
  <Box mb="17px" paddingRight={5} style={{ backgroundColor: '#f2f5f5' }}>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <BreadcrumbsContainer>
        <BreadcrumbText text="Practitioners" linkTo="/administrator/practitioners" />
        <BreadcrumbText text={`${editing ? 'Edit' : 'New'} practitioner`} isActive />
      </BreadcrumbsContainer>
    </Box>
  </Box>
);

interface FormData {
  field: any;
  firstName: string;
  lastName: string;
  email: string;
  licenses: string;
  licensesInfo: string;
  position: string;
  phone: string;
  googleReview: string;
  yelpReview: string;
  squareLocationId: string;
  servicesCommission: number | string | null;
  activeForScheduling?: boolean;
  inactive?: boolean;
  signatureImage?: string | ArrayBuffer | null;
  profileImage?: string | ArrayBuffer | null;
  medicalDirectorId: number | null;
  requestCreditCard?: boolean;
  requestDeposit?: boolean;
  entityName: string;
  medicalGroup: string;
  roleId?: string | null;
  acuityUserId?: string | null;
  isEhrReady?: boolean;
  userGroupId?: number | null;
  doingBusinessAs?: string | null;
  enableAcuityEnterprise?: boolean | null;
}

const CreateOrEditPractitioners: FC = () => {
  const classes = useStyles();
  const [step, setStep] = useState<number>(1);
  const { user, userType, permissions = [] } = useSelector(({ auth }: any) => auth);
  const isMedspaProvider = Practitioners.isMedspaProvider();
  const { practitionerId }: any = useParams();

  const practitionerView = userType === USER_TYPES.PRACTITIONER;

  const focusPractitionerId = practitionerView ? user.id : practitionerId;
  const isSupport = hasAccessTo(IS_SUPPORT, permissions);
  const {
    practitionerInfo,
    refetch: refetchPractitionerInfo,
    isFetching,
    isLoading,
  } = usePractitionerInfo(focusPractitionerId);

  const { data: roles = [] as IRoles[] } = useCustomRoles({ userType: USER_TYPES.PRACTITIONER });
  const { data: physicians, isError: isErrorPhysicians } = usePhysicians();
  const { data: medspaGroups } = useUserGroups({
    disabled: practitionerView || !user?.id || isMedspaProvider,
  });
  const [practitionerFeatures, setPractitionerFeatures] = useState<IFeatureSetting[]>([]);
  const { data: commissions, isLoading: isLoadingCommissions } = useServicesCommissions();
  const editing: boolean = typeof focusPractitionerId !== 'undefined';
  const history = useHistory();
  const currentProviderRole = roles.find((role) => practitionerInfo?.roleId === role.id);
  const isLegacyProvider =
    currentProviderRole?.name === PORTRAIT_LEGACY || currentProviderRole?.name === PORTRAIT_LEGACY_PLUS;
  const successCallback = () => {
    if (practitionerView) {
      history.goBack();
    } else {
      history.push(ADMIN_PRACTITIONER_LIST_PATH);
    }
  };
  const mutationCreate = useCreatePractitioner(successCallback);
  const mutationEdit = useMutation(Practitioners.editPractitioner);
  const [saving, setSaving] = useState<boolean>(false);
  const [showEHRReadyModal, setShowEHRReadyModal] = useState<boolean>(false);
  const [showRoleWarningModal, setShowRoleWarningModal] = useState<boolean>(false);

  useEffect(() => {
    if (editing && !isFetching) {
      const {
        firstName,
        lastName,
        slug,
        email,
        phone,
        practitionerInformation,
        activeForScheduling,
        servicesCommission,
        inactive,
        squareLocationId,
        medicalDirectorId,
        roleId,
        acuityUserId,
        requestCreditCard,
        requestDeposit,
        featureSettings,
        entityName,
        medicalGroup,
        isEhrReady,
        userGroupId,
        doingBusinessAs,
      } = practitionerInfo;

      // @ts-ignore
      const googleReview = practitionerInformation?.socialMediaReviewsInfo?.google?.url || '';
      // @ts-ignore
      const yelpReview = practitionerInformation?.socialMediaReviewsInfo?.yelp?.url || '';

      setValue('firstName', firstName);
      setValue('lastName', lastName);
      setValue('slug', slug);
      // @ts-ignore
      setValue('email', email, { shouldTouch: true });
      setValue('phone', phone);
      setValue('licenses', practitionerInformation?.licenses);
      setValue('licensesInfo', practitionerInformation?.licensesInfo);
      setValue('position', practitionerInformation?.position);
      setValue('activeForScheduling', activeForScheduling);
      setValue('inactive', inactive);
      setValue('googleReview', googleReview);
      setValue('yelpReview', yelpReview);
      setValue('squareLocationId', squareLocationId);
      setValue('servicesCommission', servicesCommission);
      setValue('medicalDirectorId', medicalDirectorId);
      setValue('roleId', `${roleId ?? ''}`);
      setValue('acuityUserId', acuityUserId);
      setValue('requestCreditCard', requestCreditCard);
      setValue('requestDeposit', requestDeposit);
      setValue('entityName', entityName);
      setValue('medicalGroup', medicalGroup);
      setValue('isEhrReady', isEhrReady);
      setValue('userGroupId', userGroupId);
      setValue('doingBusinessAs', doingBusinessAs);

      if (featureSettings && featureSettings.length > 0) {
        setPractitionerFeatures(featureSettings.map(({ key, settings }) => ({ key, settings, enabled: true })));
      }
    } else {
      setValue('servicesCommission', commissions?.find((commission: ICommission) => commission.defaultCommission)?.id);
    }
  }, [practitionerInfo, commissions, isFetching]);

  if (isErrorPhysicians) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'fetching',
          element: 'physicians',
        }),
        type: 'error',
      },
    });
  }

  const { register, control, handleSubmit, errors, setValue, getValues, trigger, clearErrors, setError, watch } =
    useForm<FormData>({
      resolver: yupResolver(editing ? schemaFormEdit : schemaForm),
      defaultValues: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        licenses: '',
        licensesInfo: '',
        position: '',
        googleReview: '',
        yelpReview: '',
        squareLocationId: '',
        inactive: false,
        activeForScheduling: false,
        signatureImage: null,
        profileImage: null,
        servicesCommission: null,
        medicalDirectorId: null,
        roleId: null,
        acuityUserId: null,
        enableAcuityEnterprise: false,
        requestCreditCard: false,
        requestDeposit: false,
        entityName: '',
        doingBusinessAs: '',
        medicalGroup: '',
        isEhrReady: true,
        userGroupId: null,
      },
    });
  const watchRoleId = watch('roleId');
  const watchEnableAcuityEnterprise = watch('enableAcuityEnterprise');

  useEffect(() => {
    setValue('acuityUserId', '');
  }, [watchEnableAcuityEnterprise]);

  const practitionerRoleLabel = roles.find((role) => watchRoleId && role.id === +watchRoleId)?.name;
  const isNonLegacyPractitioner =
    practitionerRoleLabel &&
    practitionerRoleLabel !== PORTRAIT_LEGACY &&
    practitionerRoleLabel !== PORTRAIT_LEGACY_PLUS;

  useEffect(() => {
    if (!isLoading && !editing) {
      if (isNonLegacyPractitioner) {
        setPractitionerFeatures(
          DEFAULT_FLEX_LUXE_FEATURES.map((featureKey) => ({ key: featureKey, settings: {}, enabled: true }))
        );
      } else {
        setPractitionerFeatures(
          DEFAULT_LEGACY_FEATURES.map((featureKey) => ({ key: featureKey, settings: {}, enabled: true }))
        );
      }
    }
  }, [isNonLegacyPractitioner, isLoading, editing]);

  const handleCreatePractitioner = async (practitionerData: IPractitionerPost) => {
    showSnackbar(compile('practitioner.creating'), SNACKBAR_INFO);
    try {
      await mutationCreate.mutateAsync(practitionerData);
      refetchPractitionerInfo();
    } catch (error) {
      const {
        response: { data },
      } = error;
      if (data.error === 'Email already exists') {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('practitioner_email.error'),
            type: 'error',
            duration: 2500,
          },
        });
      } else if (data.error === 'Access forbidden to the requested location.') {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: 'Invalid Square location ID provided.',
            type: 'error',
            duration: 2500,
          },
        });
      } else {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'creating',
              element: 'practitioner',
            }),
            type: 'error',
            duration: 2500,
          },
        });
      }
    } finally {
      setSaving(false);
    }
  };

  const handleSetFee = (field: 'requestCreditCard' | 'requestDeposit', value: boolean) => {
    setValue(field, value);
  };

  const handleUpdatePractitioner = async (data: IPractitionerPost) => {
    try {
      await mutationEdit.mutateAsync(data);
      refetchPractitionerInfo();
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.success_message', {
            action: 'edited',
            element: 'Practitioner',
          }),
          type: 'success',
          duration: 2500,
        },
      });
    } catch (error) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'editing',
            element: `practitioner - ${error}`,
          }),
          type: 'error',
          duration: 2500,
        },
      });
    } finally {
      setSaving(false);
    }
  };

  const onSubmit = (dataForm: any) => {
    if (!isNonLegacyPractitioner && !getValues('medicalGroup')) {
      setError('medicalGroup', {
        type: 'custom',
        message: 'Medical Group is required',
      });
      return;
    }

    const data = {
      id: focusPractitionerId,
      firstName: dataForm.firstName.trim(),
      lastName: dataForm.lastName.trim(),
      email: dataForm.email.trim(),
      phone: formatPhone(dataForm.phone).trim(),
      squareLocationId: dataForm.squareLocationId,
      licenses: dataForm.licenses?.trim(),
      licensesInfo: dataForm.licensesInfo?.trim(),
      position: dataForm.position?.trim() || '',
      inactive: dataForm.inactive,
      activeForScheduling: dataForm.activeForScheduling,
      signatureImage: dataForm.signatureImage,
      profileImage: dataForm.profileImage,
      servicesCommission: dataForm.servicesCommission,
      medicalDirectorId: dataForm.medicalDirectorId,
      roleId: dataForm.roleId,
      acuityUserId: dataForm.acuityUserId,
      enableAcuityEnterprise: dataForm.enableAcuityEnterprise,
      requestCreditCard: dataForm.requestCreditCard,
      requestDeposit: dataForm.requestDeposit,
      featureSettings: practitionerFeatures.filter(({ enabled }) => enabled),
      entityName: dataForm.entityName,
      googleReview: dataForm.googleReview?.trim(),
      yelpReview: dataForm.yelpReview?.trim(),
      medicalGroup: dataForm.medicalGroup?.trim(),
      isEhrReady: dataForm.isEhrReady,
      userGroupId: dataForm.userGroupId,
      doingBusinessAs: dataForm.doingBusinessAs,
    };

    setSaving(true);
    if (editing) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { squareLocationId, ...dataToEdit } = data;
      handleUpdatePractitioner(dataToEdit); // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, ...dataToCreate } = data;
      handleCreatePractitioner(dataToCreate);
    }
  };

  const handleNext = async () => {
    const valid = await trigger([
      'firstName',
      'lastName',
      'roleId',
      'email',
      'phone',
      'squareLocationId',
      'userGroupId',
      'profileImage',
    ]);
    if (valid) {
      setStep(step + 1);
      clearErrors();
    }
  };

  const isNaturopathic = getValues('licenses') === 'Naturopathic Doctor';

  const handleSaveAndContinue = async () => {
    setShowRoleWarningModal(false);
    /* eslint-disable no-unused-expressions */
    const dataToEdit = {
      id: focusPractitionerId,
      entityName: getValues('entityName'),
      firstName: practitionerView ? practitionerInfo?.firstName || '' : getValues('firstName').trim(),
      lastName: practitionerView ? practitionerInfo?.lastName || '' : getValues('lastName').trim(),
      email: getValues('email').trim(),
      phone: formatPhone(getValues('phone')).trim(),
      licenses: getValues('licenses').trim(),
      licensesInfo: getValues('licensesInfo')?.trim(),
      position: getValues('position').trim(),
      profileImage: getValues('profileImage'),
      medicalDirectorId: getValues('medicalDirectorId'),
      userGroupId: getValues('userGroupId'),
      doingBusinessAs: getValues('doingBusinessAs'),
      googleReview: getValues('googleReview').trim(),
      yelpReview: getValues('yelpReview').trim(),
    };

    if (isSupport && watchRoleId !== currentProviderRole?.id) {
      // @ts-ignore
      dataToEdit.roleId = watchRoleId;
    }

    try {
      setSaving(true);
      await mutationEdit.mutateAsync({ ...dataToEdit });
      refetchPractitionerInfo();
      !practitionerView &&
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.success_message', {
              action: 'edited',
              element: 'Practitioner',
            }),
            type: 'success',
            duration: 2500,
          },
        });
      setSaving(false);
      setStep(step + 1);
    } catch (error) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'editing',
            element: `practitioner - ${error}`,
          }),
          type: 'error',
          duration: 2500,
        },
      });
    }
  };

  const buttonDefaultText = practitionerView ? 'Next' : 'Save and Continue';
  const buttonSaveText = practitionerView ? 'Loading' : 'Saving...';

  const handleUpsertSubmit = (dataForm: any) => {
    if (!practitionerInfo.isEhrReady && getValues('isEhrReady') && isNonLegacyPractitioner) {
      setShowEHRReadyModal(true);
    } else {
      onSubmit(dataForm);
    }
  };

  const handleSave = (dataForm: any) => {
    setShowEHRReadyModal(false);
    onSubmit(dataForm);
  };

  if (isLoading || isLoadingCommissions) {
    return (
      <Box width="100%" display="flex" flexDirection="column">
        <MultipleSkeleton />
      </Box>
    );
  }

  const checkRoleChangeThenSave = async () => {
    if (watchRoleId && +watchRoleId !== currentProviderRole?.id && isLegacyProvider) {
      setShowRoleWarningModal(true);
    } else {
      await handleSaveAndContinue();
    }
  };

  const Breadcrumb =
    practitionerView && !isMedspaProvider ? (
      <>
        <NavigationBar
          title="Account Settings"
          onBackButtonClick={(): void => {
            history.goBack();
          }}
        />
        <Box className={classes.practitionerHeaderOuter}>
          {practitionerInfo.profileImageUrl && (
            <Box
              className={classes.profileImage}
              style={{ backgroundImage: `url('${practitionerInfo?.profileImageUrl}')` }}
            />
          )}
          <Box className={classes.practitionerHeader}>
            <Typography variant="h5">
              {practitionerInfo?.firstName || ''} {practitionerInfo?.lastName || ''}
            </Typography>
            <Typography>{practitionerRoleLabel}</Typography>
          </Box>
        </Box>
      </>
    ) : (
      (!isMedspaProvider && <MyBreadcrumb editing={editing} />) || <>Account Settings</>
    );

  return (
    <Page titleComponent={Breadcrumb}>
      <Box width="100%" display="flex" height="100%" flexDirection="column">
        <Box maxWidth="1000px">
          <Box paddingX={2} component="form" onSubmit={handleSubmit(handleUpsertSubmit)}>
            <Box display="flex" justifyContent="space-between" mb={4}>
              {!practitionerView && (
                <Box className={classes.titleContainer}>
                  <Typography>{editing ? 'Edit' : 'New'} practitioner</Typography>
                  {practitionerInfo?.slug && (
                    <button
                      type="button"
                      className={classes.clipboardLink}
                      aria-hidden="true"
                      onClick={() => copyToClipboard(`${SIGN_UP_ROUTES.booking}/${practitionerInfo.slug}`)}
                    >
                      <LinkSharpIcon
                        style={{
                          transform: 'rotate(130deg)',
                          color: '#000',
                          marginRight: '6px',
                          width: '20px',
                        }}
                      />
                      Booking link
                    </button>
                  )}
                  {practitionerInfo?.slug && (
                    <button
                      type="button"
                      className={classes.clipboardLink}
                      aria-hidden="true"
                      onClick={() => copyToClipboard(`${SIGN_UP_ROUTES.registration}/${practitionerInfo.slug}`)}
                    >
                      <LinkSharpIcon
                        style={{
                          transform: 'rotate(130deg)',
                          color: '#000',
                          marginRight: '6px',
                          width: '20px',
                        }}
                      />
                      Registration link
                    </button>
                  )}
                </Box>
              )}
            </Box>
            <Grid container spacing={2} className={classes.stepsContainer}>
              <Box className={classes.stepContainer}>
                {step === 1 && (
                  <Box>
                    <Typography className={classes.stepTitle}>STEP 1</Typography>
                    <Typography className={classes.stepSubtitle}>Basic Details</Typography>
                  </Box>
                )}
                <hr className={step === 1 ? classes.stepBarActive : classes.stepBarInactive} />
              </Box>
              <Box className={classes.stepContainer}>
                {step === 2 && (
                  <Box className={classes.stepContainer}>
                    <Typography className={classes.stepTitle}>STEP 2</Typography>
                    <Typography className={classes.stepSubtitle}>Advanced Details</Typography>
                  </Box>
                )}
                <hr className={step === 2 ? classes.stepBarActive : classes.stepBarInactive} />
              </Box>
            </Grid>

            {step === 1 && (
              <Grid container spacing={2}>
                {!practitionerView && (
                  <>
                    <Grid item xs={12} md={6} lg={6}>
                      <Controller
                        name="roleId"
                        control={control}
                        defaultValue=""
                        render={({ onChange, value }) => (
                          <EHRSelect
                            dataCy="roleId"
                            labelId="role-id"
                            label="Product Type*"
                            id="role-id"
                            value={value}
                            disabled={editing && !isSupport}
                            onChange={onChange}
                            fullWidth
                            error={!!errors.roleId}
                            helperText={errors.roleId?.message || ''}
                          >
                            {roles
                              .filter((role) => {
                                if (editing) {
                                  return (
                                    (role.name !== PORTRAIT_LEGACY && role.name !== PORTRAIT_LEGACY_PLUS) ||
                                    role.id === practitionerInfo.roleId
                                  );
                                }
                                return true;
                              })
                              .map(({ id: roleId, name }: IRoles) => (
                                <MenuItem data-cy="role-item" key={roleId} value={roleId}>
                                  {name}
                                </MenuItem>
                              ))}
                          </EHRSelect>
                        )}
                      />
                      {errors?.roleId?.message && <FormHelperText color="red">{ROLE_ERROR}</FormHelperText>}
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <EHRTextField
                        dataCy="firstNameInput"
                        fullWidth
                        name="firstName"
                        id="firstName"
                        error={!!errors.firstName}
                        helperText={errors.firstName?.message || ''}
                        onChange={(event) => setValue('firstName', event.target.value)}
                        inputRef={register}
                        label="First name*"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <EHRTextField
                        dataCy="lastNameInput"
                        fullWidth
                        name="lastName"
                        id="lastName"
                        error={!!errors.lastName}
                        helperText={errors.lastName?.message || ''}
                        onChange={(event) => setValue('lastName', event.target.value)}
                        inputRef={register}
                        label="Last name*"
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12} md={6} lg={6}>
                  <EHRTextField
                    dataCy="emailInput"
                    fullWidth
                    name="email"
                    id="email"
                    error={!!errors.email}
                    helperText={errors.email?.message || ''}
                    onChange={(event) => setValue('email', event.target.value)}
                    label="Email*"
                    type="email"
                    inputRef={register}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <EHRTextField
                    dataCy="phoneInput"
                    fullWidth
                    name="phone"
                    id="phone"
                    error={!!errors.phone}
                    helperText={errors.phone?.message || ''}
                    onChange={(event) => setValue('phone', event.target.value)}
                    inputRef={register}
                    label="Phone*"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <CredentialsDropdown value={watch('licenses')} onChange={(e) => setValue('licenses', e)} />
                </Grid>
                {isNaturopathic && (
                  <Grid item xs={12}>
                    <EHRTextField
                      dataCy="licensesInfo"
                      fullWidth
                      name="licensesInfo"
                      error={!!errors.licensesInfo}
                      helperText="Enter the license manually"
                      onChange={(event) => setValue('licensesInfo', event.target.value)}
                      inputRef={register}
                      label="License"
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={6} lg={6}>
                  <EHRTextField
                    dataCy="positionInput"
                    fullWidth
                    name="position"
                    error={!!errors.position}
                    helperText={errors.position?.message || ''}
                    onChange={(event) => setValue('position', event.target.value)}
                    inputRef={register}
                    label="Position"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <EHRTextField
                    dataCy="entityInput"
                    fullWidth
                    name="entityName"
                    error={!!errors.entityName}
                    helperText={errors.entityName?.message || ''}
                    onChange={(event) => setValue('entityName', event.target.value)}
                    inputRef={register}
                    label="Business Entity"
                    disabled={!!practitionerInfo?.userGroupId}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <EHRTextField
                    dataCy="doingBusinessAsInput"
                    fullWidth
                    name="doingBusinessAs"
                    error={!!errors.doingBusinessAs}
                    helperText={errors.doingBusinessAs?.message || ''}
                    onChange={(event) => setValue('doingBusinessAs', event.target.value)}
                    inputRef={register}
                    label="Doing Business As"
                  />
                </Grid>
                {!practitionerView &&
                  (editing ? (
                    <Grid item xs={12} md={6} lg={6}>
                      <EHRTextField
                        dataCy="squareInput"
                        fullWidth
                        name="squareLocationId"
                        helperText={errors.squareLocationId?.message || ''}
                        error={!!errors.squareLocationId}
                        onChange={(event) => setValue('squareLocationId', event.target.value)}
                        inputRef={register}
                        disabled={editing}
                        label="Square ID"
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={6} lg={6}>
                      <EHRTextField
                        dataCy="squareInput"
                        fullWidth
                        name="squareLocationId"
                        helperText={errors.squareLocationId?.message || ''}
                        error={!!errors.squareLocationId}
                        onChange={(event) => setValue('squareLocationId', event.target.value)}
                        inputRef={register}
                        disabled={editing}
                        label="Square ID (Optional)"
                      />
                    </Grid>
                  ))}
                {practitionerView && isNonLegacyPractitioner ? null : (
                  <Grid item xs={12} md={6} lg={6} style={{ marginBottom: 'auto' }}>
                    <Controller
                      name="medicalDirectorId"
                      control={control}
                      defaultValue=""
                      render={({ onChange, value }) => (
                        <EHRSelect
                          dataCy="medicalDirector"
                          labelId="medical-director"
                          label="Medical Director"
                          id="medicalDirectorId"
                          value={value}
                          onChange={onChange}
                          fullWidth
                        >
                          {physicians
                            ?.filter((physician) => !physician.isAdvancedPractitioner)
                            .map(({ professionalName, id: physicianId }: any) => (
                              <MenuItem data-cy="item" key={physicianId} value={physicianId}>
                                {professionalName}
                              </MenuItem>
                            ))}
                        </EHRSelect>
                      )}
                    />
                  </Grid>
                )}

                {!practitionerView && (
                  <Grid item xs={12} md={6} lg={6} style={{ marginBottom: 'auto' }}>
                    <Controller
                      name="userGroupId"
                      control={control}
                      defaultValue=""
                      render={({ onChange, value }) => (
                        <EHRSelect
                          dataCy="userGroupId"
                          labelId="user-group-id"
                          label="Medspa"
                          id="user-group-id"
                          value={value}
                          onChange={onChange}
                          error={!!errors.userGroupId}
                          helperText={errors.userGroupId?.message || ''}
                          fullWidth
                        >
                          {medspaGroups?.userGroups.map(
                            ({ id, name }: IUserGroup): React.ReactElement => (
                              <MenuItem data-cy="administrator-item" key={id} value={id}>
                                {name}
                              </MenuItem>
                            )
                          )}
                        </EHRSelect>
                      )}
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Box>
                    <Typography variant="h6" className={classes.mt2}>
                      Upload Profile Picture
                    </Typography>
                    <UploadImageV2
                      width={300}
                      field="profileImage"
                      canViewOriginal={editing}
                      imageUrl={(getValues('profileImage') as string) || practitionerInfo?.profileImageUrl}
                      onChange={(image: string | null) => {
                        if (image) {
                          setValue('profileImage', image);
                          clearErrors('profileImage');
                        }
                      }}
                    />
                  </Box>
                  {!!errors.profileImage && <Box className={classes.errorMessage}>{errors?.profileImage?.message}</Box>}
                </Grid>
                {editing ? (
                  <Grid item xs={12}>
                    <button
                      type="button"
                      onClick={checkRoleChangeThenSave}
                      className={classes.actionButton}
                      // disabled={saving}
                    >
                      {saving ? buttonSaveText : buttonDefaultText}
                    </button>
                    <Modal
                      open={showRoleWarningModal}
                      onClose={() => setShowRoleWarningModal(false)}
                      className={classes.modal}
                    >
                      <Box className={classes.modalContent}>
                        <Box>
                          <h2>
                            Are you sure you want to change the role of the legacy provider to a non legacy product
                            type?
                          </h2>
                          <p>This action cannot be undone.</p>
                          <div>
                            <button
                              className={classes.cancelButton}
                              type="button"
                              onClick={() => setShowRoleWarningModal(false)}
                            >
                              Cancel
                            </button>
                            <button className={classes.actionButton} type="button" onClick={handleSaveAndContinue}>
                              Confirm
                            </button>
                          </div>
                        </Box>
                      </Box>
                    </Modal>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <button type="button" onClick={handleNext} className={classes.actionButton}>
                      Next
                    </button>
                  </Grid>
                )}
              </Grid>
            )}
            {step === 2 && (
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                  <EHRTextField
                    dataCy="googleReview"
                    fullWidth
                    name="googleReview"
                    onChange={(event) => setValue('googleReview', event.target.value)}
                    inputRef={register}
                    label="Google Review link"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <EHRTextField
                    dataCy="yelpReview"
                    fullWidth
                    name="yelpReview"
                    onChange={(event) => setValue('yelpReview', event.target.value)}
                    inputRef={register}
                    label="Yelp Review link"
                  />
                </Grid>
                {!practitionerView && (
                  <Grid item xs={12} md={6} lg={6}>
                    <EHRTextField
                      dataCy="acuityUserId"
                      fullWidth
                      name="acuityUserId"
                      disabled={!!watchEnableAcuityEnterprise}
                      onChange={(event) => setValue('acuityUserId', event.target.value)}
                      inputRef={register}
                      label="Acuity user id"
                    />
                  </Grid>
                )}
                {!isNonLegacyPractitioner && (
                  <Grid item xs={12} md={6} lg={6}>
                    <EHRTextField
                      dataCy="medicalGroup"
                      fullWidth
                      name="medicalGroup"
                      error={!!errors.medicalGroup}
                      helperText={errors.medicalGroup?.message || ''}
                      onChange={(event) => setValue('medicalGroup', event.target.value)}
                      inputRef={register}
                      label="Portrait Medical Group*"
                    />
                  </Grid>
                )}
                {!practitionerView && (
                  <Grid item xs={12} md={6} lg={6} style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                    <Controller
                      name="servicesCommission"
                      control={control}
                      defaultValue=""
                      render={({ onChange, value }) => (
                        <EHRSelect
                          dataCy="commissions"
                          native
                          name="servicesCommission"
                          label="Services Commission %"
                          value={value}
                          onChange={onChange}
                          error={!!errors.servicesCommission}
                          helperText={errors.servicesCommission?.message || ''}
                          fullWidth
                        >
                          {commissions && commissions.length ? (
                            commissions
                              .sort((a, b) => {
                                const commissionRateA = +a.shortDescription.replace('%', '');
                                const commissionRateB = +b.shortDescription.replace('%', '');
                                if (commissionRateB <= commissionRateA) {
                                  return 1;
                                }
                                return -1;
                              })
                              .map(({ id, shortDescription }) => <option value={id}>{shortDescription}</option>)
                          ) : (
                            <MenuItem value="--">No items found</MenuItem>
                          )}
                        </EHRSelect>
                      )}
                    />
                    {errors.servicesCommission?.message && (
                      <FormHelperText color="red">{errors.servicesCommission?.message}</FormHelperText>
                    )}
                  </Grid>
                )}
                {!isMedspaProvider && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="h6" className={classes.mt2}>
                        Options
                      </Typography>
                      <Box>
                        {!editing && (
                          <>
                            Enable Acuity Enterprise
                            <FormControlLabel
                              control={
                                <Controller
                                  control={control}
                                  inputRef={register}
                                  name="enableAcuityEnterprise"
                                  render={({ onChange, value }: any) => (
                                    <Switch
                                      data-cy="enableAcuityEnterpriseBox"
                                      onChange={(e) => onChange(e.target.checked)}
                                      checked={value}
                                      className={classes.checkbox}
                                      color="primary"
                                    />
                                  )}
                                />
                              }
                              label=""
                            />
                          </>
                        )}
                      </Box>
                      <Box>
                        Request Cancellation Fee
                        <FormControlLabel
                          control={
                            <Controller
                              control={control}
                              inputRef={register}
                              name="requestCreditCard"
                              render={({ value }: any) => (
                                <Switch
                                  data-cy="cardCheckbox"
                                  onChange={(e) => handleSetFee('requestCreditCard', e.target.checked)}
                                  checked={value}
                                  className={classes.checkbox}
                                  color="primary"
                                />
                              )}
                            />
                          }
                          label=""
                        />
                      </Box>
                      <Box>
                        Request Deposit
                        <FormControlLabel
                          control={
                            <Controller
                              control={control}
                              inputRef={register}
                              name="requestDeposit"
                              render={({ value }: any) => (
                                <Switch
                                  data-cy="cardCheckbox"
                                  onChange={(e) => handleSetFee('requestDeposit', e.target.checked)}
                                  checked={value}
                                  className={classes.checkbox}
                                  color="primary"
                                />
                              )}
                            />
                          }
                          label=""
                        />
                      </Box>
                      {!practitionerView && (
                        <Box>
                          Inactive
                          <FormControlLabel
                            control={
                              <Controller
                                control={control}
                                inputRef={register}
                                name="inactive"
                                render={({ onChange, value }: any) => (
                                  <Switch
                                    data-cy="inactiveCheckbox"
                                    onChange={(e) => onChange(e.target.checked)}
                                    checked={value}
                                    className={classes.checkbox}
                                    color="primary"
                                  />
                                )}
                              />
                            }
                            label=""
                          />
                        </Box>
                      )}
                      {!practitionerView && (
                        <Box>
                          EHR Ready
                          <FormControlLabel
                            control={
                              <Controller
                                control={control}
                                inputRef={register}
                                name="isEhrReady"
                                render={({ onChange, value }: any) => (
                                  <Switch
                                    data-cy="isEhrReadyCheckbox"
                                    onChange={(e) => onChange(e.target.checked)}
                                    checked={value}
                                    disabled={!isNonLegacyPractitioner || practitionerInfo.isEhrReady}
                                    className={classes.checkbox}
                                    color="primary"
                                  />
                                )}
                              />
                            }
                            label=""
                          />
                        </Box>
                      )}
                      {!isMedspaProvider && (
                        <Box>
                          Active for scheduling
                          <FormControlLabel
                            control={
                              <Controller
                                control={control}
                                inputRef={register}
                                name="activeForScheduling"
                                render={({ onChange, value }: any) => (
                                  <Switch
                                    data-cy="schedulingCheckbox"
                                    onChange={(e) => onChange(e.target.checked)}
                                    checked={value}
                                    className={classes.checkbox}
                                    color="primary"
                                  />
                                )}
                              />
                            }
                            label=""
                          />
                        </Box>
                      )}
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.mt2}>
                    Upload Signature
                  </Typography>
                  <Box className={classes.uploadSignature}>
                    <UploadImageV2
                      width={500}
                      field="signatureImage"
                      canViewOriginal={false}
                      imageUrl={(getValues('signatureImage') as string) || practitionerInfo?.signatureImageUrl}
                      onChange={(image: string | null) => {
                        if (image) {
                          setValue('signatureImage', image);
                          clearErrors('signatureImage');
                        }
                      }}
                    />
                  </Box>
                  {!!errors.signatureImage && (
                    <Box className={classes.errorMessage}>{errors?.signatureImage?.message}</Box>
                  )}
                </Grid>
                <Grid item xs={12} className={classes.buttonsContainer}>
                  <button type="submit" className={classes.actionButton} disabled={saving}>
                    {saving ? 'Saving...' : 'Save'}
                  </button>
                  <button type="button" onClick={() => setStep(step - 1)} className={classes.backButton}>
                    {'< Back'}
                  </button>
                </Grid>
                <Modal open={showEHRReadyModal} onClose={() => setShowEHRReadyModal(false)} className={classes.modal}>
                  <Box className={classes.modalContent}>
                    <Box>
                      <h2>Are you sure you want mark this provider as EHR ready?</h2>
                      <p>This action cannot be undone.</p>
                      <div>
                        <button
                          className={classes.cancelButton}
                          type="button"
                          onClick={() => setShowEHRReadyModal(false)}
                        >
                          Cancel
                        </button>
                        <button className={classes.actionButton} type="button" onClick={handleSubmit(handleSave)}>
                          Confirm
                        </button>
                      </div>
                    </Box>
                  </Box>
                </Modal>
              </Grid>
            )}
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

export default CreateOrEditPractitioners;
