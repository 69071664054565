import { useQuery } from 'react-query';
import { PRACTITIONERS, PRACTITIONER_INFO } from '../../constants/reactQuery.keys';
import { Practitioners } from '../../services/Practitioners';

export function useMedspaPractitioners(filters = {}, enabled: boolean = true, endpointBuilder: Function) {
  const fetchPractitioners = () => {
    const endpoint = endpointBuilder();
    if (Object.keys(filters).length) {
      return Practitioners.getMedspa(filters, endpoint);
    }
    return Practitioners.getAllMedspa(endpoint);
  };

  return useQuery([PRACTITIONERS, filters], fetchPractitioners, {
    refetchOnWindowFocus: false,
    enabled,
  });
}

export function usePractitioners(filters = {}, enabled: boolean = true) {
  if (Object.keys(filters).length) {
    return useQuery([PRACTITIONERS, filters], () => Practitioners.get(filters), {
      refetchOnWindowFocus: false,
      enabled,
    });
  }

  return useQuery([PRACTITIONERS], () => Practitioners.getAll(), {
    refetchOnWindowFocus: false,
    enabled,
  });
}

export function usePractitionersForNewPatient(enabled: boolean = true) {
  return useQuery([PRACTITIONERS], () => Practitioners.getAllForNewPatient(), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled,
  });
}

export const DEFAULT_CANCELLATION_FEE = 150;

export const defaultPractitionerValues = {
  id: null,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  servicesCommission: '',
  licenses: '',
  licensesInfo: '',
  practitionerInformation: {
    id: 0,
    identityId: '',
    endpoint: '',
    instagramLink: null,
    facebookLink: null,
    twitterLink: null,
    profileImageUrl: null,
    isExpert: false,
    licenses: '',
    licensesInfo: '',
    position: '',
    locations: '',
    credentials: '',
    createdAt: '',
    updatedAt: '',
    profileImageFileName: null,
    profileImageContentType: null,
    profileImageFileSize: null,
    socialMediaReviewsInfo: null,
  },
  slug: '',
  signatureImageUrl: '',
  profileImageUrl: '',
  inactive: false,
  activeForScheduling: false,
  squareLocationId: '',
  medicalDirectorId: null,
  requestCreditCard: false,
  requestDeposit: false,
  featureSettings: [],
  entityName: '',
  medicalGroup: '',
  cancelationFee: DEFAULT_CANCELLATION_FEE,
  roleId: null,
  acuityUserId: null,
  depositFee: null,
  cancellationFee: null,
  isEhrReady: false,
  userGroupId: null,
  doingBusinessAs: '',
  enableAcuityEnterprise: false,
  gfeSetting: '',
  requestReclearOnProfileChange: false,
};

export function usePractitionerInfo(id: number | null, identityId?: string, enabled: boolean = true) {
  const {
    data: practitionerInfo = defaultPractitionerValues,
    isLoading,
    isError,
    isFetching,
    isFetched,
    refetch,
  } = useQuery([PRACTITIONER_INFO, id], () => Practitioners.getPractitionerInfo(id as number, identityId), {
    refetchOnWindowFocus: false,
    enabled: !!id && enabled,
  });

  return {
    practitionerInfo,
    isLoading,
    isError,
    isFetching,
    isFetched,
    refetch,
  };
}

export { PRACTITIONERS } from '../../constants/reactQuery.keys';
