const DEFAULT_LOCATE = 'en-US';

const DEFAULT_CURRENCY_FORMATS = {
  locate: DEFAULT_LOCATE,
  style: 'currency',
  currency: 'USD',
};

export default function formatNumber(value: number, format?: Intl.NumberFormatOptions) {
  const { locate, ...rest } = { locate: DEFAULT_LOCATE, ...format };
  return new Intl.NumberFormat(locate, rest).format(value);
}

export function formatCurrency(value: number, format?: Intl.NumberFormatOptions) {
  const { locate, ...rest } = { ...DEFAULT_CURRENCY_FORMATS, ...format };
  return new Intl.NumberFormat(locate, rest).format(value);
}

export const formatPhone = (val: string): string => val.replace(/[^a-zA-Z0-9]/g, '');

export const fixDecimals = (val: number, decimals: number): number | null => {
  if (val === null || val === undefined || Number.isNaN(val)) {
    return null;
  }

  const splitted = val.toString().split('.');

  if (splitted.length > 1) {
    return parseFloat(`${splitted[0]}.${splitted[1].slice(0, decimals)}`);
  }

  return val;
};
