import { useMutation } from 'react-query';
import MerchantOnboarding from '../../services/MerchantOnboarding';

interface IMerchantOnboardingParams {
  country: string;
}

export const useMerchantOnboarding = () =>
  useMutation((params: IMerchantOnboardingParams) => MerchantOnboarding.createMerchantOnboarding(params), {
    onSuccess: (data) => {
      window.location.href = data.onboardingUrl;
    },
    onError: (error) => {
      console.error('[DEBUG] Mutation failed:', error);
    },
    onSettled: (data, error) => {
      console.log('[DEBUG] Mutation settled. Data:', data, 'Error:', error);
    },
  });
