import React, { useEffect } from 'react';

import { FormControl, MenuItem, InputLabel, Select, Card, CardContent } from '@material-ui/core';
import { sortBy } from 'lodash';
import { ILocationPatientId } from 'src/interfaces/ILocation';
import { useSetMedspaLocationMutation } from 'src/hooks/serviceVisit/useServiceVisit';
import IServiceVisit from 'src/interfaces/IServiceVisits';
import compile from 'src/utils/toastMessagesCompiler';
import { dispatch } from 'src/rematch';
import { ICheckout } from 'src/interfaces/checkout.interfaces';
import { SQUARE_STATUS } from 'src/constants/square.constants';
import { SmallTitle } from '../common/card';
import { useStyles } from './servicesLocations.styles';

interface Props {
  serviceVisit: IServiceVisit;
  serviceVisitId: number;
  locations: ILocationPatientId[];
  isLoading: boolean;
  selectedLocation?: ILocationPatientId | null;
  setSelectedLocation: React.Dispatch<React.SetStateAction<ILocationPatientId | null>>;
  checkout: ICheckout;
}

export const ServicesLocations = ({
  serviceVisit,
  serviceVisitId,
  locations,
  isLoading,
  selectedLocation,
  setSelectedLocation,
  checkout,
}: Props) => {
  const classes = useStyles();
  const setMedspaLocationMutation = useSetMedspaLocationMutation(serviceVisitId);

  const shouldDisableInputs = checkout?.transactionStatus === SQUARE_STATUS.OK || !serviceVisit.opened;

  useEffect(() => {
    if (!isLoading) {
      if (serviceVisit.medspaLocationId) {
        const location = locations.find((loc) => loc.id === serviceVisit.medspaLocationId) as ILocationPatientId;
        setSelectedLocation(location);
      } else if (locations.length === 1) {
        setSelectedLocation(locations[0]);
      }
    }
  }, [locations, isLoading, serviceVisit]);

  const handleLocationChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    const locationId = event.target.value as number;
    const location = locations.find((loc) => loc.id === locationId);
    if (location) {
      try {
        await setMedspaLocationMutation.mutateAsync({ medspaLocationId: locationId });
        setSelectedLocation(location);
      } catch (error) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'setting',
              element: 'location',
            }),
            type: 'error',
          },
        });
      }
    }
  };

  return (
    <Card style={{ marginBottom: '0', minHeight: '148px' }}>
      <CardContent style={{ padding: '11px 20px 20px 20px' }}>
        <SmallTitle title="Locations" style={{ marginTop: '0', marginBottom: '15px' }} />
        <FormControl
          size="medium"
          variant="outlined"
          className={classes.formControl}
          disabled={isLoading || shouldDisableInputs}
        >
          <InputLabel id="location">Locations available</InputLabel>
          <Select
            label="Locations available"
            onChange={handleLocationChange}
            id="location"
            value={selectedLocation?.id ?? ''}
            fullWidth
          >
            <MenuItem value="" disabled>
              Select a location
            </MenuItem>
            {sortBy(locations, (location) => location.name).map((location) => (
              <MenuItem key={location.id} value={location.id}>
                {location.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </CardContent>
    </Card>
  );
};
