import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Switch,
  FormHelperText,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useMutation, useQueryClient } from 'react-query';
import { useStyles } from './index.styles';
import { schemaForm } from './schemaForm';
import { USER_TYPES } from '../../../constants/general.constants';
import { usePhysicians } from '../../../hooks/queries/usePhysicians';
import { PRACTITIONERS, usePractitionerInfo } from '../../../hooks/queries/usePractitioners';
import { formatPhone } from '../../../utils/formatNumber';
import { IPractitionerPost } from '../../../interfaces/IPractitioner';
import { dispatch } from '../../../rematch';
import compile from '../../../utils/toastMessagesCompiler';
import { Practitioners } from '../../../services/Practitioners';
import { MultipleSkeleton } from '../../../components/common/LoadingSkeleton';
import Header from '../Header';
import Footer from '../Footer';
import { PRACTITIONER_ACCOUNT_SETTINGS_PATH, PRACTITIONER_HOME_PATH } from '../../../routes/practitionerRoutes';
import UploadImageButton from '../../../components/common/UploadImageButton';
import { DEFAULT_FLEX_LUXE_FEATURES } from '../../../constants/features.constants';
import { useUpdateProfileProgress } from '../../../hooks/mutations/useUpdateProfileProgress';
import { IRoles } from '../../../interfaces/IRoles.interfaces';
import { useCustomRoles } from '../../../hooks/queries/useCustomRoles';

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  licenses: string;
  position: string;
  phone: string;
  inactive?: boolean;
  signatureImage?: string | ArrayBuffer | null;
  profileImage?: string | ArrayBuffer | null;
  medicalDirectorId: string | number;
  roleId?: number | null;
  depositFee?: number | string | null;
  cancelationFee?: number | string | null;
  requestDeposit?: boolean;
  requestCreditCard?: boolean;
  squareLocationId: string;
}

export const OLD_MEDICAL_CREDENTIALS = ['RN', 'RN, BSN', 'NP', 'PA-C', 'DNP, FNP-C'];
export const MEDICAL_CREDENTIALS = [
  'Registered Nurse (RN)',
  'Nurse Practitioner (NP)',
  'Physician Assistant (PA)',
  'Licensed Nurse (LVN / LPN)',
  'Doctor of Medicine (MD)',
  'Doctor of Osteopathic Medicine (DO)',
  'Dentist (DDS / DMD)',
  'Entrepreneur',
  'Esthetician',
  'Naturopathic Doctor',
  'None of These',
];

export const ALL_MEDICAL_CREDENTIALS = [...OLD_MEDICAL_CREDENTIALS, ...MEDICAL_CREDENTIALS];

const MoreDetails: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id: identityId, user, userId } = useSelector(({ auth }: any) => auth);
  const { practitionerInfo, isFetching, isLoading } = usePractitionerInfo(userId, identityId);
  const { data: physicians, isError: isErrorPhysicians } = usePhysicians();
  const updateProfileProgressMutation = useUpdateProfileProgress();
  const { data: roles = [] as IRoles[], isFetching: isLoadingRole } = useCustomRoles({
    userType: USER_TYPES.PRACTITIONER,
  });

  const successCallback = () => {
    history.push(`${PRACTITIONER_ACCOUNT_SETTINGS_PATH('services-management')}?firstTime=true`);
  };
  const mutationEdit = useMutation(Practitioners.editPractitioner);

  const queryClient = useQueryClient();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const { register, control, handleSubmit, errors, setValue, getValues, watch } = useForm<FormData>({
    resolver: yupResolver(schemaForm),
    defaultValues: {
      email: '',
      phone: '',
      signatureImage: '',
      profileImage: '',
      medicalDirectorId: '',
      licenses: '',
      depositFee: '',
      cancelationFee: '',
      requestCreditCard: false,
      requestDeposit: false,
      squareLocationId: '',
    },
  });

  const watchRequestCreditCard = watch('requestCreditCard');
  const watchRequestDeposit = watch('requestDeposit');

  useEffect(() => {
    if (practitionerInfo?.isEhrReady) {
      history.replace(PRACTITIONER_HOME_PATH);
    }
  }, [practitionerInfo]);

  useEffect(() => {
    if (!isFetching) {
      const {
        firstName,
        lastName,
        email,
        phone,
        practitionerInformation,
        medicalDirectorId,
        roleId,
        cancelationFee,
        requestCreditCard,
        requestDeposit,
        squareLocationId,
      } = practitionerInfo;

      setValue('firstName', firstName);
      setValue('squareLocationId', squareLocationId || '');
      setValue('lastName', lastName);
      setValue('email', email);
      setValue('phone', phone);
      setValue('licenses', practitionerInformation?.licenses || '');
      setValue('medicalDirectorId', medicalDirectorId || '');
      setValue('roleId', roleId);
      setValue('cancelationFee', requestCreditCard ? cancelationFee : '');
      setValue('depositFee', requestDeposit ? cancelationFee : '');
      setValue('requestCreditCard', requestCreditCard || false);
      setValue('requestDeposit', requestDeposit || false);
    }
  }, [practitionerInfo]);

  if (isErrorPhysicians) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'fetching',
          element: 'physicians',
        }),
        type: 'error',
      },
    });
  }

  const handleUpdatePractitioner = async (data: IPractitionerPost) => {
    try {
      await mutationEdit.mutateAsync(data);
      await queryClient.invalidateQueries([PRACTITIONERS, user.id]);
      await updateProfileProgressMutation.mutateAsync({ stepName: 'more-details' });
      successCallback();
    } catch (error) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'editing',
            element: `practitioner - ${error}`,
          }),
          type: 'error',
          duration: 2500,
        },
      });
    }
  };

  const onSubmit = (dataForm: any) => {
    const data = {
      id: user.id,
      firstName: dataForm.firstName.trim(),
      lastName: dataForm.lastName.trim(),
      email: practitionerInfo.email,
      phone: formatPhone(dataForm.phone).trim(),
      licenses: dataForm.licenses?.trim(),
      signatureImage: dataForm.signatureImage,
      profileImage: dataForm.profileImage,
      medicalDirectorId: dataForm.medicalDirectorId,
      cancelationFee: dataForm.depositFee || dataForm.cancelationFee,
      roleId: dataForm.roleId,
      featureSettings: DEFAULT_FLEX_LUXE_FEATURES.map((featureKey) => ({
        key: featureKey,
        settings: {},
        enabled: true,
      })),
      inactive: false,
      position: '',
      requestCreditCard: dataForm.requestCreditCard,
      requestDeposit: dataForm.requestDeposit,
    };
    setIsSaving(true);
    handleUpdatePractitioner(data);
  };

  if (!practitionerInfo.id || isLoading || isLoadingRole) {
    return (
      <Box width="100%" display="flex" flexDirection="column">
        <MultipleSkeleton />
      </Box>
    );
  }

  return (
    <>
      <Header currentStepName="More Details" currentStep={1} />
      <Box width="100%" display="flex" height="100%" flexDirection="column" className={classes.container}>
        <Box onSubmit={handleSubmit(onSubmit)}>
          <Box className={classes.practitionerInfo}>
            <Box
              className={classes.profileImage}
              style={{
                backgroundImage: `url('${(getValues('profileImage') as string) || practitionerInfo?.profileImageUrl}')`,
              }}
            />
            <Box className={classes.roleAndFullName}>
              <Typography variant="h5">
                {practitionerInfo?.firstName || ''} {practitionerInfo?.lastName || ''}
              </Typography>
              <Typography>{roles?.find((role: IRoles) => role.id === practitionerInfo.roleId)?.name}</Typography>
              <UploadImageButton
                field="photo"
                imageUrl={(getValues('profileImage') as string) || practitionerInfo?.profileImageUrl}
                onChange={(image: string | null) => {
                  if (image) {
                    setValue('profileImage', image);
                  }
                }}
              />
            </Box>
          </Box>
          <Grid container className={classes.formsContainer}>
            <Grid item xs>
              <Grid item className={classes.formContainerLeft}>
                <Typography variant="h6" className={classes.title}>
                  Personal Information
                </Typography>
                <Grid container item spacing={1} className={classes.formMargin}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      name="email"
                      error={!!errors.email}
                      helperText={errors.email?.message || ''}
                      onChange={(event) => setValue('email', event.target.value)}
                      label="Email"
                      type="email"
                      inputRef={register}
                      disabled
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      name="phone"
                      error={!!errors.phone}
                      helperText={errors.phone?.message || ''}
                      onChange={(event) => setValue('phone', event.target.value)}
                      inputRef={register}
                      label="Phone*"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container item spacing={1} className={classes.signatureContainer}>
                  <Typography variant="h6" className={classes.titleSecundary}>
                    Signature
                  </Typography>
                  {(getValues('signatureImage') || practitionerInfo?.signatureImageUrl) && (
                    <div className={classes.imageContainer}>
                      <div aria-hidden>
                        <img
                          src={(getValues('signatureImage') as string) || practitionerInfo?.signatureImageUrl}
                          alt="siganture"
                          className={classes.signatureImage}
                        />
                      </div>
                    </div>
                  )}
                  <UploadImageButton
                    field="signature"
                    imageUrl={(getValues('signatureImage') as string) || practitionerInfo?.signatureImageUrl}
                    onChange={(image: string | null) => {
                      if (image) {
                        setValue('signatureImage', image);
                      }
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs>
                <Grid item className={classes.formContainerLeft}>
                  <Grid item>
                    <Box className={classes.formItemTitle}>
                      <Box width="185px" position="absolute">
                        Deposit Fee*
                      </Box>
                      <FormControlLabel
                        style={{ justifyContent: 'flex-end', width: '100%' }}
                        label=""
                        control={
                          <Switch
                            color="primary"
                            name="requestDeposit"
                            checked={watchRequestDeposit}
                            onChange={(event) => {
                              setValue('requestDeposit', event.target.checked);
                              if (event.target.checked) {
                                setValue('cancelationFee', '');
                                setValue('requestCreditCard', false);
                              }
                            }}
                          />
                        }
                      />
                      {watchRequestDeposit && (
                        <div className={classes.subField}>
                          <div className={classes.textField}>
                            <TextField
                              fullWidth
                              name="depositFee"
                              error={!!errors.depositFee}
                              helperText={errors.depositFee?.message || ''}
                              onChange={(event) => setValue('depositFee', event.target.value)}
                              inputRef={register}
                              label="Enter amount"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </Box>
                    <Box className={classes.formItemTitle}>
                      <Box width="185px" position="absolute">
                        Cancellation Fee*
                      </Box>
                      <FormControlLabel
                        style={{ justifyContent: 'flex-end', width: '100%' }}
                        label=""
                        control={
                          <Switch
                            color="primary"
                            name="requestCreditCard"
                            id="requestCreditCard"
                            checked={watchRequestCreditCard}
                            onChange={(event) => {
                              setValue('requestCreditCard', event.target.checked);
                              if (event.target.checked) {
                                setValue('depositFee', '');
                                setValue('requestDeposit', false);
                              }
                            }}
                          />
                        }
                      />
                      {watchRequestCreditCard && (
                        <div className={classes.subField}>
                          <div className={classes.textField}>
                            <TextField
                              fullWidth
                              name="cancelationFee"
                              error={!!errors.cancelationFee}
                              helperText={errors.cancelationFee?.message || ''}
                              onChange={(event) => setValue('cancelationFee', event.target.value)}
                              inputRef={register}
                              label="Enter amount"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs>
              <Grid item className={classes.formContainerRight}>
                <Typography variant="h6" className={classes.title}>
                  Medical Information
                </Typography>
                <Grid container item spacing={1} className={classes.formMargin}>
                  <Grid item xs={12} md={6} lg={6} className={classes.hiddenSection}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel shrink htmlFor="medical-director">
                        Medical Director
                      </InputLabel>
                      <Controller
                        name="medicalDirectorId"
                        control={control}
                        defaultValue=""
                        render={({ onChange, value }) => (
                          <Select
                            labelId="medical-director"
                            label="Medical Director"
                            id="medicalDirectorId"
                            value={value}
                            variant="outlined"
                            onChange={onChange}
                          >
                            {physicians
                              ?.filter((physician) => !physician.isAdvancedPractitioner)
                              .map(({ professionalName, id: physicianId }: any) => (
                                <MenuItem key={physicianId} value={physicianId}>
                                  {professionalName}
                                </MenuItem>
                              ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} style={{ marginBottom: 'auto' }}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel shrink htmlFor="medical-director">
                        Licenses
                      </InputLabel>
                      <Controller
                        name="licenses"
                        control={control}
                        defaultValue=""
                        variant="outlined"
                        render={({ onChange, value }) => (
                          <Select
                            labelId="licenses"
                            label="Licenses"
                            id="licenses"
                            value={value}
                            onChange={onChange}
                            error={!!errors.licenses}
                          >
                            <MenuItem />
                            {MEDICAL_CREDENTIALS.map((license: string) => (
                              <MenuItem key={license} value={license}>
                                {license}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      {!!errors.licenses && <FormHelperText error>{errors.licenses.message}</FormHelperText>}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Footer isSaving={isSaving || isLoading} currentStep={1} handleSubmit={handleSubmit(onSubmit)} />
    </>
  );
};

export default MoreDetails;
