import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles, Container } from '@material-ui/core';
import { NavigationBar } from '../../components/PatientProfile/NavigationBar';
import { useServiceVisits } from '../../hooks/queries/useServiceVisits';
import { IN_PERSON_VISIT_TYPE } from '../../services/ServiceVisit';
import ContainerCheckout from './ContainerCheckout';

interface IParams {
  patientId: string;
  serviceVisitId: string;
}

const ContainerCheckoutStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: 0,
  },
  order: {
    padding: 0,
    paddingBottom: '4rem',
  },
}));

// This is the old checkout page new one is in ServiceVisitCheckoutContainer
export default function SimpleContainer(params: any) {
  const history = useHistory();
  const classes = ContainerCheckoutStyles();
  const { patientId, serviceVisitId } = useParams<IParams>();
  const { data: serviceVisits } = useServiceVisits(patientId);

  const onClickBackButton = () =>
    serviceVisits?.find((serviceVisit) => serviceVisit.id?.toString() === serviceVisitId)?.visitType ===
    IN_PERSON_VISIT_TYPE
      ? history.push(`/patient/${patientId}/serviceVisit/${serviceVisitId}`)
      : history.push(`/patient/${patientId}/retailCheckout`);

  return (
    <Container className={classes.root}>
      <NavigationBar title="Checkout" onBackButtonClick={onClickBackButton} />
      <Container className={classes.order}>
        <ContainerCheckout {...params} />
      </Container>
    </Container>
  );
}
