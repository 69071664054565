import React, { useState, ReactElement, useEffect } from 'react';
import { Typography, Select, MenuItem, FormControl, withStyles } from '@material-ui/core';

import { ILocation, IMedspaLocation } from 'src/interfaces/ILocation';

const StyledSelect = withStyles({
  icon: {
    position: 'static',
  },
})(Select);

const DeliveryAddress = ({
  location,
  onAddressSelect,
  commercialShippingAddress,
  commercialShippingAddressId,
  className,
  singleLine,
  showDropdown = false,
}: {
  showDropdown?: boolean;
  location?: IMedspaLocation | ILocation | null;
  onAddressSelect?: (address: IMedspaLocation) => void;
  commercialShippingAddress?: IMedspaLocation[] | null;
  commercialShippingAddressId?: number | null;
  className?: string;
  singleLine?: boolean;
}): ReactElement | null => {
  const [selectedId, setSelectedId] = useState<number | null>(commercialShippingAddressId ?? null);
  const isMedspaLocation = location && 'address' in location;
  const address = isMedspaLocation ? location.address : location?.customAddress ?? '';
  const zipCode = isMedspaLocation ? location.zipCode : location?.customAddress?.split(' ').slice(-1)[0];

  useEffect(() => {
    if (commercialShippingAddress?.length && !commercialShippingAddressId) {
      onAddressSelect?.(commercialShippingAddress[0]);
    }
  }, [commercialShippingAddress, commercialShippingAddressId]);

  useEffect(() => {
    setSelectedId(commercialShippingAddressId ?? null);
  }, [commercialShippingAddressId]);

  if (!location || address.length < 1) {
    return (
      <Typography component="p">Deliveries will be sent to the address specified in your Account Settings</Typography>
    );
  }

  if (!showDropdown || !commercialShippingAddress?.length) {
    return (
      <Typography
        component="p"
        className={className}
        style={{ textTransform: 'capitalize', color: 'rgba(0, 0, 0, 0.38)' }}
      >
        {(address || '').toLowerCase().split(`, ${(location?.city || '').toLowerCase()}`)[0]}
        {singleLine ? ' - ' : <br />}
        {location?.city}, {location?.state} {zipCode}
      </Typography>
    );
  }

  return (
    <FormControl fullWidth>
      <StyledSelect
        SelectDisplayProps={{
          style: {
            padding: 0,
          },
        }}
        variant="standard"
        disableUnderline
        value={selectedId ?? ''}
        onChange={(e) => {
          const value = Number(e.target.value);
          setSelectedId(value);
          const selectedAddress = commercialShippingAddress?.find((addr) => addr.id === value);
          if (selectedAddress) {
            onAddressSelect?.(selectedAddress);
          }
        }}
        renderValue={() => {
          const selectedAddress = commercialShippingAddress?.find((addr) => addr.id === selectedId);

          if (!selectedAddress) {
            return address;
          }

          return (
            <Typography component="p" className={className} style={{ textTransform: 'capitalize' }}>
              {(selectedAddress.address || '').toLowerCase()}
              {singleLine ? ' - ' : <br />}
              {selectedAddress.city}, {selectedAddress.state} {selectedAddress.zipCode}
            </Typography>
          );
        }}
      >
        {commercialShippingAddress?.map((addr) => (
          <MenuItem key={addr.id} value={addr.id}>
            {addr.address}
            <br />
            {addr.city}, {addr.state} {addr.zipCode}
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export { DeliveryAddress, DeliveryAddress as default };
