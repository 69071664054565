import React, { useEffect, useMemo, useState } from 'react';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import { Help as HelpCenterIcon } from '@material-ui/icons';
import { STRIPE_PAYMENT_PROCESSOR } from 'src/constants/general.constants';
import { useStyles } from './ServiceVisitCheckoutContainer.styles';
import { formatNumber } from '../../utils/checkout.utils';
import {
  ALLE_WITH_CHERRY_TENDER,
  ASPIRE_TENDER,
  BRILLIANT_TENDER,
  CARE_CREDIT_TENDER,
  PATIENTFI_TENDER,
  TENDER_TYPE_NAMES,
  XPERIENCE_TENDER,
} from '../../constants/checkout.constants';
import { SQUARE_STATUS } from '../../constants/square.constants';
import { ISquareCard } from '../../interfaces/ISquareCard.interfaces';
import { IPatientBasicInfo } from '../../types/Patient';
import { ServiceVisitCheckoutSummaryModal } from './ServiceVisitCheckoutSummaryModal';
import { ILineItem } from '../../interfaces/IServiceVisits';
import { ICheckout } from '../../interfaces/checkout.interfaces';
import { ReactComponent as SuccessIcon } from '../../assets/images/payment-success.svg';
import { PaymentOptions } from './PaymentOptions';

export const TENDER_TYPE_TO_SHOW = [
  BRILLIANT_TENDER,
  ALLE_WITH_CHERRY_TENDER,
  CARE_CREDIT_TENDER,
  XPERIENCE_TENDER,
  ASPIRE_TENDER,
  PATIENTFI_TENDER,
];

export const CASH_CHARGE_TYPE = 'cash';
export const SQUARE_CHARGE_TYPE = 'square';
export const CARD_CHARGE_TYPE = 'card';

interface RemainingToPayProps {
  updateCheckout: any;
  accountCreditUsed?: number;
  checkoutTenders: any;
  referralValue?: number;
  patient: IPatientBasicInfo;
  loading: boolean;
  openSquarePOS: () => void;
  handlePayment: (args: any) => void;
  lineItems: ILineItem[];
  checkout: ICheckout;
  payZeroBalance: () => void;
  isPractitioner: boolean;
}

export const RemainingToPay = ({
  accountCreditUsed,
  checkoutTenders,
  referralValue,
  patient,
  loading,
  openSquarePOS,
  handlePayment,
  lineItems,
  checkout,
  updateCheckout,
  payZeroBalance,
  isPractitioner,
}: RemainingToPayProps) => {
  const styles = useStyles();
  const {
    toPay,
    subtotal: subTotal,
    discounts,
    amountPaid,
    canPayCheckout,
    serviceCreditsValue,
    transactionStatus,
    chargeType: checkoutChargeType,
    checkoutSignatureUrl,
  } = checkout;
  const total = toPay - (amountPaid || 0);
  const [chargeType, setChargeType] = useState<string>('card');
  const [cashAmount, setCashAmount] = useState<string>();
  const [selectedCard, setSelectedCard] = useState<ISquareCard>();
  const cards = useMemo(() => (patient.creditCards || []).reverse(), [patient.creditCards]);
  const [openSummaryModal, setOpenSummaryModal] = useState<boolean>(false);

  const { paymentProcessor: patientPaymentProcessor } = patient;

  useEffect(() => {
    setSelectedCard(patient.creditCards.find((card) => card.id === patient.squareDefaultCreditCardId));
  }, [patient.squareDefaultCreditCardId]);

  useEffect(() => {
    if (chargeType !== CASH_CHARGE_TYPE) {
      setCashAmount(undefined);
    }
  }, [chargeType]);

  const handleOpenSummary = () => {
    if (checkout.toPay === 0) {
      payZeroBalance();
    } else if (chargeType === SQUARE_CHARGE_TYPE) {
      openSquarePOS();
    } else {
      setOpenSummaryModal(true);
    }
  };

  const checkValid = () => {
    if (checkout.toPay === 0) {
      return true;
    }
    if (chargeType === CARD_CHARGE_TYPE) {
      return !!selectedCard;
    }

    return true;
  };

  const renderPaymentOptions = () => {
    if (patientPaymentProcessor === STRIPE_PAYMENT_PROCESSOR) {
      return <div>Stripe placeholder component</div>;
    }
    return (
      <PaymentOptions
        patient={patient}
        chargeType={chargeType}
        setChargeType={setChargeType}
        cashAmount={cashAmount}
        setCashAmount={setCashAmount}
        total={total}
        cards={cards}
        selectedCard={selectedCard}
        setSelectedCard={setSelectedCard}
      />
    );
  };
  return (
    <div className={styles.totalsContainer}>
      {transactionStatus === SQUARE_STATUS.OK ? (
        <Box className={styles.paidContainer}>
          <SuccessIcon />
          <h2>Paid ${formatNumber(toPay)}</h2>
        </Box>
      ) : (
        <>
          {renderPaymentOptions()}
          <Box className={styles.paymentSummaryContainer}>
            <h2>Summary</h2>
            <Box className={styles.summaryItem}>
              <div>Sub total</div>
              <div>${formatNumber(subTotal)}</div>
            </Box>
            {!!discounts && (
              <Box className={styles.summaryItem}>
                <div>Discounts</div>
                <div>- ${formatNumber(discounts)}</div>
              </Box>
            )}
            {!!serviceCreditsValue && (
              <Box className={styles.summaryItem}>
                <div>Service credits</div>
                <div>- ${formatNumber(serviceCreditsValue)}</div>
              </Box>
            )}
            {checkoutTenders
              .filter(
                ({ tenderType, value }: { tenderType: string; value: number }) =>
                  value > 0 && TENDER_TYPE_TO_SHOW.includes(tenderType)
              )
              // eslint-disable-next-line react/no-unused-prop-types
              .map(({ tenderType, value }: { tenderType: string; value: number }) => (
                <Box className={styles.summaryItem}>
                  <div
                    /* eslint-disable-next-line react/no-danger */
                    dangerouslySetInnerHTML={{ __html: TENDER_TYPE_NAMES[tenderType] }}
                  />
                  <div>- ${formatNumber(value)}</div>
                </Box>
              ))}
            {!!referralValue && (
              <Box className={styles.summaryItem}>
                <div>Referral credit</div>
                <div>- ${formatNumber(referralValue)}</div>
              </Box>
            )}
            {!!accountCreditUsed && (
              <Box className={styles.summaryItem}>
                <div>Account Credit</div>
                <div>- ${formatNumber(accountCreditUsed)}</div>
              </Box>
            )}
            {!!amountPaid && amountPaid < toPay && (
              <Box className={styles.summaryItem}>
                <div>Processed</div>
                <div>- ${formatNumber(amountPaid)}</div>
              </Box>
            )}
            <Box className={styles.summaryItem}>
              <h2>Total</h2>
              <h2>${formatNumber(total)}</h2>
            </Box>
            {!!cashAmount && (
              <>
                <hr />
                <br />
              </>
            )}
            {!!cashAmount && (
              <Box className={styles.summaryItem}>
                <div>Cash Received</div>
                <div>${formatNumber(+cashAmount)}</div>
              </Box>
            )}
            {!!cashAmount && +cashAmount > total && (
              <Box className={styles.summaryItem}>
                <div>Cashback</div>
                <div>${formatNumber(+cashAmount - total)}</div>
              </Box>
            )}
            <Box className={styles.goToSummaryContainer}>
              <button
                type="button"
                className={styles.goToSummaryButton}
                disabled={loading || !canPayCheckout || !checkValid() || !isPractitioner}
                onClick={handleOpenSummary}
              >
                {chargeType === 'square' || checkout.toPay === 0 ? 'CHECKOUT' : 'GO TO SUMMARY'}
              </button>
              {!canPayCheckout && (
                <Tooltip title="Please complete the inventory steps!">
                  <IconButton>
                    <HelpCenterIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
        </>
      )}
      <ServiceVisitCheckoutSummaryModal
        open={openSummaryModal && isPractitioner}
        patientPaymentProcessor={patientPaymentProcessor}
        onClose={() => setOpenSummaryModal(false)}
        total={total}
        subTotal={subTotal}
        accountCreditUsed={accountCreditUsed}
        discounts={discounts}
        checkoutTenders={checkoutTenders}
        referralValue={referralValue}
        serviceCreditsValue={serviceCreditsValue}
        cashAmount={cashAmount ? +cashAmount : 0}
        selectedCard={chargeType === CARD_CHARGE_TYPE ? selectedCard : undefined}
        handlePayment={handlePayment}
        lineItems={lineItems}
        chargeType={chargeType}
        checkoutChargeType={checkoutChargeType}
        transactionStatus={transactionStatus}
        checkoutSignatureUrl={checkoutSignatureUrl}
        updateCheckout={updateCheckout}
      />
    </div>
  );
};
