/* eslint-disable max-len */
import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError } from 'axios';
import { MedicalLicenseDetails } from 'src/interfaces/onboarding.interface';
import { dispatch } from 'src/rematch';
import { ExternalMedicalDirectorApi, SignAgreementResponse } from '../../api/externalMedicalDirector';

export const useSignAgreement = (): UseMutationResult<SignAgreementResponse, AxiosError, MedicalLicenseDetails> =>
  useMutation(
    (payload: MedicalLicenseDetails) =>
      ExternalMedicalDirectorApi.signAgreement(payload).then((response) => response.data),
    {
      onSuccess: () => {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: 'Agreement signed successfully',
            type: 'success',
          },
        });
      },
      onError: (error: AxiosError) => {
        const errorMessage = error.response?.data?.errors || 'Failed to sign agreement';
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: errorMessage,
            type: 'error',
          },
        });
      },
    }
  );
