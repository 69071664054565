export const PORTRAIT_ORDERING_SQUARE_LOCATION_ID = 'LPZKG9CW76C0S';

export enum SQUARE_STATUS {
  ERROR = 'error',
  OK = 'ok',
  PENDING = 'pending',
  PARTIAL_REFUNDED = 'partial_refunded',
}

export const SQUARE_ERROR_CODES: any = {
  payment_canceled: 'Payment Canceled',
  amount_invalid_format: 'Amount Invalid Format',
  amount_too_large: 'Amount Too Large',
  amount_too_small: 'Amount Too Small',
  client_not_authorized_for_user: 'Client not Authorized for User',
  could_not_perform: 'Could not Perform',
  currency_code_mismatch: 'Currency Code Mismatch',
  currency_code_missing: 'Currency Code Missing',
  customer_management_not_supported: 'Customer Management not Supported',
  data_invalid: 'Data Invalid',
  invalid_customer_id: 'Invalid Customer Id',
  invalid_tender_type: 'Invalid Tender Type',
  no_network_connection: 'No Network Connection',
  not_logged_in: 'Not Logged In',
  unsupported_api_version: 'Unsupported API Version',
  unsupported_currency_code: 'Unsupported Currency Code',
  unsupported_tender_type: 'Unsupported Tender Type',
  user_id_mismatch: 'user ID Mismatch',
  user_not_active: 'User not Active',
};

export const SQUARE_ERROR_MESSAGES: any = {
  payment_canceled: 'The payment was cancelled',
  amount_invalid_format: 'The amount has an invalid form',
  amount_too_large: 'The amount is too large',
  amount_too_small: 'The amount is to small',
  client_not_authorized_for_user: 'The client is not authorize for the user',
  could_not_perform: 'The transaction could not be perform',
  currency_code_mismatch: 'The currency does not match',
  currency_code_missing: 'The currency code is missing',
  customer_management_not_supported: 'Customer management is not supported',
  data_invalid: 'The data is invalid',
  invalid_customer_id: 'The customer has an invalid id',
  invalid_tender_type: 'Invalid tender type',
  no_network_connection: 'Without connection',
  not_logged_in: 'Not logged in',
  unsupported_api_version: 'Unsupported API version',
  unsupported_currency_code: 'Unsupported currency code',
  unsupported_tender_type: 'Unsupported tender type',
  user_id_mismatch: 'User ID mismatch',
  user_not_active: 'User is not active',
};
