// they should match app/models/medspa_onboarding_progress.rb
const ACCOUNT_SETTINGS_NAME = 'account-settings';
const PROVIDERS_MANAGEMENT_NAME = 'providers-management';
const LOCATION_MANAGEMENT_NAME = 'locations-management';
const SERVICE_MANAGEMENT_NAME = 'services-management';
const INVENTORY_ORDER_NAME = 'make-your-first-order';
const PATIENTS_MANAGEMENT_NAME = 'patients-management';
const APPOINTMENT_TYPES_NAME = 'appointment-types';
const CALENDAR_SET_UP_NAME = 'calendar-set-up';

export const ONBOARDING_STEPS = {
  ACCOUNT_SETTINGS_NAME,
  PROVIDERS_MANAGEMENT_NAME,
  LOCATION_MANAGEMENT_NAME,
  SERVICE_MANAGEMENT_NAME,
  INVENTORY_ORDER_NAME,
  PATIENTS_MANAGEMENT_NAME,
  APPOINTMENT_TYPES_NAME,
  CALENDAR_SET_UP_NAME,
};

export const ONBOARDING_STEPS_ORDER = [
  ONBOARDING_STEPS.ACCOUNT_SETTINGS_NAME,
  ONBOARDING_STEPS.INVENTORY_ORDER_NAME,
  ONBOARDING_STEPS.PROVIDERS_MANAGEMENT_NAME,
  ONBOARDING_STEPS.LOCATION_MANAGEMENT_NAME,
  ONBOARDING_STEPS.SERVICE_MANAGEMENT_NAME,
  ONBOARDING_STEPS.PATIENTS_MANAGEMENT_NAME,
  ONBOARDING_STEPS.APPOINTMENT_TYPES_NAME,
  ONBOARDING_STEPS.CALENDAR_SET_UP_NAME,
];

export const stepTitles: Record<string, string> = {
  [ONBOARDING_STEPS.ACCOUNT_SETTINGS_NAME]: 'Account Settings',
  [ONBOARDING_STEPS.INVENTORY_ORDER_NAME]: 'Make your first order',
  [ONBOARDING_STEPS.PROVIDERS_MANAGEMENT_NAME]: 'Add Providers to MedSpa',
  [ONBOARDING_STEPS.LOCATION_MANAGEMENT_NAME]: 'Create Locations',
  [ONBOARDING_STEPS.SERVICE_MANAGEMENT_NAME]: 'Add your Services',
  [ONBOARDING_STEPS.PATIENTS_MANAGEMENT_NAME]: 'Add your Patients',
  [ONBOARDING_STEPS.APPOINTMENT_TYPES_NAME]: 'Appointment Types',
  [ONBOARDING_STEPS.CALENDAR_SET_UP_NAME]: 'Set up your first Calendar',
};

export const SHOW_SUCCESS_MESSAGE = 'first_time_onboarding_completed';

// Video URLs
export const ONBOARDING_VIDEOS = {
  PROVIDERS_MANAGEMENT_NAME:
    'https://www.loom.com/embed/93e0e92634104f38a2773a76e7ffec64?sid=4361b63a-f9d6-4ba7-826e-155e6f8fab99',
  LOCATION_MANAGEMENT_NAME:
    'https://www.loom.com/embed/289c6c6a504448c7b173e933b841c24b?sid=06aa1c60-0577-4790-8458-4838402c81b0',
  SERVICE_MANAGEMENT_NAME:
    'https://www.loom.com/embed/98c98a9fa2c041928572ba53f9b0b806?sid=29efbc87-c50e-4e73-adc0-5ccc2f5f8f48',
  INVENTORY_ORDER_NAME:
    'https://www.loom.com/embed/a1552df487ad4056980c3c22a094067c?sid=7a345fb1-cea6-4c33-8779-90b6806e1070',
  PATIENTS_MANAGEMENT_NAME:
    'https://www.loom.com/embed/57686ddb9fb54ba4994dec9bfc73a1c5?sid=967493fe-74e2-43e2-9093-d0a372267b04',
  APPOINTMENT_TYPES_NAME:
    'https://www.loom.com/embed/57686ddb9fb54ba4994dec9bfc73a1c5?sid=967493fe-74e2-43e2-9093-d0a372267b04',
  CALENDAR_SET_UP_NAME:
    'https://www.loom.com/embed/57686ddb9fb54ba4994dec9bfc73a1c5?sid=967493fe-74e2-43e2-9093-d0a372267b04',
};
