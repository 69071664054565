import React from 'react';

import { Grid } from '@material-ui/core';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';
import { PORTRAIT_ORDERING_SQUARE_LOCATION_ID } from 'src/constants/square.constants';
// eslint-disable-next-line import/no-extraneous-dependencies
import Square from '@square/web-sdk';
import { RootState } from 'src/rematch';
import { useSelector } from 'react-redux';
import { STRIPE_PAYMENT_PROCESSOR } from 'src/constants/general.constants';
import { EHRTypography } from '../ui/v1';

interface NewCreditCardProps {
  onSave: (details: Square.TokenDetails | undefined, token: string) => void;
  disabled?: boolean;
}

const NewCreditCard: React.FC<NewCreditCardProps> = ({ onSave, disabled = true }) => {
  const applicationId = process.env.REACT_APP_SQUARE_APPLICATION_ID || '';
  const { paymentProcessor } = useSelector(({ auth }: RootState) => auth);

  const handleCard = async ({ status, token, details }: Square.TokenResult): Promise<void> => {
    if (status === 'OK' && !!token) {
      onSave(details, token);
    }
  };

  const renderPaymentForm = () => {
    if (paymentProcessor === STRIPE_PAYMENT_PROCESSOR) {
      return <p>Stripe component placeholder</p>;
    }

    return (
      <PaymentForm
        applicationId={applicationId}
        locationId={PORTRAIT_ORDERING_SQUARE_LOCATION_ID}
        cardTokenizeResponseReceived={handleCard}
      >
        <CreditCard
          includeInputLabels
          buttonProps={{
            isLoading: disabled,
            css: {
              background: '#12574D',
              width: '30%',
              display: 'flex',
              justifyContent: 'center',
              marginLeft: 'auto',
            },
          }}
        >
          <span>
            <>Add Card</>
          </span>
        </CreditCard>
      </PaymentForm>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <EHRTypography dataCy="" variant="body2">
          Credit Card Info
        </EHRTypography>
      </Grid>
      <Grid item xs={12} style={{ width: '100%' }}>
        {renderPaymentForm()}
      </Grid>
    </Grid>
  );
};

export default NewCreditCard;
