import React, { FC } from 'react';
import { Box, FormControl, MenuItem } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { EHRCheckbox, EHRButton, EHRSelect } from 'src/components/ui/v1';
import { usStates } from '../../../constants/general.constants';
import InputSearch from '../../common/InputSearch';
import { hasAccessTo } from '../../../utils/auth.utils';
import { CREATE_PHYSICIAN } from '../../../constants/actions.constants';

interface PhysicianHeaderProps {
  inactiveChecked: boolean;
  onChangeInactiveCheckbox: () => void;
  onSearch: (event: React.ChangeEvent) => void;
  selectedState: string;
  setSelectedState: (state: string) => void;
  isLoading?: boolean;
}

const PhysicianHeader: FC<PhysicianHeaderProps> = ({
  inactiveChecked,
  onChangeInactiveCheckbox,
  onSearch,
  selectedState,
  setSelectedState,
  isLoading = false,
}) => {
  const permissions = useSelector(({ auth }: any) => auth.permissions);
  const NEW_PHYSICIAN_URL = '/administrator/new-physician';

  const statesList = Object.entries(usStates).map(([abbr, name]) => ({
    abbr,
    name,
  }));

  return (
    <Box display="flex" justifyContent="space-between" gridGap="1rem" flexWrap="wrap">
      <Box display="flex" flexGrow={1} gridGap="1rem" flexWrap="wrap">
        <Box>
          <FormControl variant="outlined">
            <InputSearch placeholder="Search by name or email..." onChangeValue={onSearch} isLoading={isLoading} />
          </FormControl>
        </Box>

        <Box>
          <FormControl variant="outlined">
            <EHRCheckbox
              dataCy="showInactive"
              label="Show Inactive physicians"
              checked={inactiveChecked}
              onChange={onChangeInactiveCheckbox}
              value="order"
            />
          </FormControl>
        </Box>

        <Box>
          <FormControl variant="outlined" style={{ width: '150px' }}>
            <EHRSelect
              dataCy="stateFilter"
              labelId="state"
              fullWidth
              value={selectedState || 'all'}
              onChange={(value: string | number) => setSelectedState(value.toString())}
            >
              <MenuItem value="all">All States</MenuItem>
              {statesList.map(({ name }) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </EHRSelect>
          </FormControl>
        </Box>
      </Box>
      <Box>
        {hasAccessTo(CREATE_PHYSICIAN, permissions) && (
          <EHRButton dataCy="new-physician" text="New Physician" href={NEW_PHYSICIAN_URL} color="primary" />
        )}
      </Box>
    </Box>
  );
};

export default PhysicianHeader;
