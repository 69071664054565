import { OnboardingDoneFooter, OnboardingFooter } from 'src/pages/MedspaAdmin/Onboarding/components/OnboardingFooter';
import { hasActiveShippingAddress } from 'src/utils/onboarding.utils';
import { StepsCompleted } from 'src/services/MedspaAdmins';
import { getNextStep } from 'src/pages/MedspaAdmin/Onboarding/utils';
import { useSelector } from 'react-redux';
import { RootState } from 'src/rematch';
import { useEffect, useMemo, useState } from 'react';
import { ACCOUNT_SETTINGS_STEPS } from 'src/pages/Onboarding/AccountSettings/constants';
import { useMedspaAdminInfo, useMedspaAdminOnboardingProgress } from './queries/useMedspaAdmins';
import { useCurrentUserGroup } from './queries/useUserGroups';
import { MEDSPA_ADMIN_ROLE } from '../constants/general.constants';

const useMedspaAdminOnboarding = (step?: string) => {
  const [isMedspaOwner, setIsMedspaOwner] = useState(false);
  const { userGroupId, userId, roleName, isLoading: isLoadingUser } = useSelector(({ auth }: RootState) => auth);
  const isMedspa = roleName === MEDSPA_ADMIN_ROLE && !isLoadingUser;

  const { data: medspaAdminInfo, isLoading: isLoadingMedspaAdminInfo } = useMedspaAdminInfo(isMedspa);
  const {
    data: userGroupData,
    isLoading: isLoadingUserGroup,
    refetch: refetchUserGroup,
  } = useCurrentUserGroup(isMedspa);

  const { data: onboardingProgress, isLoading: isLoadingOnboardingProgress } = useMedspaAdminOnboardingProgress(
    step ?? '',
    isMedspaOwner
  );

  const isLoading = isLoadingMedspaAdminInfo || isLoadingUserGroup || isLoadingOnboardingProgress;

  const commercialShippingAddress = userGroupData?.commercialShippingAddress;
  const hasAcknowledgedInventoryDisclaimer = userGroupData?.hasAcknowledgedInventoryDisclaimer;

  const isPendingCommercialShippingAddress =
    !commercialShippingAddress || !hasActiveShippingAddress(commercialShippingAddress);

  const userGroupMD = userGroupData?.medicalDirector;
  const externalMD = userGroupMD?.externalMedicalDirector;

  const hasPendingExternalMD = externalMD?.id && !externalMD?.agreementPdf;

  const doNotHaveMedicalDirector =
    userGroupMD?.hasNoMedicalDirector === true && userGroupMD?.medicalDirectorId === null;

  const isMissingMDInfo =
    !userGroupMD?.medicalDirectorId && !externalMD && !userGroupMD?.ownMedicalDirector?.isOwnMedicalDirector;

  const isPendingMedicalDirector = isMissingMDInfo || Boolean(hasPendingExternalMD) || doNotHaveMedicalDirector;

  const nextStep = getNextStep(step ?? '');
  const nextStepIsCompleted = Boolean(onboardingProgress?.stepsCompleted?.[nextStep as keyof StepsCompleted]);

  const showFooter =
    medspaAdminInfo?.onboarding &&
    medspaAdminInfo?.onboardingCompleted &&
    !medspaAdminInfo?.allStepsCompleted &&
    !nextStepIsCompleted;

  const showDoneFooter = true;

  const FooterComponent = showFooter ? OnboardingFooter : () => null;
  const DoneFooterComponent = showDoneFooter ? OnboardingDoneFooter : () => null;

  // If the user is the Medspa owner, should check if onboarding is enabled for them
  const onboardingEnabled = medspaAdminInfo?.onboarding;

  // If the user is not the Medspa owner, should check if onboarding is enabled
  // for the owner
  const medspaOnboardingEnabled = userGroupData?.owner?.onboardingEnabled;

  const isOnboardingEnabled = isMedspaOwner ? onboardingEnabled : medspaOnboardingEnabled;
  const pendingCommercialShippingAddress = !!userGroupId && isPendingCommercialShippingAddress && isOnboardingEnabled;
  const pendingMedicalDirector = !!userGroupId && isPendingMedicalDirector && isOnboardingEnabled;
  const pendingAcknowledgedInventoryDisclaimer =
    !!userGroupId && !hasAcknowledgedInventoryDisclaimer && isOnboardingEnabled;

  const missingFields = useMemo(
    () =>
      [
        pendingCommercialShippingAddress ? ACCOUNT_SETTINGS_STEPS.SHIPPING_ADDRESS : '',
        pendingMedicalDirector ? ACCOUNT_SETTINGS_STEPS.MEDICAL_DIRECTOR : '',
        pendingAcknowledgedInventoryDisclaimer ? ACCOUNT_SETTINGS_STEPS.INVENTORY_AGREEMENT : '',
      ].filter((field) => field !== ''),
    [pendingCommercialShippingAddress, pendingMedicalDirector, hasAcknowledgedInventoryDisclaimer]
  );

  useEffect(() => {
    setIsMedspaOwner(userGroupData?.ownerId === userId && isMedspa);
  }, [userGroupData?.ownerId]);

  return {
    FooterComponent,
    DoneFooterComponent,
    showFooter,
    onboardingEnabled,
    onboardingCompleted: medspaAdminInfo?.onboardingCompleted,
    allStepsCompleted: medspaAdminInfo?.allStepsCompleted,
    pendingCommercialShippingAddress,
    pendingMedicalDirector,
    pendingAcknowledgedInventoryDisclaimer,
    isLoading,
    isLoadingMedspaAdminInfo,
    userGroupData,
    refetchUserGroup,
    missingFields,
    isMedspaOwner,
  };
};

export default useMedspaAdminOnboarding;
