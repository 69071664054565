import { Box, Button, ButtonGroup, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import React from 'react';
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from '@material-ui/icons';
import moment from 'moment';
import { PORTRAIT_LEGACY, PORTRAIT_LEGACY_PLUS } from 'src/constants/general.constants';
import useCurrentUserGroup from 'src/hooks/queries/useUserGroups';
import { VIEW_NAME_DAY, VIEW_NAME_WEEK, VIEW_NAME_WEEK_PROVIDER } from '../indexV2';

const useStyles = makeStyles(() => ({
  viewSwitcherContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    padding: '0.5rem',
  },
  dateNavigator: {
    padding: '0.6rem',
    fontSize: '1rem',
    display: 'flex',
    width: '40%',
    justifyContent: 'center',
  },
  dateFormat: {
    padding: '0 1rem',
  },
  buttons: {
    display: 'flex',
    float: 'left',
    width: '30%',
    gap: '1rem',
    height: '2.1rem',
  },
}));

interface Props {
  startHour: number;
  view: string;
  currentDate: Date;
  onCurrentDateChange: (date: Date) => void;
  onCurrentViewNameChange: (view: string) => void;
}
const CustomViewSwitcher = ({ startHour, view, currentDate, onCurrentDateChange, onCurrentViewNameChange }: Props) => {
  const classes = useStyles();
  const { data: userGroup } = useCurrentUserGroup();
  const isLegacy = userGroup?.roleName === PORTRAIT_LEGACY || userGroup?.roleName === PORTRAIT_LEGACY_PLUS;

  const onChangeDate = (direction: string) => {
    const type = view === VIEW_NAME_DAY ? 'day' : 'week';
    if (direction === 'left') {
      onCurrentDateChange(moment(currentDate).subtract(1, type).toDate());
    } else {
      onCurrentDateChange(moment(currentDate).add(1, type).toDate());
    }
  };

  const today = moment().startOf('day').toDate();
  const selectedDate = moment(currentDate).startOf('day').toDate();

  const handleBookAppointment = () => {
    const startDate = moment(currentDate)
      .hour(startHour || 0)
      .minute(0)
      .second(0)
      .millisecond(0);
    const formattedDate = startDate.toISOString();
    const targetCell = document.querySelector(`[data-id="time-table-cell-${formattedDate}"]`);
    targetCell?.dispatchEvent(new MouseEvent('click', { bubbles: true }));
  };

  return (
    <Box className={classes.viewSwitcherContainer}>
      <Box className={classes.buttons}>
        <ButtonGroup variant="contained" aria-label="Basic button group">
          <Button
            dataCy="dayBtn"
            style={{ minWidth: '5rem ' }}
            onClick={() => onCurrentViewNameChange(VIEW_NAME_DAY)}
            color={view === VIEW_NAME_DAY ? 'primary' : 'gray'}
          >
            {VIEW_NAME_DAY}
          </Button>
          <Button
            dataCy="dayBtn"
            size="small"
            style={{ minWidth: '5rem ' }}
            onClick={() => onCurrentViewNameChange(VIEW_NAME_WEEK)}
            color={[VIEW_NAME_WEEK, VIEW_NAME_WEEK_PROVIDER].includes(view) ? 'primary' : 'gray'}
          >
            {VIEW_NAME_WEEK}
          </Button>
        </ButtonGroup>
        {!isLegacy && today.getTime() !== selectedDate.getTime() && (
          // eslint-disable-next-line max-len
          <Tooltip title="Click to navigate to today's date and view appointments and availability for the selected location or provider.">
            <Button
              style={{ minWidth: '5rem ' }}
              variant="outlined"
              onClick={() => onCurrentDateChange(moment().toDate())}
            >
              Today
            </Button>
          </Tooltip>
        )}
      </Box>
      <Box className={classes.dateNavigator}>
        <IconButton aria-label="left" size="small" style={{ display: 'flex' }} onClick={() => onChangeDate('left')}>
          <ChevronLeftIcon fontSize="inherit" />
        </IconButton>
        {view === VIEW_NAME_DAY ? (
          <Box className={classes.dateFormat}>{moment(currentDate).format('ll')}</Box>
        ) : (
          <Box className={classes.dateFormat}>
            {moment(currentDate).endOf('week').format('MMMM ')}
            {moment(currentDate).startOf('week').format('DD')}-{moment(currentDate).endOf('week').format('DD, YYYY')}
          </Box>
        )}
        <IconButton aria-label="left" size="small" style={{ display: 'flex' }} onClick={() => onChangeDate('right')}>
          <ChevronRightIcon fontSize="inherit" />
        </IconButton>
      </Box>
      <Box width="30%" justifyContent="end" display="flex">
        <Button
          style={{ minWidth: '5rem ', height: '2.1rem' }}
          variant="contained"
          color="primary"
          onClick={() => {
            handleBookAppointment();
          }}
        >
          Book Appointment
        </Button>
      </Box>
    </Box>
  );
};

export { CustomViewSwitcher };
