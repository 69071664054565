/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useMemo, useState } from 'react';
import { Button } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Checkbox from '@material-ui/core/Checkbox';
import { chunk, difference } from 'lodash';
import { useStyles } from './OtherTenders.styles';
import { SQUARE_STATUS } from '../../../constants/square.constants';
import { IPatientBasicInfo } from '../../../types/Patient';
import { IReferralCredits } from '../../../types/ReferralCredits';
import { AMOUNT_FOR_REFERRER, TOTAL_AMOUNT_REDEEMABLE } from '../../../constants/general.constants';
import { formatNumber } from '../../../utils/checkout.utils';
import { useApplyReferralCredits } from '../../../hooks/serviceVisit/useReferrals';

export const ReferralCredits = ({
  referralCredits,
  patient,
  transactionStatus,
  appliedReferralCredits = [],
  isLoadingAppliedReferred,
  serviceVisitId,
  refetchAppliedReferralCredits,
}: {
  referralCredits: IReferralCredits[];
  patient: IPatientBasicInfo;
  transactionStatus: string;
  appliedReferralCredits: IReferralCredits[];
  isLoadingAppliedReferred: boolean;
  serviceVisitId: number;
  refetchAppliedReferralCredits: () => void;
}) => {
  const [openReferralModal, setOpenReferralModal] = useState<boolean>(false);
  const classes = useStyles();
  const { isAdvocate, hasReferred } = patient;
  const notRedeemed = useMemo(
    () => referralCredits.filter((referralCredit) => !referralCredit.redeemed),
    [referralCredits]
  );
  const redeemed = useMemo(
    () => referralCredits.filter((referralCredit) => referralCredit.redeemed),
    [referralCredits]
  );
  const [selectedReferralCredits, setSelectedReferralCredits] = useState<number[]>(
    appliedReferralCredits.map((referralCredit) => referralCredit.id).sort()
  );
  const { mutateAsync: updateServiceReferralCredits, isLoading: isLoadingMutation } = useApplyReferralCredits(
    +serviceVisitId
  );

  useEffect(() => {
    // Update selected with info of refetch on focus
    const appliedReferredIds = appliedReferralCredits.map((referralCredit) => referralCredit.id);
    const isAppliedReferredUpdated = appliedReferredIds.sort().toString() !== selectedReferralCredits.sort().toString();
    if (!isLoadingAppliedReferred && isAppliedReferredUpdated) {
      setSelectedReferralCredits(appliedReferralCredits.map((referralCredit) => referralCredit.id).sort());
    }
  }, [appliedReferralCredits, isLoadingAppliedReferred]);

  const getAvailableCount = (): number => {
    if (!isAdvocate && hasReferred) {
      return transactionStatus === SQUARE_STATUS.OK
        ? notRedeemed.length
        : Math.max(notRedeemed.length - selectedReferralCredits.length, 0);
    }
    if (transactionStatus === SQUARE_STATUS.OK) {
      return Math.trunc(notRedeemed.length / 5);
    }
    return notRedeemed.length >= 5 ? Math.trunc((notRedeemed.length - selectedReferralCredits.length) / 5) : 0;
  };
  const getAvailableReferralsLabel = (): string => `${getAvailableCount()} Available ($${getAvailableAmount()})`;

  const getRedeemedReferralsLabel = (): string => {
    const amount =
      !isAdvocate && hasReferred
        ? selectedReferralCredits.length * AMOUNT_FOR_REFERRER
        : (selectedReferralCredits.length / 5) * TOTAL_AMOUNT_REDEEMABLE;
    const redeemedValue = formatNumber(amount);
    return selectedReferralCredits.length ? `$${redeemedValue}` : '';
  };

  const getAvailableAmount = (): number => {
    /* if is advocate can redeem 250 (5 referral)
     * if is not advocate but has referred can redeem by 50 (1 referred)
     */
    if (!isAdvocate && hasReferred) {
      // TODO: AMOUNT_FOR_REFERRER needs to be changed by referral value in future
      return getAvailableCount() * AMOUNT_FOR_REFERRER;
    }
    return getAvailableCount() * TOTAL_AMOUNT_REDEEMABLE;
  };

  const getAvailableCreditLabel = (): string => `$${getAvailableAmount()} In redeemable credit`;

  const applyReferrals = async ({ apply, id, ids }: { apply: boolean; id?: number; ids?: number[] }) => {
    let newIds = selectedReferralCredits;
    if (apply) {
      if (id) {
        newIds = [...selectedReferralCredits, id];
      } else if (ids) {
        newIds = [...selectedReferralCredits, ...ids];
      }
    } else if (id) {
      const filteredIds = selectedReferralCredits.filter((referralId) => referralId !== id);
      newIds = filteredIds;
    } else if (ids) {
      newIds = difference(selectedReferralCredits, ids);
    }

    if (newIds.sort().toString() !== selectedReferralCredits.sort().toString()) {
      await updateServiceReferralCredits({ referralIds: newIds });
      await refetchAppliedReferralCredits();
    }
  };

  const availableReferralsOptions = notRedeemed.map((referral: IReferralCredits) => (
    <div className={classes.referralsAvailableSection} key={`referral-${referral.id}`}>
      <div className={classes.referralsAvailable}>
        <Checkbox
          color="primary"
          checked={selectedReferralCredits.includes(referral.id)}
          disabled={isLoadingAppliedReferred || isLoadingMutation}
          onChange={() => {
            applyReferrals({ id: referral.id, apply: !selectedReferralCredits.includes(referral.id) });
          }}
        />
        <div className={classes.referralsAvailableAmount}>$ {referral.value}</div>
        <div className={classes.referralsAvailableName}>{referral.fullName}</div>
      </div>
    </div>
  ));

  const advocateAvailableReferralOptions = chunk(notRedeemed, 5).map((referrals, index: number) => {
    const referralIds = referrals.map((referral) => referral.id);
    const intersection = difference(referralIds, selectedReferralCredits);
    return (
      <div className={classes.referralsAvailableSection} key={`advocate-${index}`}>
        <div className={classes.buttonSection}>
          {intersection.length === 0 ? (
            <Button
              className={classes.removeButton}
              disabled={isLoadingAppliedReferred || isLoadingMutation}
              onClick={() => {
                applyReferrals({ ids: referralIds, apply: false });
              }}
            >
              Remove Credit
            </Button>
          ) : (
            <Button
              className={classes.applyButton}
              onClick={() => {
                applyReferrals({ ids: referralIds, apply: true });
              }}
              disabled={referrals.length < 5 || isLoadingAppliedReferred || isLoadingMutation}
            >
              Apply $250
            </Button>
          )}
        </div>
        {referrals.map(({ value, fullName, id }) => (
          <div className={classes.referralsAvailable} key={`ref-${id}`}>
            <div className={classes.referralsAvailableAmount}>$ {value}</div>
            <div className={classes.referralsAvailableName}>{fullName}</div>
          </div>
        ))}
      </div>
    );
  });

  return (
    <>
      <div className={classes.mainSection}>
        <div className={classes.referralCreditTitle}>Referral Credit</div>
        <div className={classes.availableCreditSection}>
          <div className={classes.availableLeft}>
            <div className={classes.availableLabel}>{getAvailableReferralsLabel()}</div>
            <Button
              className={classes.plusButton}
              onClick={() => {
                setOpenReferralModal(true);
              }}
            >
              +
            </Button>
          </div>
          <div className={classes.availableRight}>{getRedeemedReferralsLabel()}</div>
        </div>
      </div>
      <Dialog
        open={openReferralModal}
        onClose={() => setOpenReferralModal(false)}
        PaperProps={{ className: classes.dialog }}
      >
        <div className={classes.header}>
          <span className={classes.referralTitle}>Apply Referral Credit</span>
          <div onClick={() => setOpenReferralModal(false)}>
            <CloseIcon />
          </div>
        </div>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.stripe}>
            <div className={classes.stripeTitle}>Available</div>
            <div className={classes.stripeTitleRight}>{getAvailableCreditLabel()}</div>
          </div>
          {!isAdvocate && hasReferred ? availableReferralsOptions : advocateAvailableReferralOptions}
          <div className={classes.stripe}>
            <div className={classes.stripeTitle}>Redeemed</div>
          </div>
          <div className={classes.redeemedSection}>
            {redeemed.map(({ fullName }) => (
              <div className={classes.redeemedNames}>{fullName}</div>
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
