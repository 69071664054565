import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { CardContent } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import { NavigationBar } from '../../components/PatientProfile/NavigationBar';
import PatientDetailHeader from '../../components/PatientProfile/PatientDetailHeader';
import { useStyles } from './index.styles';
import { PATIENT_INFO } from '../../constants/reactQuery.keys';
import Patients from '../../services/Patients';
import { dispatch } from '../../rematch';
import { checkoutInfo, formatNumber } from '../../utils/checkout.utils';
import { TendersObject } from '../../interfaces/checkout.interfaces';
import { MultipleSkeleton } from '../../components/common/LoadingSkeleton';
import { SQUARE_STATUS } from '../../constants/square.constants';
import { ManageCards } from '../PatientCreditCards/components/ManageCards';
import { ConfirmChargeModal } from '../PatientChargeCard/components/ConfirmChargeModal';
import SelectAndChargeCard from '../../components/PatientProfile/CreditCards/SelectAndChargeCard';

const CheckoutWithCard = () => {
  const classes = useStyles();
  const history = useHistory();
  const { patientId, serviceVisitId }: { patientId: string; serviceVisitId: string } = useParams();
  const [tabIndex, setTabIndex] = useState<number>();
  const [selectedCardId, setSelectedCardId] = useState<string>();
  const [processing, setProcessing] = useState<boolean>(false);
  const { services } = useSelector((store: any) => store.common);
  const serviceVisit = useSelector((state: any) => state.newServiceVisit);
  const { id } = useSelector(({ patient }: any) => patient);
  const [showConfirmChargeModal, setShowConfirmChargeModal] = useState<boolean>(false);
  const [tenders, setTenders] = useState<TendersObject>({});
  const { isLoadingProducts: isLoadingGaldermaProducts, isLoadingPatientCertificates } = useSelector(
    (state: any) => state.patientPointCertificates
  );
  const { checkout, isLoading, isLoadingFetchCheckout, isLoadingGetServiceVisitFromPatient } = serviceVisit;

  useEffect(() => {
    dispatch({
      type: 'common/fetchServices',
      payload: { contextServiceVisitId: serviceVisitId },
    });

    dispatch.patient.fetchPatientData(+patientId);

    dispatch({
      type: 'newServiceVisit/fetchCheckout',
      payload: {
        serviceVisitId,
        successCallback: () => null,
      },
    });
    dispatch.newServiceVisit.fetchDiscounts();

    dispatch({ type: 'referrals/getRedeemedCustomers', payload: { customerId: patientId } });
    dispatch.patient.fetchServiceGroups();
  }, []);

  const accountCreditUsed = checkout?.accountCreditUsed || 0;

  const { data: patient, isLoading: patientLoading } = useQuery(
    [PATIENT_INFO, patientId],
    () => Patients.getPatientInfo(patientId),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (id) {
      dispatch({
        type: 'newServiceVisit/getServiceVisitFromPatient',
        payload: { serviceVisitId: +serviceVisitId, currentPatientId: id },
      });
    }
  }, [id]);

  useEffect(() => {
    if (checkout) {
      const tendersObj: TendersObject = {};
      if (+checkout.aspireValue > 0) {
        tendersObj.aspire = { type: 'aspire', voucher: checkout.aspireVoucher, value: +checkout.aspireValue };
      }
      if (+checkout.brilliantValue > 0) {
        tendersObj.brilliant = {
          type: 'brilliant',
          voucher: checkout.brilliantVoucher,
          value: +checkout.brilliantValue,
        };
      }
      if (+checkout.careCreditValue > 0) {
        tendersObj.care_credit = {
          type: 'careCredit',
          voucher: checkout.careCreditVoucher,
          value: +checkout.careCreditValue,
        };
      }
      if (+checkout.xperienceValue > 0) {
        tendersObj.xperience = {
          type: 'xperience',
          voucher: checkout.xperienceVoucher,
          value: +checkout.xperienceValue,
        };
      }
      setTenders(tendersObj);
    }
  }, [checkout]);

  useEffect(() => {
    if (patient) {
      setSelectedCardId(patient.basicInfo.squareDefaultCreditCardId);
      if (tabIndex === undefined) {
        setTabIndex(patient.basicInfo.creditCards.length > 0 ? 0 : 1);
      }
    }
  }, [patient, isLoading]);

  useEffect(() => {
    if (checkout?.transactionStatus === SQUARE_STATUS.OK) {
      navigateToCheckout();
    }
  }, [checkout]);

  const checkoutInfoHash = useMemo(
    () =>
      checkoutInfo({
        services,
        serviceVisit,
        tenders,
        accountCreditUsed,
        // for regular in person service checkouts, account charges should be empty.
        accountChargeLineItems: [],
        referralsIds: checkout.redeemableIds,
      }),
    [services, serviceVisit, tenders, accountCreditUsed, checkout]
  );

  const navigateToCheckout = () => {
    history.push(`/patient/${patientId}/serviceVisit/${serviceVisitId}/checkout`);
  };

  const patientCards = [...(patient?.basicInfo?.creditCards || [])].reverse();

  const handleInitiateTransaction = () => {
    setProcessing(true);
    dispatch({
      type: 'newServiceVisit/checkoutServiceVisitWithCard',
      payload: {
        patientId,
        serviceVisitId,
        creditCardId: selectedCardId,
        successCallback: navigateToCheckout,
        errorCallback: () => setProcessing(false),
      },
    });
  };

  const ChargeCardTabs = () => (
    <div className={classes.tabsContainer}>
      <Button
        className={`${classes.mr10} ${tabIndex === 0 ? classes.activeTab : classes.inactiveTab}`}
        onClick={() => setTabIndex(0)}
      >
        Saved Payment Options
      </Button>
      <Button className={tabIndex === 1 ? classes.activeTab : classes.inactiveTab} onClick={() => setTabIndex(1)}>
        Add / Manage Cards
      </Button>
    </div>
  );

  if (
    isLoading ||
    patientLoading ||
    isLoadingFetchCheckout ||
    isLoadingGetServiceVisitFromPatient ||
    isLoadingPatientCertificates ||
    isLoadingGaldermaProducts
  ) {
    return <MultipleSkeleton />;
  }

  return (
    <div className={classes.mainContainer}>
      <div className={classes.navigationContainer}>
        <NavigationBar title="Charge Card" onBackButtonClick={navigateToCheckout} />
      </div>
      <PatientDetailHeader patient={patient} />
      <CardContent className={classes.card}>
        <ChargeCardTabs />
        {tabIndex === 0 ? (
          <SelectAndChargeCard
            selectedCardId={selectedCardId}
            onCardSelect={(e) => {
              // @ts-ignore
              setSelectedCardId(e.target.value);
            }}
            patientCards={patientCards}
            onCharge={() => setShowConfirmChargeModal(true)}
            loading={processing}
            buttonText={`Process $${formatNumber(+checkoutInfoHash?.toPay)}`}
          />
        ) : (
          <ManageCards patientId={patientId} afterCardAdded={() => setTabIndex(0)} />
        )}
      </CardContent>
      <ConfirmChargeModal
        chargeAmount={formatNumber(checkoutInfoHash.toPay)}
        card={patientCards.find((card) => card.id === selectedCardId)}
        handleCharge={handleInitiateTransaction}
        handleCloseCallback={() => setShowConfirmChargeModal(false)}
        open={showConfirmChargeModal}
      />
    </div>
  );
};

export default CheckoutWithCard;
