import React from 'react';
import { Box, Chip, FormControlLabel, Radio, TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import { IPatientBasicInfo } from 'src/types/Patient';
import { useStyles } from './ServiceVisitCheckoutContainer.styles';
import { formatNumber } from '../../utils/checkout.utils';

import { ISquareCard } from '../../interfaces/ISquareCard.interfaces';
import CreditCardIcon from '../../components/PatientProfile/CreditCards/CreditCardIcon';
import { CARD_CHARGE_TYPE, CASH_CHARGE_TYPE, SQUARE_CHARGE_TYPE } from './RemainingToPay';

interface PaymentOptionsProps {
  chargeType: string;
  setChargeType: (chargeType: string) => void;
  cashAmount: string | undefined;
  setCashAmount: (chargeType: string) => void;
  total: number;
  cards: ISquareCard[];
  selectedCard: ISquareCard | undefined;
  setSelectedCard: (card: any) => void;
  patient: IPatientBasicInfo;
}

export const PaymentOptions = ({
  chargeType,
  setChargeType,
  cashAmount,
  setCashAmount,
  total,
  cards,
  selectedCard,
  setSelectedCard,
  patient,
}: PaymentOptionsProps) => {
  const styles = useStyles();
  const history = useHistory();
  const patientId = patient.id;

  const onCardSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cardId = e.target.value;
    const userSelectedCard = cards.find((card) => card.id === cardId);
    setSelectedCard(userSelectedCard);
  };

  const navigateToAddCard = () => {
    history.push(`/patient/${patientId}/creditCards`);
  };

  return (
    <Box className={styles.paymentOptionContainer}>
      <h2>Payment Options</h2>
      <Box>
        <Chip
          key={CARD_CHARGE_TYPE}
          label="Card on File"
          className={chargeType === CARD_CHARGE_TYPE ? styles.activePill : styles.inactivePill}
          onClick={() => setChargeType(CARD_CHARGE_TYPE)}
        />
        <Chip
          key={SQUARE_CHARGE_TYPE}
          label="Charge with Square"
          className={chargeType === SQUARE_CHARGE_TYPE ? styles.activePill : styles.inactivePill}
          onClick={() => setChargeType(SQUARE_CHARGE_TYPE)}
        />
        <Chip
          key={CASH_CHARGE_TYPE}
          label="Cash"
          className={chargeType === CASH_CHARGE_TYPE ? styles.activePill : styles.inactivePill}
          onClick={() => setChargeType(CASH_CHARGE_TYPE)}
        />
      </Box>
      {chargeType === CARD_CHARGE_TYPE &&
        (cards.length ? (
          <>
            {cards.map((card: ISquareCard) => (
              <div>
                <FormControlLabel
                  value={card.id}
                  key={card.id}
                  // @ts-ignore
                  control={<Radio color="primary" checked={card.id === selectedCard?.id} onClick={onCardSelect} />}
                  label={
                    <div className={styles.cardName}>
                      Card ending in {card.last4}
                      <span className={styles.cardIcon}>
                        <CreditCardIcon cardBrand={card.cardBrand} />
                      </span>
                    </div>
                  }
                />
              </div>
            ))}
          </>
        ) : (
          <div className={styles.noCardsContainer}>
            <div>No cards on file</div>
            <button type="button" className={styles.noCardButton} onClick={navigateToAddCard}>
              Add a card
            </button>
          </div>
        ))}
      {chargeType === SQUARE_CHARGE_TYPE && <p>You will be re-directed to Square page to finish the checkout.</p>}
      {chargeType === CASH_CHARGE_TYPE && (
        <>
          <p>Enter the amount you received from your customer.</p>
          <NumberFormat
            decimalScale={2}
            fixedDecimalScale
            autoComplete="off"
            placeholder="$"
            customInput={TextField}
            variant="outlined"
            label="$"
            value={cashAmount}
            onChange={(e) => {
              e.preventDefault();
              setCashAmount(e.target.value);
            }}
            helperText={
              cashAmount && +cashAmount < total
                ? `Warning: Cash amount is less than the total to pay ($${formatNumber(total)})`
                : undefined
            }
            InputProps={{
              className: cashAmount && +cashAmount < total ? styles.warningInput : undefined,
            }}
          />
        </>
      )}
    </Box>
  );
};
