import React, { useEffect, useState } from 'react';
import { FormHelperText, Grid, makeStyles } from '@material-ui/core';
import Banner from 'src/components/common/Banner';
import { EHRCheckbox } from 'src/components/ui/v1';
import { useForm } from 'react-hook-form';
import { STEP_COMPLETED, STEP_EMPTY, STEP_LOADING, STEP_PENDING } from 'src/components/StepsTracker';
import { useStates } from 'src/hooks/queries/useStates';
import {
  CommercialShippingAddress,
  StepStatus,
  ShippingAddress as ShippingAddressType,
} from 'src/interfaces/onboarding.interface';
import { useCreateShippingAddress, useUpsertShippingAddress } from 'src/hooks/queries/useAccountSettings';
import { useUpdateMedspaAdminOnboardingProgress } from 'src/hooks/queries/useMedspaAdmins';
import { ONBOARDING_STEPS } from 'src/pages/MedspaAdmin/Onboarding/constants';
import { compressImage } from 'src/utils/image.utils';
import DocumentSigningForm from './DocumentSigningForm';
import DocumentForm from './DocumentForm';
import AddressForm from './AddressForm';

const useStyles = makeStyles({
  checkbox: {
    height: '100%',
    width: '100%',
  },
});

export type ShippingAddressProps = {
  stepId?: number;
  onStepUpdate?: (stepId: number, status: StepStatus) => void;
  commercialShippingAddress?: CommercialShippingAddress;
  progressPercentage?: number;
  onSuccess?: () => void;
  editMode?: boolean;
};

export type AddressDetails = {
  streetAddress: string;
  city: string;
  state: string;
  zip: string | number;
  document?: string | null;
  monthlyRent?: number | string;
  leaseStartDate?: Date | null;
  leaseTermLength?: string;
};

export const DEFAULT_ADDRESS_DETAILS: AddressDetails = {
  streetAddress: '',
  city: '',
  state: '',
  zip: '',
  document: null,
};

const ShippingAddress = ({
  stepId,
  onStepUpdate,
  progressPercentage,
  commercialShippingAddress,
  onSuccess = () => { },
  editMode = false,
}: ShippingAddressProps) => {
  const { shippingAddressAmendmentPdf, hasCommercialShippingAddress, proofOfBusinessAddress, ...addressDetails } =
    commercialShippingAddress || {};
  const {
    mutateAsync: createShippingAddress,
    isLoading: isLoadingCreate,
    isError: isErrorCreate,
    isSuccess: isSuccessCreate,
  } = useCreateShippingAddress();
  const {
    mutateAsync: upsertShippingAddress,
    isLoading: isLoadingUpsert,
    isError: isErrorUpsert,
    isSuccess: isSuccessUpsert,
  } = useUpsertShippingAddress();
  const requestIsLoading = isLoadingCreate || isLoadingUpsert;
  const requestHasErrors = isErrorUpsert || isErrorCreate;
  const requestIsSuccessful = isSuccessUpsert || isSuccessCreate;

  const { mutateAsync: updateProgress } = useUpdateMedspaAdminOnboardingProgress();
  const { data: states = [] } = useStates();
  const [fileHasErrors, setFileHasErrors] = useState(false);
  const [enableAgreementSigning, setEnableAgreementSigning] = useState(false);
  const classes = useStyles();
  const { control, watch, setValue, reset } = useForm({ defaultValues: DEFAULT_ADDRESS_DETAILS });
  const [isChecked, setIsChecked] = useState(false);
  const [amendmentPdf, setAmendmentPdf] = useState<string>('');
  const [disableForm, setDisableForm] = useState(false);

  const {
    address: userGroupAddress,
    city: userGroupCity,
    state: userGroupState,
    zip: userGroupZip,
    leaseStartDate,
    leaseTermLength,
    monthlyRent,
  } = addressDetails;

  const document = watch('document');
  const streetAddress = watch('streetAddress');
  const city = watch('city');
  const state = watch('state');
  const zip = watch('zip');

  const handleCheckboxChange = async (_: any, checked: boolean) => {
    if (checked) {
      await createShippingAddress({
        hasCommercialShippingAddress: !checked,
      });
      reset();
      setAmendmentPdf('');
    } else {
      onStepUpdate?.(stepId as number, STEP_EMPTY);
      setIsChecked(checked);
    }
  };

  const onFormSigned = async (data: ShippingAddressType) => {
    if (!editMode) {
      const res = await createShippingAddress({
        hasCommercialShippingAddress: true,
        address: streetAddress || '',
        zip: zip as string,
        city,
        state,
        ...(document !== proofOfBusinessAddress?.url && { document }),
        ...data,
      });

      if (res?.success) {
        onSuccess?.();
        updateProgress({
          stepName: ONBOARDING_STEPS.ACCOUNT_SETTINGS_NAME,
          percentage: progressPercentage as number,
        });
      }
    } else {
      const res = await upsertShippingAddress({
        shippingAddress: {
          address: streetAddress || '',
          zip: zip as string,
          city,
          state,
          ...(document !== proofOfBusinessAddress?.url && { document }),
          ...data,
        },
        commercialShippingAddressId: commercialShippingAddress?.id,
      });

      if (res?.success) {
        onSuccess?.();
      }
    }
  };

  const handleFileRead = (file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      setFileHasErrors(false);
      setValue('document', reader.result as string);
    };
    reader.onerror = () => {
      setFileHasErrors(true);
      onStepUpdate?.(stepId as number, STEP_EMPTY);
    };
    reader.readAsDataURL(file);
  };

  const handleFileInput = async (fileData: File[]) => {
    const file = fileData?.[0];
    if (file) {
      if (file.type === 'application/pdf') {
        handleFileRead(file);
        return;
      }

      compressImage({
        file,
        onSuccess: (result) => {
          handleFileRead(result as File);
        },
        onError: () => {
          setFileHasErrors(true);
          onStepUpdate?.(stepId as number, STEP_EMPTY);
        },
      });
    }
  };

  useEffect(() => {
    if (!isChecked) {
      setEnableAgreementSigning(!!document && !!streetAddress && !!city && !!state && !!zip);
    }
  }, [streetAddress, city, state, zip, document, isChecked]);

  useEffect(() => {
    const updateStepStatus = () => {
      if (requestIsLoading) {
        return STEP_LOADING;
      }
      if (hasCommercialShippingAddress == null || requestHasErrors) {
        return STEP_EMPTY;
      }
      if (hasCommercialShippingAddress && shippingAddressAmendmentPdf) {
        reset({
          streetAddress: userGroupAddress,
          city: userGroupCity,
          state: userGroupState,
          zip: userGroupZip,
        });
        setAmendmentPdf(shippingAddressAmendmentPdf);
        setValue('document', proofOfBusinessAddress?.url);
        if (!editMode) {
          setDisableForm(true);
        }
        return STEP_COMPLETED;
      }
      return STEP_PENDING;
    };

    const stepStatus = updateStepStatus();
    onStepUpdate?.(stepId as number, stepStatus as StepStatus);
    setIsChecked(hasCommercialShippingAddress == null ? false : !hasCommercialShippingAddress);
  }, [
    hasCommercialShippingAddress,
    requestHasErrors,
    requestIsLoading,
    shippingAddressAmendmentPdf,
    requestIsSuccessful,
  ]);

  const renderForm = (
    <>
      <AddressForm formControl={control} states={states || []} isFormDisabled={disableForm || requestIsLoading} />
      <DocumentForm
        fileHasErrors={fileHasErrors}
        handleFileInput={handleFileInput}
        file={proofOfBusinessAddress}
        handleRemoveFile={() => setValue('document', null)}
        isFormDisabled={disableForm || requestIsLoading}
      />
      <DocumentSigningForm
        onFormSigned={onFormSigned}
        addressDetails={{
          streetAddress,
          city,
          state,
          zip,
          leaseStartDate,
          leaseTermLength,
          monthlyRent,
        }}
        enableAgreementSigning={enableAgreementSigning}
        disable={requestIsLoading || disableForm}
        states={states || []}
        file={editMode ? undefined : (amendmentPdf as string)}
      />
    </>
  );

  return (
    <Grid container spacing={4}>
      {!editMode && (
        <Grid item xs={12}>
          {!disableForm ? (
            <EHRCheckbox
              dataCy="commercial-shipping-address-checkbox"
              label="I don't have a Commercial Shipping Address yet"
              onChange={handleCheckboxChange}
              className={classes.checkbox}
              checked={isChecked}
              disabled={requestIsLoading}
            />
          ) : null}
        </Grid>
      )}
      {!isChecked ? (
        <Grid item xs={12}>
          {renderForm}
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Banner
            content={`A commercial shipping address is required to set up accounts with Galderma, McKesson, 
              and Allergan. 🏢 However, you can use a residential address to set up accounts with Medline, 
              Merz, and Evolus. 🏠 If you need assistance sourcing a commercial location, please let your 
              onboarding manager know—we're here to help! 💬`}
            type="warning"
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <FormHelperText error={requestHasErrors}>
          {requestHasErrors && 'There was an error submitting data. Please try again.'}
        </FormHelperText>
      </Grid>
    </Grid>
  );
};

export default ShippingAddress;
