import React, { useState, useMemo } from 'react';
import { Box, Table, TableBody, TableContainer } from '@material-ui/core';
import Page from 'src/components/common/Page';
import { debounce } from 'lodash';
import { dispatch } from '../rematch';
import TableHeader from '../components/common/Table/TableHeader';
import { IPhysician } from '../interfaces/IPhysician';
import { usePhysicians } from '../hooks/queries/usePhysicians';
import { MultipleSkeleton } from '../components/common/LoadingSkeleton';
import compile from '../utils/toastMessagesCompiler';
import PhysicianHeader from '../components/DashboardAdministrator/Physicians/PhysicianHeader';
import PhysicianRow from '../components/DashboardAdministrator/Physicians/PhysicianRow';
import { useMedspaPractitioners } from '../hooks/queries/usePractitioners';

const TABLE_COLUMNS = [
  { id: 'id', title: 'ID' },
  { id: 'fullname', title: 'Fullname' },
  { id: 'professionalName', title: 'Professional Name' },
  { id: 'email', title: 'Email' },
  { id: 'workingStates', title: 'States' },
  { id: 'assignedProviderIds', title: 'Assigned Providers' },
  { id: 'medicalMemberType', title: 'Medical Member' },
  { id: 'inactive', title: 'Status' },
];

const PhysiciansPage: React.FC = () => {
  const [filters, setFilters] = useState({
    search: '',
    workingState: '',
    showInactive: false,
  });

  const { data: physicians, isError, isLoading } = usePhysicians(filters);
  const { data: practitioners, isLoading: isLoadingPractitioners } = useMedspaPractitioners(
    {},
    true,
    () => 'v3/practitioners'
  );

  const debounceSearch = useMemo(
    () =>
      debounce((query: string) => {
        setFilters((prev) => ({
          ...prev,
          search: query,
        }));
      }, 1500),
    []
  );

  const onSearch = (event: any) => {
    debounceSearch(event.target.value);
  };

  const onStateChange = (state: string) => {
    setFilters((prev) => ({
      ...prev,
      workingState: state === 'all' ? '' : state,
    }));
  };

  const onInactiveChange = () => {
    setFilters((prev) => ({
      ...prev,
      showInactive: !prev.showInactive,
    }));
  };

  if (isError) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'fetching',
          element: 'physicians',
        }),
        type: 'error',
      },
    });
  }

  const TableComponent = (
    <Box padding={2}>
      <TableContainer>
        <Table>
          <TableHeader columns={TABLE_COLUMNS} />
          <TableBody>
            {isLoading || isLoadingPractitioners ? (
              <MultipleSkeleton width="80%" />
            ) : (
              physicians &&
              practitioners &&
              physicians.map(
                ({
                  id,
                  identity,
                  professionalName,
                  workingStates,
                  medicalMemberType,
                  assignedProviderIds,
                  inactive,
                }: IPhysician) => (
                  <PhysicianRow
                    key={id}
                    id={id}
                    fullname={`${identity?.firstName} ${identity?.lastName}`}
                    professionalName={professionalName || ''}
                    email={identity?.email}
                    assignedProviders={assignedProviderIds.map((practitionerId) => {
                      const provider = practitioners.find((practitioner) => practitioner.id === +practitionerId);
                      return provider ? `${provider.firstName} ${provider.lastName}` : '';
                    })}
                    workingStates={workingStates}
                    medicalMemberType={medicalMemberType || ''}
                    inactive={inactive}
                  />
                )
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  return (
    <Page title="Physicians">
      <Box width="100%">
        <PhysicianHeader
          inactiveChecked={filters.showInactive}
          onChangeInactiveCheckbox={onInactiveChange}
          onSearch={onSearch}
          selectedState={filters.workingState}
          setSelectedState={onStateChange}
          isLoading={isLoading}
        />
        <Box>{TableComponent}</Box>
      </Box>
    </Page>
  );
};

export default PhysiciansPage;
