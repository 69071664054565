import React, { useState } from 'react';
import { Box, CircularProgress, Paper, Table, TableCell, TableRow, makeStyles, withStyles } from '@material-ui/core';
import { useMedspaLocations } from 'src/hooks/queries/medspaAdmins/useMedspaLocations';
import { Pill } from 'src/components/common/Pill';
import Page from 'src/components/common/Page';
import { EHRButton } from 'src/components/ui/v1';
import EmptyPage from 'src/components/common/EmptyPage';
import { MEDSPA_ADMIN_SERVICES_MANAGEMENT } from 'src/routes/medspaAdminRoutes';
import { useSelector } from 'react-redux';
import { copyToClipboard } from 'src/utils/clipboard.utils';
import { useUpdateMedspaAdminOnboardingProgress } from 'src/hooks/queries/useMedspaAdmins';
import { ONBOARDING_STEPS, ONBOARDING_VIDEOS, SHOW_SUCCESS_MESSAGE } from 'src/pages/MedspaAdmin/Onboarding/constants';
import useMedspaAdminOnboarding from 'src/hooks/useMedspaAdminOnboarding';
import { VideoBanner } from 'src/pages/MedspaAdmin/Onboarding/components/VideoBanner';
import { VideoModal } from 'src/pages/MedspaAdmin/Onboarding/components/VideoModal';
import { MedspaAdminLocationTableDetails } from './MedspaLocationTableDetail';
import MedspaLocationModal from './MedspaLocationModal';
import useCurrentUserGroup from '../../../hooks/queries/useUserGroups';
import { SIGN_UP_ROUTES } from '../../../constants/routes.constants';
import { MEDSPA_ADMIN_ROLE, PORTRAIT_LEGACY, PORTRAIT_LEGACY_PLUS } from '../../../constants/general.constants';

const CustomTableCell = withStyles(() => ({
  root: {
    border: 'none',
  },
}))(TableCell);

export const useStyles = makeStyles(() => ({
  root: { '& p': { margin: 0 } },
  tableTitle: { color: 'gray' },
  titleContainer: { textAlign: 'right' },
  tableCellTitle: { fontFamily: 'Messina Sans Regular' },
  addButton: { background: '#1d584d', color: 'white', float: 'right', padding: '10px' },
  title: { fontSize: '28px', fontFamily: 'Roboto', fontWeight: 600, color: '#000000' },
}));

const MedspaAdminLocations = () => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [editMedspaLocationForm, setEditMedspaLocationForm] = useState<any>();
  const { roleName } = useSelector(({ auth }: any) => auth);
  const { data: userGroupData, isLoading: isLoadingUserGroup } = useCurrentUserGroup(roleName === MEDSPA_ADMIN_ROLE);
  const isLegacy = userGroupData?.roleName === PORTRAIT_LEGACY || userGroupData?.roleName === PORTRAIT_LEGACY_PLUS;

  const [openEhrOnboardingSuccessModal, setOpenEhrOnboardingSuccessModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);

  const { mutateAsync: updateProgress } = useUpdateMedspaAdminOnboardingProgress();

  const { FooterComponent, DoneFooterComponent, onboardingEnabled, allStepsCompleted, showFooter } =
    useMedspaAdminOnboarding(ONBOARDING_STEPS.LOCATION_MANAGEMENT_NAME);

  const {
    data: { medspaLocations },
    isLoading,
  } = useMedspaLocations();

  const onClose = () => {
    setEditMedspaLocationForm(null);
    setOpenModal(false);
  };

  const onEditMedspaLocation = (data: any) => {
    setEditMedspaLocationForm({
      ...data,
      practitioners: data.practitioners.map(({ id }: { id: number }) => id),
    });
    setOpenModal(true);
  };

  const AddLocationButton = () => (
    <EHRButton
      dataCy="btn-add-add-medspa-location"
      onClick={() => setOpenModal(true)}
      color="primary"
      text="New Location"
    />
  );

  if (isLoading || isLoadingUserGroup) {
    return (
      <Box textAlign="center" width="100%" padding="100px 0">
        <CircularProgress />
      </Box>
    );
  }

  if (medspaLocations.length === 0) {
    return (
      <>
        {onboardingEnabled && (
          <div style={{ padding: '2rem' }}>
            <VideoBanner title="How to add a location to your MedSpa?" onClick={() => setShowVideoModal(true)} />
          </div>
        )}
        <EmptyPage title="Medspa Locations">
          <h1>Welcome to Your Locations Management Section!</h1>
          <p>It looks like you haven&apos;t added your first location yet.</p>
          <p>Click below to add your first location.</p>
          <Box marginTop="24px">
            <AddLocationButton />
          </Box>
          <MedspaLocationModal
            openModal={openModal}
            onClose={onClose}
            medspaLocation={editMedspaLocationForm}
            medspaLegacyPracIds={isLegacy && userGroupData ? userGroupData.practitionerIds : null}
            onSuccessCallback={async () => {
              if (onboardingEnabled && !allStepsCompleted) {
                const resp = await updateProgress({
                  stepName: ONBOARDING_STEPS.LOCATION_MANAGEMENT_NAME,
                  percentage: 100,
                });

                if (resp.completed && resp?.message === SHOW_SUCCESS_MESSAGE) {
                  setOpenEhrOnboardingSuccessModal(true);
                }
              }
            }}
          />
        </EmptyPage>
        <FooterComponent
          currentStep="Locations"
          nextStep="Add Services"
          urlToNextStep={MEDSPA_ADMIN_SERVICES_MANAGEMENT}
        />
        <VideoModal
          open={showVideoModal}
          onClose={() => setShowVideoModal(false)}
          url={ONBOARDING_VIDEOS.LOCATION_MANAGEMENT_NAME}
        />
      </>
    );
  }

  return (
    <>
      <Page title="Medspa Locations">
        <Box width="100%">
          <Box className={classes.titleContainer}>{!isLegacy && <AddLocationButton />}</Box>
          <Box height="1.25rem" />
          {onboardingEnabled && (
            <VideoBanner title="How to add a location to your MedSpa?" onClick={() => setShowVideoModal(true)} />
          )}
          <Box paddingTop="30px">
            {medspaLocations.map((medspaLocation: any, index: number) => (
              <Box
                padding="10px"
                paddingBottom="20px"
                marginBottom={index === medspaLocations.length - 1 && showFooter ? '100px' : '0'}
                key={medspaLocation.id}
              >
                <Paper elevation={1} style={{ background: 'white' }}>
                  <Table>
                    <TableRow className={classes.tableTitle}>
                      <CustomTableCell className={classes.tableCellTitle}>Location Name</CustomTableCell>
                      <CustomTableCell className={classes.tableCellTitle}>City/State</CustomTableCell>
                      <CustomTableCell className={classes.tableCellTitle}>Address</CustomTableCell>
                      <CustomTableCell className={classes.tableCellTitle}>Providers</CustomTableCell>
                      <CustomTableCell className={classes.tableCellTitle}>Status</CustomTableCell>
                      <CustomTableCell rowSpan={2}>
                        <EHRButton
                          dataCy="btn-booking-link"
                          color="secondary"
                          text={`${medspaLocation.name} Booking Link`}
                          onClick={() =>
                            copyToClipboard(`${SIGN_UP_ROUTES.medspaLocationBooking}/${medspaLocation.slug}`)
                          }
                        />
                      </CustomTableCell>
                    </TableRow>
                    <TableRow>
                      <CustomTableCell>{medspaLocation.name}</CustomTableCell>
                      <CustomTableCell>
                        {medspaLocation.city}, {medspaLocation.state}
                      </CustomTableCell>
                      <CustomTableCell>
                        {medspaLocation.address},&nbsp;{medspaLocation.city},&nbsp;{medspaLocation.state}
                      </CustomTableCell>
                      <CustomTableCell>{medspaLocation.practitioners.length}</CustomTableCell>
                      <CustomTableCell>
                        <Pill
                          title={medspaLocation.active ? 'Active' : 'Inactive'}
                          color={medspaLocation.active ? 'green' : 'gray'}
                          style={{ cursor: 'pointer' }}
                        />
                      </CustomTableCell>
                    </TableRow>
                  </Table>

                  <hr style={{ margin: '20px', border: '1px solid gainsboro' }} />
                  <MedspaAdminLocationTableDetails row={medspaLocation} onEditMedspaLocation={onEditMedspaLocation} />
                </Paper>
              </Box>
            ))}
          </Box>
        </Box>
        <MedspaLocationModal
          openModal={openModal}
          onClose={onClose}
          medspaLocation={editMedspaLocationForm}
          medspaLegacyPracIds={isLegacy && userGroupData ? userGroupData.practitionerIds : null}
        />
      </Page>
      <FooterComponent
        currentStep="Locations"
        nextStep="Add Services"
        urlToNextStep={MEDSPA_ADMIN_SERVICES_MANAGEMENT}
      />

      {openEhrOnboardingSuccessModal && <DoneFooterComponent />}

      <VideoModal
        open={showVideoModal}
        onClose={() => setShowVideoModal(false)}
        url={ONBOARDING_VIDEOS.LOCATION_MANAGEMENT_NAME}
      />
    </>
  );
};

export default MedspaAdminLocations;
