import React, { useCallback, useEffect, useState } from 'react';
import {
  ViewState,
  GroupingState,
  IntegratedGrouping,
  AppointmentModel,
  Resource,
} from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  WeekView as WeekViewProvider,
  DayView,
  Toolbar,
  DateNavigator,
  AllDayPanel,
  Resources,
  CurrentTimeIndicator,
} from '@devexpress/dx-react-scheduler-material-ui';
import { Paper, decomposeColor, recomposeColor, makeStyles } from '@material-ui/core';
import { isEqual } from 'lodash';
import { IPractitioner } from 'src/interfaces/IPractitioner';
import AppointmentsTimeIndicator from 'src/components/common/Appointments/AppointmentsTimeIndicator';
import { IMedspaLocation } from 'src/interfaces/ILocation';
import { IAppointmentType } from 'src/interfaces/acuity/acuity.interface';
import { IWorkingHour } from 'src/services/medspaAdmins/MedspaCalendar';
import { CalendarToday } from '@material-ui/icons';
import { CustomWeekView as WeekView } from './CustomWeekView';
import { DayViewDayScaleCell } from './DayViewDayScaleCell';
import { DayViewTimeTableCell } from './DayViewTimeTableCell';
import { AllDayCell } from './AllDayCell'; // For MedspaCalendar V2
import { capDatetimeSeconds } from './medspaCalendarUtils';
import MedspaCalendarAppointmentForm from './MedspaCalendarAppointmentForm';
import MedspaCalendarGroupingPanel from './MedspaCalendarGroupingPanel';
import MedspaCalendarAppointments from './MedspaCalendarAppointments';
import MedspaCalendarAppointmentTooltip from './MedspaCalendarAppointmentTooltip';
import { VIEW_NAME_DAY, VIEW_NAME_WEEK, VIEW_NAME_WEEK_PROVIDER } from '../indexV2';
import { WeekViewTimeTableCell } from './WeekViewTimeTableCell';
import { CustomViewSwitcher } from './CustomViewSwitcher';

function clamp(value: number, min = 0, max = 1) {
  if (process.env.NODE_ENV !== 'production') {
    if (value < min || value > max) {
      // eslint-disable-next-line no-console
      console.error(`Material-UI: The value provided ${value} is out of range [${min}, ${max}].`);
    }
  }

  return Math.min(Math.max(min, value), max);
}

export function alpha(color: string, value: number) {
  const newColor = decomposeColor(color);
  const newValue = clamp(value);

  if (newColor.type === 'rgb' || newColor.type === 'hsl') {
    newColor.type += 'a';
  }
  newColor.values[3] = newValue;

  return recomposeColor(newColor);
}

const grouping = [
  {
    resourceName: 'practitionerId',
  },
];

const filterTasks = (items: AppointmentModel[], practitionerId?: number | string) =>
  practitionerId === '' ? items : items.filter((task) => !practitionerId || task.practitionerId === practitionerId);

const useStyles = makeStyles(() => ({
  schedulerContainer: {
    height: '90vh',
    backgroundColor: '#fff',
    overflowX: 'scroll',
    marginBottom: '10rem',
  },
  dateNavigatorButton: {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    borderRadius: '8px',
    padding: '6px 8px',
    '&:hover': {
      background: 'rgba(58, 59, 63, 0.04)',
    },
  },
  dateNavigatorIcon: {
    marginLeft: '0.5rem',
    width: '20px',
    position: 'relative',
    top: '-2px',
  },
}));

interface MedspaCalendarProps {
  isFetching: boolean;
  appointmentsData: AppointmentModel[];
  selectedPractitionerId?: number | string;
  onChangePractitionerSelected: (id: number | string) => void;
  providerResources: Resource[];
  providersMap: { [key: number]: IPractitioner };
  workingHoursMap: { [key: number]: IWorkingHour[] };
  selectedMedspaLocation?: IMedspaLocation;
  date: Date;
  onDateChange: (newDate: Date) => void;
  appointmentTypes: IAppointmentType[];
  currentViewName: string;
  setCurrentViewName: (view: string) => void;
}

const MedspaCalendar = React.memo(
  ({
    isFetching,
    appointmentsData,
    selectedPractitionerId,
    providerResources,
    providersMap,
    selectedMedspaLocation,
    date,
    onDateChange,
    appointmentTypes,
    workingHoursMap,
    currentViewName,
    setCurrentViewName,
    onChangePractitionerSelected,
  }: MedspaCalendarProps) => {
    const calendarClasses = useStyles();
    const data = appointmentsData;
    const currentViewNameChange = (newCurrentViewName: string) => {
      setCurrentViewName(newCurrentViewName);
    };
    const currentDateChange = (newCurrentDate: Date) => {
      onDateChange(newCurrentDate);
    };
    const [startHour, setStartHour] = useState(0);

    const updateStartHour = useCallback((hour) => {
      setStartHour(hour);
    }, []);

    useEffect(() => {
      const timeout = setTimeout(() => {
        const timerIndicator = document.getElementById('timer-indicator');
        const defaultTime = Array.from(document.querySelectorAll('span')).find(
          (element) => element.textContent === '9:00 AM'
        );

        if (timerIndicator) {
          timerIndicator.style.width = '10px';
          timerIndicator.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          });
        } else if (defaultTime) {
          defaultTime.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          });
        }
      }, 200);

      return () => clearTimeout(timeout);
    }, [data, date]);

    const startEndDateData: { startDayHour: number; endDayHour: number } = {
      startDayHour: 1,
      endDayHour: 23,
    };

    if (Object.keys(workingHoursMap).length === 0) {
      return <div>Loading working hours...</div>;
    }
    return (
      <Paper className={calendarClasses.schedulerContainer}>
        <Scheduler data={isFetching ? [] : filterTasks(data, selectedPractitionerId)}>
          <ViewState currentDate={date} currentViewName={currentViewName} />
          <GroupingState
            grouping={grouping}
            groupOrientation={(viewName) => {
              if (viewName === VIEW_NAME_WEEK) {
                return 'Vertical';
              }
              return 'Horizontal';
            }}
          />
          <DayView
            startDayHour={startEndDateData.startDayHour}
            endDayHour={startEndDateData.endDayHour}
            name={VIEW_NAME_DAY}
            timeTableCellComponent={(props) => {
              const providerId = props.groupingInfo?.[0].id;
              if (!providerId || isFetching) {
                return <></>;
              }
              const workingHours = workingHoursMap[providerId as number];
              updateStartHour(workingHours[0]?.startDate.getUTCHours());

              return (
                <DayViewTimeTableCell
                  {...props}
                  workingHours={workingHours}
                  startDate={capDatetimeSeconds(props.startDate)}
                  endDate={capDatetimeSeconds(props.endDate)}
                />
              );
            }}
            dayScaleCellComponent={DayViewDayScaleCell}
            intervalCount={1}
          />
          {/* For MedspaCalendar V2 */}
          <WeekView name={VIEW_NAME_WEEK} data={isFetching ? [] : data} />
          <WeekViewProvider
            name={VIEW_NAME_WEEK_PROVIDER}
            startDayHour={Math.max(0, startEndDateData.startDayHour - 1)}
            endDayHour={Math.max(0, startEndDateData.endDayHour + 1)}
            timeTableCellComponent={(props) => {
              if (isFetching) {
                return <></>;
              }
              const providerId = props.groupingInfo?.[0].id;
              const workingHours = workingHoursMap[providerId as number];
              return <WeekViewTimeTableCell {...props} view={currentViewName} workingHours={workingHours || []} />;
            }}
          />
          {/* For MedspaCalendar V2 */}
          <AllDayPanel
            cellComponent={(props) =>
              isFetching ? (
                <></>
              ) : (
                <AllDayCell
                  {...props}
                  view={currentViewName}
                  workingHoursMap={workingHoursMap}
                  onChangePractitionerSelected={onChangePractitionerSelected}
                  onCurrentViewNameChange={currentViewNameChange}
                  onCurrentDateChange={currentDateChange}
                />
              )
            }
          />
          <MedspaCalendarAppointments view={currentViewName} />
          {currentViewName === VIEW_NAME_DAY && <CurrentTimeIndicator indicatorComponent={AppointmentsTimeIndicator} />}
          <Resources data={providerResources} />
          <IntegratedGrouping />
          {selectedMedspaLocation && (
            <MedspaCalendarGroupingPanel
              providersMap={providersMap}
              selectedMedspaLocation={selectedMedspaLocation}
              date={date}
              workingHoursMap={currentViewName === VIEW_NAME_DAY ? workingHoursMap : []}
              showEdit={currentViewName === VIEW_NAME_DAY}
            />
          )}
          <Toolbar
            rootComponent={() => (
              <CustomViewSwitcher
                startHour={startHour}
                currentDate={date}
                view={currentViewName}
                onCurrentDateChange={currentDateChange}
                onCurrentViewNameChange={currentViewNameChange}
              />
            )}
          />
          <DateNavigator
            openButtonComponent={(props) => {
              const { text, onVisibilityToggle } = props;
              return (
                <button type="button" className={calendarClasses.dateNavigatorButton} onClick={onVisibilityToggle}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {text}
                    <CalendarToday className={calendarClasses.dateNavigatorIcon} />
                  </div>
                </button>
              );
            }}
          />
          <MedspaCalendarAppointmentTooltip
            providersMap={providersMap}
            selectedMedspaLocation={selectedMedspaLocation}
          />
          <MedspaCalendarAppointmentForm
            view={currentViewName}
            providersMap={providersMap}
            workingHoursMap={workingHoursMap}
            selectedMedspaLocation={selectedMedspaLocation}
            appointmentTypes={appointmentTypes}
          />
        </Scheduler>
      </Paper>
    );
  },
  (oldProps, newProps) => isEqual(oldProps, newProps)
);

export default MedspaCalendar;
