import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { STRIPE_PAYMENT_PROCESSOR } from 'src/constants/general.constants';
import { useStyles } from './StartMembership.styles';
import { usePractitionerInfo } from '../../../hooks/queries/usePractitioners';
import { useCreateBeautiBankSubscription } from '../../../hooks/mutations/useCreateBeautiBankSubscription';
import { dispatch } from '../../../rematch';
import { BEAUTY_BANK_PRICE } from '../../../constants/beautyBank.constants';
import { SelectOrAddCardForMembership } from './SelectOrAddCardForMembership';
import { ShortMultipleSkeleton } from '../../../components/common/LoadingSkeleton';
import CreditCardForm from '../../PatientCreditCards/components/CreditCardForm';

const StartMembership = ({ patient, refetch, patientId }: { patient: any; refetch: () => void; patientId: string }) => {
  const classes = useStyles();
  const [selectedCardId, setSelectedCardId] = useState<string>();
  const [processing, setProcessing] = useState<boolean>(false);
  const patientCards = [...(patient?.basicInfo?.creditCards || [])].reverse();
  const { practitionerInfo, isLoading: practitionerLoading } = usePractitionerInfo(
    patient?.contactInfo?.practitioner?.id
  );
  const createSubscriptionMutation = useCreateBeautiBankSubscription();
  const [intervalId, setIntervalId] = useState<any>();

  useEffect(() => {
    if (patient) {
      setSelectedCardId(patient.basicInfo.squareDefaultCreditCardId);
    }
  }, [patient]);

  useEffect(() => {
    if (patient?.basicData?.subscription && intervalId) {
      clearInterval(intervalId);
      setProcessing(false);
    }
  }, [patient?.basicData?.subscription, intervalId]);

  const startSubscription = async (cardId?: string) => {
    try {
      setProcessing(true);
      const success = await createSubscriptionMutation.mutateAsync({
        patientId,
        squareCardId: cardId || selectedCardId,
      });

      if (success) {
        const interval = setInterval(() => {
          refetch();
        }, 5000);

        setIntervalId(interval);
      } else {
        setProcessing(false);
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: 'Something went wrong, failed to create subscription.',
            type: 'error',
          },
        });
      }
    } catch (error) {
      setProcessing(false);
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: 'Something went wrong, failed to create subscription.',
          type: 'error',
        },
      });
    }
  };

  const handleAddCardSuccess = async (cardId: string, callBack: () => void) => {
    await addCardAndStartSubscription({ cardId });
    callBack();
  };

  const addCardAndStartSubscription = ({ cardId }: { cardId: string }) => startSubscription(cardId);

  if (practitionerLoading) {
    return <ShortMultipleSkeleton length={3} />;
  }

  const renderSelectOrAddCardMembership = () => {
    if (patient.paymentProcessor === STRIPE_PAYMENT_PROCESSOR) {
      return <div>Stripe placeholder component</div>;
    }
    return (
      <SelectOrAddCardForMembership
        selectedCardId={selectedCardId}
        setSelectedCardId={setSelectedCardId}
        onSave={() => startSubscription()}
        loading={processing}
        patientCards={patientCards}
        squareLocationId={practitionerInfo.squareLocationId}
        patientId={patientId}
        onSuccessCardAdd={addCardAndStartSubscription}
        buttonText={`START MEMBERSHIP: $${BEAUTY_BANK_PRICE}`}
      />
    );
  };

  const renderCreditCardForm = () => {
    if (patient.paymentProcessor === STRIPE_PAYMENT_PROCESSOR) {
      return <div>Stripe placeholder component</div>;
    }
    return (
      <CreditCardForm
        locationId={practitionerInfo.squareLocationId}
        patientId={patientId}
        onCardAdded={handleAddCardSuccess}
        buttonText={`START MEMBERSHIP: $${BEAUTY_BANK_PRICE}`}
        loading={processing}
      />
    );
  };

  return (
    <div>
      <Typography variant="h5">Manage Membership</Typography>
      {patientCards.length > 0 ? (
        <>
          <Typography className={classes.subtitle}>Select card to start membership</Typography>
          {renderSelectOrAddCardMembership()}
        </>
      ) : (
        <div className={classes.mt2}>{renderCreditCardForm()}</div>
      )}
    </div>
  );
};

export default StartMembership;
