import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Button } from '@material-ui/core';
import { useStyles } from './futureCredits.styles';
import { FutureCreditModal, ModalData } from './FutureCreditModal';
import { dispatch } from '../../../rematch';
import { IFutureCreditUsed, IFutureVariantCreditUsed } from '../../../interfaces/checkout.interfaces';
import { formatNumber } from '../../../utils/checkout.utils';
import { IServicesUnits } from '../../../interfaces/serviceVisit.interfaces';
import { ServiceCredits as IServiceCredits } from '../../../types/ServiceCredits';
import { ICommonState } from '../../../rematch/common.model';
import { SQUARE_STATUS } from '../../../constants/square.constants';
import IVariant, { IVariantItem } from '../../../interfaces/IVariants';

const INITIAL_MODEL_DATA = { serviceName: '', units: 0, serviceId: 0, variantItemId: 0, maxUnits: 1, balance: 0 };

const FutureCredits = () => {
  const { patientId }: any = useParams();
  const classes = useStyles();
  const { credits } = useSelector((store: any) => store.serviceCredits as IServiceCredits);
  const { services } = useSelector((store: any) => store.common as ICommonState);
  const {
    servicesUnits,
    variantsUnits,
    futureCreditUsed,
    futureVariantCreditUsed,
    checkout,
  }: {
    servicesUnits: IServicesUnits[];
    variantsUnits: IServicesUnits[];
    futureCreditUsed: IFutureCreditUsed[];
    futureVariantCreditUsed: IFutureVariantCreditUsed[];
    checkout: any;
  } = useSelector((store: any) => store.newServiceVisit);
  const { transactionStatus } = checkout;
  const [open, setOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<ModalData>({ ...INITIAL_MODEL_DATA });

  useEffect(() => {
    dispatch({ type: 'serviceCredits/fetchCredits', payload: { customerId: patientId } });
  }, []);

  const openModal = (serviceName: string, serviceId: number, balance: number): void => {
    const units = futureCreditUsed.find((credit) => credit.serviceId === serviceId)?.used || 1;
    const maxUnits = +servicesUnits[serviceId] < balance ? +servicesUnits[serviceId] : balance;
    setModalData({ serviceName, units, serviceId, maxUnits, balance });
    setOpen(true);
  };
  const openVariantModal = (serviceName: string, variantItemId: number, balance: number): void => {
    const units = futureVariantCreditUsed.find((credit) => credit.variantItemId === variantItemId)?.used || 1;
    const maxUnits = +variantsUnits[variantItemId] < balance ? +variantsUnits[variantItemId] : balance;
    setModalData({ serviceName, units, variantItemId, maxUnits, balance });
    setOpen(true);
  };

  const applyCredit = (units: number): void => {
    dispatch({
      type: 'newServiceVisit/applyCredit',
      payload: { serviceId: modalData.serviceId, used: units },
    });
  };
  const applyVariantCredit = (units: number): void => {
    dispatch({
      type: 'newServiceVisit/applyVariantCredit',
      payload: { variantItemId: modalData.variantItemId, used: units },
    });
  };

  const removeCredit = (): void => {
    dispatch({
      type: 'newServiceVisit/removeCredit',
      payload: modalData.serviceId,
    });
  };

  const removeVariantCredit = (): void => {
    dispatch({
      type: 'newServiceVisit/removeVariantCredit',
      payload: modalData.variantItemId,
    });
  };

  const getBalanceLabel = (balance: number, serviceId: number): number =>
    balance - (futureCreditUsed.find((item) => item.serviceId === serviceId)?.used || 0);

  const getVariantBalanceLabel = (balance: number, variantItemId: number): number =>
    balance - (futureVariantCreditUsed.find((item) => item.variantItemId === variantItemId)?.used || 0);

  const getAppliedCreditsLabel = (serviceId: number): string => {
    const servicePrice = services.find(({ id }) => id === serviceId)?.price || 0;
    const used = futureCreditUsed.find((item) => item.serviceId === serviceId)?.used || 0;
    const total = formatNumber(used * servicePrice);
    return used > 0 ? `(Applied ${used}) - $${total}` : '';
  };

  const getAppliedVariantCreditsLabel = (variantItemId: number): string => {
    const variantItem = services
      .map(({ variants }: any) =>
        variants.find(({ items }: IVariant) => items.find((item: any) => item.id === variantItemId))
      )
      ?.find((variant: IVariant) => variant)
      .items.find((item: IVariantItem) => item.id === variantItemId);

    const used = futureVariantCreditUsed.find((item) => item.variantItemId === variantItemId)?.used || 0;
    const total = formatNumber((used / variantItem.minAmount) * variantItem.price);
    return used > 0 ? `(Applied ${used}) - $${total}` : '';
  };

  const getButtonLabel = (serviceId: number): string =>
    futureCreditUsed.some((item) => item.serviceId === serviceId) ? 'Edit credits' : 'Apply credits';

  const getVariantButtonLabel = (variantItemId: number): string =>
    futureVariantCreditUsed.some((item) => item.variantItemId === variantItemId) ? 'Edit credits' : 'Apply credits';

  const getCreditData = () => {
    if (transactionStatus === SQUARE_STATUS.OK) {
      return futureCreditUsed.map(({ serviceId, used }) => ({
        serviceName: services.find(({ id }) => id === serviceId)?.name || '',
        serviceId,
        balance: used,
      }));
    }
    return credits.filter(({ serviceId }) => Object.keys(servicesUnits).includes(`${serviceId}`));
  };

  const getVariantsCreditData = () => {
    if (transactionStatus === SQUARE_STATUS.OK) {
      return futureVariantCreditUsed.map(({ variantItemId, used }) => {
        const { name } = services
          .map(({ variants }: any) =>
            variants.find(({ items }: IVariant) => items.find((item: any) => item.id === variantItemId))
          )
          .find((variant: IVariant) => variant)
          .items.find((item: IVariantItem) => item.id === variantItemId);
        return {
          serviceName: name,
          variantItemId,
          balance: used,
        };
      });
    }
    return credits.filter(
      ({ variantItemId }) =>
        Object.keys(variantsUnits).includes(`${variantItemId}`) && +variantsUnits[variantItemId] !== 0
    );
  };
  const creditData = getCreditData();
  const variantCreditData = getVariantsCreditData();

  if (!creditData.length && !variantCreditData.length) {
    return null;
  }

  return (
    <>
      <div className={classes.mainSection}>
        <div className={classes.sectionTitle}>Future Use Credits</div>
        <div className={classes.contentSection}>
          {creditData.map(({ serviceName, serviceId, balance }) => (
            <Box key={serviceId} display="flex" alignItems="center" mb={2} flexDirection="row">
              <Box width="23%" className={classes.service}>
                {serviceName}
              </Box>
              <Box width="17%" className={classes.balance}>
                {getBalanceLabel(balance, serviceId)}
              </Box>
              <Box width="25%">
                <Button
                  disableElevation
                  className={classes.buttonApply}
                  onClick={() => {
                    openModal(serviceName, serviceId, balance);
                  }}
                >
                  {getButtonLabel(serviceId)}
                </Button>
              </Box>
              <Box width="35%" textAlign="right">
                {getAppliedCreditsLabel(serviceId)}
              </Box>
            </Box>
          ))}
          {variantCreditData.map(({ serviceName, variantItemId, balance }) => (
            <Box key={variantItemId} display="flex" alignItems="center" mb={2} flexDirection="row">
              <Box width="23%" className={classes.service}>
                {serviceName}
              </Box>
              <Box width="17%" className={classes.balance}>
                {getVariantBalanceLabel(balance, variantItemId)}
              </Box>
              <Box width="25%">
                <Button
                  disableElevation
                  className={classes.buttonApply}
                  onClick={() => {
                    openVariantModal(serviceName, variantItemId, balance);
                  }}
                >
                  {getVariantButtonLabel(variantItemId)}
                </Button>
              </Box>
              <Box width="35%" textAlign="right">
                {getAppliedVariantCreditsLabel(variantItemId)}
              </Box>
            </Box>
          ))}
        </div>
      </div>
      <FutureCreditModal
        open={open}
        data={modalData}
        showRemoveButton={
          modalData.serviceId
            ? futureCreditUsed.some(({ serviceId }) => serviceId === modalData.serviceId)
            : futureVariantCreditUsed.some(({ variantItemId }) => variantItemId === modalData.variantItemId)
        }
        handleClose={() => {
          setOpen(false);
        }}
        handleConfirm={modalData.serviceId ? applyCredit : applyVariantCredit}
        removeCredit={modalData.serviceId ? removeCredit : removeVariantCredit}
      />
    </>
  );
};

export default FutureCredits;
