export const CURRENCY_CODE = 'USD';
export const SQUARE_VERSION = '1.3';
export const SQUARE_CLIENT_ID = 'sq0idp-K3mN7cefrbIv8U-oEUcxLw';
export const SUPPORTED_TENDER_TYPES = ['CREDIT_CARD', 'CASH', 'OTHER', 'SQUARE_GIFT_CARD', 'CARD_ON_FILE'];
export const CALLBACK_URL = `${window.location.origin}/squaretransaction`;
export const MAX_AMOUNT_ERROR_TEXT = "Amount can't be greater than";
export const MIN_AMOUNT_ERROR_TEXT = 'Amount needs to be greater than';
export const CHECKOUT_ACCOUNT_CREDIT_ERROR = 'Cannot apply account credit';

export enum DISCOUNT_TYPES {
  PERCENTAGE = 'percentage',
  VALUE = 'value',
  AMOUNT = 'amount',
  BUNDLE = 'bundle',
  BUY_X_GET_Y = 'buy_x_get_y',
}

export const EMPTY_DISCOUNT = {
  type: DISCOUNT_TYPES.PERCENTAGE,
  amount: '',
  reason: '',
};

export const INDUSTRY_DISCOUNT_NAME = 'Industry (no commission) - 25%';

export const LIMIT_FOR_DISCOUNT = 750;
export const MAX_SERVICE_UNITS = 500;
export const ACCOUNT_CREDIT_CHARGE_TYPE = 'account_credit';
export const CANCELLATION_FEE_CHARGE_TYPE = 'cancellation_fee';
export const DEPOSIT_FEE_CHARGE_TYPE = 'cancellation_fee';
export const OTHER_FEE_CHARGE_TYPE = 'other_fee';
export const FEE_RELATED_CHARGE_TYPES = [
  ACCOUNT_CREDIT_CHARGE_TYPE,
  CANCELLATION_FEE_CHARGE_TYPE,
  DEPOSIT_FEE_CHARGE_TYPE,
  OTHER_FEE_CHARGE_TYPE,
];
export const ASPIRE_TENDER = 'aspire';
export const BRILLIANT_TENDER = 'brilliant';
export const ALLE_WITH_CHERRY_TENDER = 'alleCherry';
export const CARE_CREDIT_TENDER = 'careCredit';
export const XPERIENCE_TENDER = 'xperience';
export const PATIENTFI_TENDER = 'patientfi';

export const TENDER_TYPES = [
  BRILLIANT_TENDER,
  ALLE_WITH_CHERRY_TENDER,
  CARE_CREDIT_TENDER,
  XPERIENCE_TENDER,
  ASPIRE_TENDER,
  PATIENTFI_TENDER,
];
export const TENDER_TYPE_NAMES: Record<string, string> = {
  [BRILLIANT_TENDER]: 'All&#x0113;',
  [ALLE_WITH_CHERRY_TENDER]: 'All&#x0113; Payment with Cherry',
  [CARE_CREDIT_TENDER]: 'Care credit',
  [XPERIENCE_TENDER]: 'Xperience',
  [ASPIRE_TENDER]: 'Aspire',
  [PATIENTFI_TENDER]: 'PatientFi',
};
export const PRICE_FLOOR_EXCLUDED_SERVICE_NAMES = [
  'PDO Threads',
  'Glycolic Peel',
  'Branded Peel',
  'TCA Peel',
  'Hyaluronidase',
];
export const CUSTOM_TREATMENT_SERVICE_NAME = 'Custom Treatment';
