import React from 'react';
import { Box, Table, TableBody, TableContainer } from '@material-ui/core';
import Page from 'src/components/common/Page';
import { dispatch } from '../rematch';
import TableHeader from '../components/common/Table/TableHeader';
import { ILocation } from '../interfaces/ILocation';
import { useLocations } from '../hooks/queries/useLocations';
import { MultipleSkeleton } from '../components/common/LoadingSkeleton';
import compile from '../utils/toastMessagesCompiler';
import LocationRow from '../components/DashboardAdministrator/Locations/LocationRow';

const TABLE_COLUMNS = [
  { id: 'providerName', title: 'Provider Name' },
  { id: 'name', title: 'Name' },
  { id: 'cityState', title: 'City / State' },
  { id: 'address', title: 'Address' },
];

const LocationsPage: React.FC = () => {
  const { data: locations, isError, isLoading } = useLocations();

  if (isError) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'fetching',
          element: 'locations',
        }),
        type: 'error',
      },
    });
  }

  const TableComponent = (
    <Box padding={2}>
      <TableContainer>
        <Table data-cy="table">
          <TableHeader columns={TABLE_COLUMNS} />
          <TableBody>
            {isLoading ? (
              <MultipleSkeleton width="80%" />
            ) : (
              locations &&
              locations.map(({ id, providerName, customAddress, name, city, state }: ILocation) => (
                <LocationRow
                  key={id}
                  id={id}
                  providerName={providerName}
                  customAddress={customAddress}
                  name={name}
                  city={city}
                  state={state}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  return (
    <Page title="Locations">
      <Box width="100%">
        <Box>{TableComponent}</Box>
      </Box>
    </Page>
  );
};

export default LocationsPage;
