import Api from './Api';

interface IMerchantOnboardingParams {
  country: string;
}

const MerchantOnboarding = {
  // TODO majo: update response to be the correct type when we have the correct response from the backend
  async createMerchantOnboarding(params: IMerchantOnboardingParams): Promise<{ onboardingUrl: string }> {
    const response = await Api.post('/payments/merchants', {
      country: params.country,
    });

    return response;
  },
};

export default MerchantOnboarding;
