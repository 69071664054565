import { DayView } from '@devexpress/dx-react-scheduler-material-ui';
import { styled } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { IWorkingHour } from 'src/services/medspaAdmins/MedspaCalendar';
import { groupingStyles } from './groupingStyles';
import { isWithinAWorkingHour } from './medspaCalendarUtils';

// @ts-expect-error
const StyledDayViewTimeTableCell = styled(DayView.TimeTableCell)(groupingStyles);

const DayViewTimeTableCell = (props: DayView.TimeTableCellProps & { workingHours: IWorkingHour[] }) => {
  const { groupingInfo, workingHours, startDate, endDate, ...restProps } = props;
  const groupId = groupingInfo?.[0].id;
  if (!startDate || !endDate) {
    return <></>;
  }
  const isValidWorkingHour = isWithinAWorkingHour(workingHours, startDate, endDate);
  const handleClick = () => {
    if (onDoubleClick) {
      onDoubleClick(startDate);
    }
  };
  const { onDoubleClick, ...otherProps } = restProps;

  return (
    <StyledDayViewTimeTableCell
      {...otherProps}
      onClick={handleClick}
      className={classNames(`day-view-time-table-cell-group-${groupId}`, !isValidWorkingHour && 'disabled-date')}
      data-id={`time-table-cell-${startDate.toISOString()}`}
      groupingInfo={groupingInfo}
    />
  );
};

export { DayViewTimeTableCell };
