import React from 'react';
import { useSelector } from 'react-redux';
import { TableCell, TableRow } from '@material-ui/core';
import { EHRButton } from 'src/components/ui/v1';
import { useRowStyles } from './Physician.styles';
import { TableCellCustom } from '../../common/TableStyles/TableCellCustom';
import { hasAccessTo } from '../../../utils/auth.utils';
import { CREATE_PHYSICIAN } from '../../../constants/actions.constants';
import { ActiveChip } from '../../common/Global/CustomChip';

export interface PhysicianRowProps {
  id: number;
  email: string;
  fullname: string;
  professionalName?: string;
  workingStates?: string[];
  medicalMemberType: string;
  assignedProviders?: string[];
  inactive: boolean;
}

const PhysicianRow: React.FC<PhysicianRowProps> = (props) => {
  const {
    id: physicianId,
    email,
    fullname,
    professionalName,
    workingStates,
    medicalMemberType,
    assignedProviders,
    inactive,
  } = props;
  const classes = useRowStyles();
  const permissions = useSelector(({ auth }: any) => auth.permissions);
  const EDIT_URL = `/administrator/new-physician/${physicianId}`;

  return (
    <>
      <TableRow data-cy="row" className={classes.root}>
        <TableCellCustom>{physicianId}</TableCellCustom>
        <TableCellCustom data-cy="fullName">{fullname}</TableCellCustom>
        <TableCellCustom data-cy="professionalName">{professionalName}</TableCellCustom>
        <TableCellCustom data-cy="email" className={classes.email}>
          {email}
        </TableCellCustom>
        <TableCellCustom data-cy="workingStates">{workingStates?.join(', ') || ''}</TableCellCustom>
        <TableCellCustom data-cy="assignedProviders">{assignedProviders?.join(', ') || ''}</TableCellCustom>
        <TableCellCustom data-cy="memberType">{medicalMemberType}</TableCellCustom>
        <TableCellCustom data-cy="inactive">
          <ActiveChip active={!inactive} />
        </TableCellCustom>
        <TableCell>
          {hasAccessTo(CREATE_PHYSICIAN, permissions) && (
            <EHRButton dataCy="edit-physician" text="Edit" variant="outlined" href={EDIT_URL} color="text" />
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default PhysicianRow;
