import React, { useEffect, useMemo, useState } from 'react';
import { Box, CircularProgress, List, ListItem, ListItemIcon } from '@material-ui/core';
import Page from 'src/components/common/Page';
import { ONBOARDING_ACCOUNT_SETTINGS } from 'src/routes/medspaOnboardingRoutes';
import {
  MEDSPA_ADMIN_APPOINTMENT_TYPES_CONFIG,
  MEDSPA_ADMIN_CALENDAR_CONFIGURATION,
  MEDSPA_ADMIN_INVENTORY,
  MEDSPA_ADMIN_LOCATIONS,
  MEDSPA_ADMIN_PATIENTS,
  MEDSPA_ADMIN_PROVIDERS,
  MEDSPA_ADMIN_SERVICES_MANAGEMENT,
} from 'src/routes/medspaAdminRoutes';
import useMedspaAdminOnboarding from 'src/hooks/useMedspaAdminOnboarding';
import { useLocation } from 'react-router-dom';
import { StepsCompleted } from 'src/services/MedspaAdmins';
import { EHRButton } from 'src/components/ui/v1';
import { useMerchantOnboarding } from 'src/hooks/mutations/useMerchantOnboarding';
import useCurrentUserGroup from 'src/hooks/queries/useUserGroups';
import { STRIPE_PAYMENT_PROCESSOR } from 'src/constants/general.constants';
import { useMedspaAdminOnboardingProgress } from '../../../hooks/queries/useMedspaAdmins';
import { useStepsListStyles, useStyles } from './index.styles';
import { Step } from './components/Steps';
import { OnboardingSuccessModal } from './components/OnboardingSuccessModal';
import { ONBOARDING_STEPS, ONBOARDING_STEPS_ORDER, stepTitles } from './constants';
import { stepMapping } from './utils';

const ToDoOnboarding = () => {
  const classes = useStyles();
  const stepsStyles = useStepsListStyles();
  const { data: currentUserGroup } = useCurrentUserGroup();

  const location = useLocation();

  const [openEhrOnboardingSuccessModal, setOpenEhrOnboardingSuccessModal] = useState(false);

  const { data: progress, isLoading } = useMedspaAdminOnboardingProgress('all', true);

  const { onboardingCompleted, isLoadingMedspaAdminInfo, pendingCommercialShippingAddress, pendingMedicalDirector } =
    useMedspaAdminOnboarding();

  const { mutate: onboardMerchant } = useMerchantOnboarding();

  const STEPS = useMemo(() => {
    if (!progress?.stepsCompleted) {
      return [];
    }

    const createStep = (
      step: string,
      title: string,
      key: keyof StepsCompleted,
      redirect: string,
      isRequired: boolean = false,
      pending: boolean = false
    ) => ({
      step,
      title,
      completed: Boolean((progress.stepsCompleted[key] ?? 0) >= 100),
      completedPercentage: progress.stepsCompleted[key] ?? 0,
      disabled: key === stepMapping[ONBOARDING_STEPS.ACCOUNT_SETTINGS_NAME] ? false : !onboardingCompleted,
      redirect,
      isRequired,
      pending,
    });

    const stepRedirects: Record<string, string> = {
      [ONBOARDING_STEPS.ACCOUNT_SETTINGS_NAME]: ONBOARDING_ACCOUNT_SETTINGS,
      [ONBOARDING_STEPS.INVENTORY_ORDER_NAME]: MEDSPA_ADMIN_INVENTORY,
      [ONBOARDING_STEPS.PROVIDERS_MANAGEMENT_NAME]: MEDSPA_ADMIN_PROVIDERS,
      [ONBOARDING_STEPS.LOCATION_MANAGEMENT_NAME]: MEDSPA_ADMIN_LOCATIONS,
      [ONBOARDING_STEPS.SERVICE_MANAGEMENT_NAME]: MEDSPA_ADMIN_SERVICES_MANAGEMENT,
      [ONBOARDING_STEPS.PATIENTS_MANAGEMENT_NAME]: MEDSPA_ADMIN_PATIENTS,
      [ONBOARDING_STEPS.APPOINTMENT_TYPES_NAME]: MEDSPA_ADMIN_APPOINTMENT_TYPES_CONFIG,
      [ONBOARDING_STEPS.CALENDAR_SET_UP_NAME]: MEDSPA_ADMIN_CALENDAR_CONFIGURATION,
    };

    return ONBOARDING_STEPS_ORDER.map((stepName, index) => {
      const stepKey = stepMapping[stepName];
      const isAccountSettings = stepName === ONBOARDING_STEPS.ACCOUNT_SETTINGS_NAME;
      return createStep(
        `Step ${index + 1}`,
        stepTitles[stepName],
        stepKey,
        stepRedirects[stepName],
        isAccountSettings,
        isAccountSettings &&
          Boolean(onboardingCompleted && (pendingCommercialShippingAddress || pendingMedicalDirector))
      );
    });
  }, [progress, onboardingCompleted, pendingCommercialShippingAddress, pendingMedicalDirector]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const success = searchParams.get('success');

    setOpenEhrOnboardingSuccessModal(success === 'true');
  }, []);

  const stripeOnboarding = () => {
    onboardMerchant({ country: 'US' });
  };

  if (isLoadingMedspaAdminInfo || isLoading) {
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Page title="Home">
      <Box width="90%" marginBottom="2rem">
        <h1 className={classes.title}>Welcome to Your EHR Onboarding!</h1>
        {currentUserGroup?.paymentProcessor === STRIPE_PAYMENT_PROCESSOR && (
          <EHRButton
            dataCy="stripe-onboarding"
            data-testid="stripe-onboarding"
            text="Become a Partner"
            color="primary"
            onClick={() => stripeOnboarding()}
          />
        )}
        <h6 className={classes.subTitle}>
          We&apos;re thrilled to have you join our platform. To help you make the most of our exciting features and
          elevate your business to the next level, please complete this simple onboarding checklist. This process will
          guide you through configuring your account.
        </h6>
        <div className={classes.stepsContainer}>
          <div className={classes.verticalLine} />
          <List>
            {STEPS.map((step) => (
              <ListItem className={stepsStyles.listItem} key={step.step}>
                <ListItemIcon className={stepsStyles.listItemIcon}>
                  <Step
                    text={step.step}
                    title={step.title}
                    completed={step.completed}
                    pending={step.pending}
                    disabled={step.disabled}
                    isRequired={step.isRequired ?? false}
                    redirect={step.redirect}
                    completedPercentage={step.completedPercentage}
                  />
                </ListItemIcon>
              </ListItem>
            ))}
          </List>
        </div>
      </Box>

      <OnboardingSuccessModal open={openEhrOnboardingSuccessModal} setOpen={setOpenEhrOnboardingSuccessModal} />
    </Page>
  );
};

export default ToDoOnboarding;
