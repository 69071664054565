import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { EHRButton, EHRTypography } from 'src/components/ui/v1';

interface DeleteResourceDialogProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}

export const DeleteResourceDialog = ({ open, onClose, onDelete }: DeleteResourceDialogProps) => {
  const handleDelete = () => {
    onDelete();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <EHRTypography variant="h6" dataCy="delete-resource-title">
          Delete Resource
        </EHRTypography>
      </DialogTitle>
      <DialogContent>
        <EHRTypography variant="body1" dataCy="delete-resource-confirmation-text">
          Are you sure you want to delete this resource? This action cannot be undone.
        </EHRTypography>
      </DialogContent>
      <DialogActions>
        <EHRButton
          onClick={onClose}
          data-testid="cancel-delete-resource"
          dataCy="delete-resource-cancel-button"
          text="Cancel"
          color="secondary"
        />
        <EHRButton
          onClick={handleDelete}
          data-testid="confirm-delete-resource"
          dataCy="delete-resource-confirm-button"
          text="Delete"
          color="danger"
        />
      </DialogActions>
    </Dialog>
  );
};
