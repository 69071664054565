import { reduce } from 'lodash';
import Big from 'big.js';
import {
  CALLBACK_URL,
  CURRENCY_CODE,
  DISCOUNT_TYPES,
  PRICE_FLOOR_EXCLUDED_SERVICE_NAMES,
  SQUARE_CLIENT_ID,
  SQUARE_VERSION,
  SUPPORTED_TENDER_TYPES,
} from '../constants/checkout.constants';
import { SQUARE_STATUS } from '../constants/square.constants';
import {
  AMOUNT_FOR_REFERRER,
  CLERICAL_SERVICE_GROUP_ID,
  MISC_SERVICE_GROUP_ID,
  TOTAL_AMOUNT_REDEEMABLE,
} from '../constants/general.constants';
import { IMinimumAllServicesDiscountValidation, IDiscount, TendersObject } from '../interfaces/checkout.interfaces';
import { IServicesUnits } from '../interfaces/serviceVisit.interfaces';
import {
  IPractitionerCertificate,
  PatientCertificate as IPatientCertificate,
  PatientPointCertificatesState,
} from '../types/PatientPointCertificatesState';
import IServices from '../interfaces/IServices';
import IVariant, { IVariantItem } from '../interfaces/IVariants';
import { CheckoutLineItem } from '../types/CheckoutLineItem';
import { dispatch } from '../rematch';
import compile from './toastMessagesCompiler';
import { parseGaldermaProductsAndCertificates } from './galderma.utils';

export const getDataParameter = (amount: string, notes: string, state: string) => ({
  amount_money: {
    amount,
    currency_code: CURRENCY_CODE,
  },
  callback_url: CALLBACK_URL,
  client_id: SQUARE_CLIENT_ID,
  version: SQUARE_VERSION,
  notes,
  options: {
    supported_tender_types: SUPPORTED_TENDER_TYPES,
    clear_default_fees: true,
  },
  state,
});

export const getTransactionInfo = (url: string) => {
  const newURL: any = new URL(url);
  const data = decodeURI(newURL.searchParams.get('data'));
  const transactionInfo = JSON.parse(data);
  return transactionInfo;
};

export const getDiscountAmountByType = (discount: IDiscount): any => {
  switch (discount.discountType) {
    case DISCOUNT_TYPES.PERCENTAGE:
      return discount.discountByPercentage;
    case DISCOUNT_TYPES.VALUE:
      return discount.discountByValue;
    case DISCOUNT_TYPES.BUY_X_GET_Y:
      return discount.discountByX;
    default:
      return 0;
  }
};

const hasMinimumNumber = (
  requestedAmountByService: any,
  totalServicesUnits: IServicesUnits,
  totalCreditServicesUnits: any
): boolean => {
  const validations = requestedAmountByService || [];
  return validations.some(({ serviceId, minAmount }: any) => {
    const unitsAmount = +totalServicesUnits[serviceId] + (+totalCreditServicesUnits[serviceId] || 0);
    return unitsAmount >= +minAmount;
  });
};

const hasMinimumSumOfServices = (
  validationOptions: any,
  totalServicesUnits: IServicesUnits,
  totalCreditServicesUnits: any
): boolean => {
  let sumOfServices = 0;
  const { serviceIds, minNumber } = validationOptions;

  serviceIds.forEach((serviceId: number) => {
    const units = (+totalServicesUnits[serviceId] || 0) + (+totalCreditServicesUnits[serviceId] || 0);
    sumOfServices += units;
  });

  return sumOfServices >= minNumber;
};

const hasMinimumNumberOfAllServices = (
  validationOptions: IMinimumAllServicesDiscountValidation[],
  totalServicesUnits: IServicesUnits,
  totalCreditServicesUnits: any
): boolean =>
  validationOptions.every(({ serviceId, minimum }) => {
    const units = +totalServicesUnits[serviceId] + (+totalCreditServicesUnits[serviceId] || 0);
    return units >= minimum;
  });

const isBuyXGetYValid = (
  options: any,
  totalServicesUnits: IServicesUnits,
  totalCreditServicesUnits: IServicesUnits
): boolean => {
  const { requestedAmountByService, sumOfServices, minimumAllServices } = options || {};

  if (sumOfServices) {
    return hasMinimumSumOfServices(sumOfServices, totalServicesUnits, totalCreditServicesUnits);
    // eslint-disable-next-line no-else-return
  } else if (requestedAmountByService) {
    return hasMinimumNumber(requestedAmountByService, totalServicesUnits, totalCreditServicesUnits);
  } else if (minimumAllServices) {
    return hasMinimumNumberOfAllServices(minimumAllServices, totalServicesUnits, totalCreditServicesUnits);
  } else {
    return true;
  }
};

const isDiscountByPercentageValid = (
  options: any,
  totalServicesUnits: IServicesUnits,
  totalCreditServicesUnits: IServicesUnits
): boolean => {
  const { requestedAmountByService, minimumAllServices } = options || {};
  if (requestedAmountByService) {
    return hasMinimumNumber(requestedAmountByService, totalServicesUnits, totalCreditServicesUnits);
    // eslint-disable-next-line no-else-return
  } else if (minimumAllServices) {
    return hasMinimumNumberOfAllServices(minimumAllServices, totalServicesUnits, totalCreditServicesUnits);
  } else {
    return true;
  }
};

export const discountValidationFactory = (
  type: string,
  options: any,
  totalServicesUnits: IServicesUnits,
  totalCreditServicesUnits: IServicesUnits
): boolean => {
  switch (type) {
    case DISCOUNT_TYPES.BUY_X_GET_Y:
      return isBuyXGetYValid(options, totalServicesUnits, totalCreditServicesUnits);
    case DISCOUNT_TYPES.PERCENTAGE:
      return isDiscountByPercentageValid(options, totalServicesUnits, totalCreditServicesUnits);
    default:
      return true;
  }
};

export const formatNumber = (num: number) => num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

export const getRedeemableAmount = (referrals: any) => Math.trunc(referrals.length / 5) * TOTAL_AMOUNT_REDEEMABLE;

export const getReferredTotalAmount = (referrals: any) =>
  reduce(referrals, ({ value: acc }: any, { value }: any) => ({ value: acc + value }))?.value || 0;

export const calculateDiscount = (amount: string | number, type: string, total: number): number => {
  const displayAmount = type === DISCOUNT_TYPES.PERCENTAGE ? (+total / 100) * +amount : +amount;
  return displayAmount;
};

export const getGaldermaAspireExtraData = (
  patientCertificates: IPatientCertificate[],
  patientCertificatesSelected: number[],
  practitionerCertificates: IPractitionerCertificate[],
  practitionerCertificatesSelected: number[]
): { aspireVoucher: string; aspireValue: number } => {
  const aspirePatientCertificatesUsed = patientCertificates.filter(({ id }) =>
    patientCertificatesSelected.includes(id)
  );
  const aspirePractitionerCertificatesUsed = practitionerCertificates.filter(({ id }) =>
    practitionerCertificatesSelected.includes(id)
  );

  const aspireValue = [
    ...aspirePatientCertificatesUsed.map(({ amount }) => parseFloat(amount.replace('$', ''))),
    ...aspirePractitionerCertificatesUsed.map(({ amount }) => parseFloat(amount.replace('$', ''))),
  ];
  const aspireVoucher = [
    ...aspirePatientCertificatesUsed.map(({ code }) => code),
    ...aspirePractitionerCertificatesUsed.map(({ code }) => code),
  ];

  return {
    aspireVoucher: aspireVoucher.join(', '),
    aspireValue: aspireValue.reduce((a: number, v: number) => a + v, 0),
  };
};

export const checkoutInfo = ({
  serviceVisit,
  tenders = {},
  services = [],
  accountCreditUsed = 0,
  accountChargeLineItems = [],
  referralsIds = [],
}: {
  serviceVisit: any;
  tenders: TendersObject;
  services: IServices[];
  accountChargeLineItems: CheckoutLineItem[];
  accountCreditUsed: string | number;
  referralsIds: number[];
}) => {
  const {
    servicesUnits,
    creditServicesUnits,
    variantsUnits,
    creditVariantsUnits,
    checkout,
    futureCreditUsed,
    futureVariantCreditUsed,
  } = serviceVisit;

  const { servicesDiscounts, variantsDiscounts, discounts: checkoutDiscounts } = checkout;

  const subTotal = (): number => {
    let result = 0.0;

    const accountChargeLineItemsIds = accountChargeLineItems.map(({ serviceId }) => +serviceId);

    if (services.length) {
      Object.entries(servicesUnits)
        // to not duplicate the amounts of services in account charge line items, we filter those services here
        .filter(([serviceId]) => !accountChargeLineItemsIds.includes(+serviceId))
        .forEach(([serviceId, unit]: any) => {
          const service = services.find((serv: IServices) => serv.id === +serviceId);
          if (service) {
            result += service.price * +unit;
            if (service.variants) {
              Object.entries(variantsUnits).forEach(([variantItemId, varianUnit]: any) => {
                const variant = service.variants?.map(({ items }: IVariant) =>
                  items.find((variantItem: IVariantItem) => variantItem.id === +variantItemId)
                )[0];
                if (variant && +varianUnit > 0) {
                  result += variant.price;
                }
              });
            }
          }
        });

      Object.entries(creditServicesUnits).forEach(([serviceId, unit]: any) => {
        const service = services.find((serv: IServices) => serv.id === +serviceId);
        if (service) {
          result += service.price * +unit;
          if (service.variants) {
            Object.entries(creditVariantsUnits).forEach(([variantItemId, varianUnit]: any) => {
              const variant = service.variants?.map(({ items }: IVariant) =>
                items.find((variantItem: IVariantItem) => variantItem.id === +variantItemId)
              )[0];
              if (variant && +varianUnit > 0) {
                result += variant.price;
              }
            });
          }
        }
      });
    }

    if (accountChargeLineItems.length) {
      accountChargeLineItems.forEach((lineItem) => {
        result += lineItem.totalWithoutDiscount;
      });
    }

    return result;
  };

  const totalDiscounts = (): number => {
    let discountAmount = 0.0;

    if (
      !Object.entries(servicesDiscounts).length &&
      !Object.entries(variantsDiscounts).length &&
      !serviceVisit.serviceVisit.opened &&
      serviceVisit.checkout.transactionStatus === SQUARE_STATUS.OK
    ) {
      return checkoutDiscounts;
    }

    if (services.length) {
      Object.entries(servicesUnits).forEach(([serviceId, unit]: any) => {
        const service = services.find((serviceObj: any) => +serviceId === serviceObj.id);
        if (service) {
          const { price, variants } = service;
          const discounts = servicesDiscounts[serviceId] || [];

          discounts.forEach(({ amount, type }: any) => {
            const futureCredit = +creditServicesUnits[serviceId] || 0;
            discountAmount += calculateDiscount(amount, type, (+unit + futureCredit) * +price);
          });

          if (variants?.length) {
            Object.entries(variantsUnits).forEach(([variantItemId, variantUnit]: any) => {
              const variantItem = variants.map(({ items }: IVariant) =>
                items.find((item: any) => item.id === +variantItemId)
              )[0];
              const variantDiscount = variantsDiscounts[variantItemId] || [];

              variantDiscount.forEach(({ amount, type }: any) => {
                if (variantItem) {
                  const futureCredit = +creditVariantsUnits[variantItemId] / variantItem.minAmount;
                  const totalVariantUnit = +variantUnit / variantItem.minAmount;
                  discountAmount += calculateDiscount(
                    amount,
                    type,
                    (totalVariantUnit + futureCredit) * variantItem.price
                  );
                }
              });
            });
          }
        }
      });
    }

    return discountAmount;
  };

  const total = (): number => subTotal() - totalDiscounts();

  const getCreditsApplied = (): number => {
    let creditsApplied = 0;

    if (services.length) {
      futureCreditUsed.forEach(({ serviceId, used }: any) => {
        const service = services.find((serviceObj: IServices) => serviceId === serviceObj.id);
        if (service) {
          const { price } = service;
          creditsApplied += price * +used;
        }
      });
      futureVariantCreditUsed.forEach(({ variantItemId, used }: any) => {
        let variantItem: any = {};
        services.find((service: any) =>
          service.variants.find((variant: IVariant) =>
            variant.items.find((item: IVariantItem) => {
              if (item.id === +variantItemId) {
                variantItem = item;
              }
              return item.id === +variantItemId;
            })
          )
        );
        creditsApplied += (variantItem.price * +used) / variantItem.minAmount;
      });
    }

    return creditsApplied;
  };

  const otherTendersTotal = (): number => {
    let otherTendertotal = 0.0;

    if (tenders.aspire?.value) {
      // @ts-ignore
      otherTendertotal += parseFloat(tenders.aspire.value);
    }
    if (tenders.brilliant?.value) {
      // @ts-ignore
      otherTendertotal += parseFloat(tenders.brilliant.value);
    }
    if (tenders.care_credit?.value) {
      // @ts-ignore
      otherTendertotal += parseFloat(tenders.care_credit.value);
    }
    if (tenders.xperience?.value) {
      // @ts-ignore
      otherTendertotal += parseFloat(tenders.xperience.value);
    }

    return otherTendertotal;
  };

  const getReferralTotal = (): number => referralsIds.length * AMOUNT_FOR_REFERRER;

  const getAccountCreditUsed = (): number => Number(accountCreditUsed) || 0;

  const toPay = (): number => {
    const result =
      subTotal() -
      totalDiscounts() -
      otherTendersTotal() -
      getReferralTotal() -
      getCreditsApplied() -
      getAccountCreditUsed();

    return result > 0 ? result : 0;
  };

  return {
    total: total(),
    subTotal: subTotal(),
    totalDiscounts: totalDiscounts(),
    otherTendersTotal: otherTendersTotal(),
    getCreditsApplied: getCreditsApplied(),
    getAccountCreditUsed: getAccountCreditUsed(),
    getReferralTotal: getReferralTotal(),
    toPay: toPay(),
  };
};

export const unscannedProducts = (productsSelected: any[]): any[] =>
  productsSelected
    .filter((product: any) => !product.untracked && !product.assets.length)
    .map((product: any) => product.name);

export const initiateOrUpdateTransaction = ({
  patientId,
  serviceVisitId,
  checkoutInfoHash,
  serviceVisit,
  tenders = {},
  referralsIds = [],
  note,
  services,
  selectedReferralRedeemedIndexes,
  galdermaEmail,
  patientPointCertificates,
  showGaldermaBox,
  payWithCardForCharge = false,
  successCallback,
  errorCallback,
}: {
  patientId: string;
  serviceVisitId: string;
  note: string;
  checkoutInfoHash: any;
  serviceVisit: any;
  tenders: TendersObject;
  services: IServices[];
  referralsIds: number[];
  selectedReferralRedeemedIndexes: number[] | undefined;
  galdermaEmail: string | undefined;
  patientPointCertificates: PatientPointCertificatesState;
  showGaldermaBox: boolean;
  payWithCardForCharge: string | boolean | null | undefined;
  successCallback: () => void;
  errorCallback: (error: string | undefined) => void;
}) => {
  const {
    productsSelected,
    servicesUnits,
    creditServicesUnits,
    variantsUnits,
    creditVariantsUnits,
    checkout,
    totalServicesUnits,
    selectedServices,
  } = serviceVisit;
  const missingScannedServices = unscannedProducts(productsSelected);
  const { total, toPay, subTotal, totalDiscounts, otherTendersTotal, getAccountCreditUsed, getReferralTotal } =
    checkoutInfoHash;
  const {
    patientCertificates,
    practitionerCertificates,
    patientCertificatesSelected,
    practitionerCertificatesSelected,
    galdermaApplicableProducts,
    skusSelected,
  } = patientPointCertificates;

  const { taxes, servicesDiscounts, variantsDiscounts } = checkout;

  if (missingScannedServices.length) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.error_message', {
          action: 'please re scan the following services:',
          element: missingScannedServices.join(', '),
        }),
        type: 'error',
      },
    });
    return;
  }

  const checkoutObj: any = {};
  checkoutObj.patientId = patientId;
  checkoutObj.note = note;
  checkoutObj.subTotal = Number(new Big(+subTotal));
  checkoutObj.taxes = taxes;
  checkoutObj.total = Number(new Big(+total));
  checkoutObj.accountCreditUsed = Number(new Big(+getAccountCreditUsed));
  checkoutObj.discounts = Number(new Big(+totalDiscounts));
  checkoutObj.otherTendersTotal = Number(new Big(+(otherTendersTotal + getReferralTotal)));
  checkoutObj.toPay = Number(new Big(+toPay).toString());

  checkoutObj.tenders = tenders;
  if (referralsIds.length) {
    checkoutObj.tenders.referral = {
      type: 'referral',
      voucher: '',
      value: getReferralTotal,
    };
  }

  const lineItems: any = [];
  Object.entries(servicesUnits).forEach(([serviceId, unit]: any) => {
    const lineItem: any = {};
    lineItem[serviceId] = {};
    lineItem[serviceId].serviceId = serviceId;

    // @ts-ignore
    const { price, unitLabel } = services.find((service: any) => +serviceId === service.id);
    const discounts = servicesDiscounts[serviceId] || [];

    const futureCredit = +creditServicesUnits[serviceId] || 0;
    const totalUnits = +unit + futureCredit;

    lineItem[serviceId].quantity = +unit + futureCredit;
    lineItem[serviceId].unit = unitLabel;
    lineItem[serviceId].totalWithoutDiscount = +price * totalUnits;

    if (!discounts.length) {
      lineItem[serviceId].totalWithDiscount = lineItem[serviceId].totalWithoutDiscount;
    }

    lineItem[serviceId].discounts = [];
    discounts.forEach((discount: any) => {
      lineItem[serviceId].totalWithDiscount =
        +price * totalUnits - +calculateDiscount(discount.amount, discount.type, totalUnits * +price).toFixed(2);

      lineItem[serviceId].discounts.push({
        id: discount.id,
        value: discount.amount,
        discountType: discount.type,
        description: discount.reason,
      });
    });
    if (totalUnits !== 0) {
      lineItems.push(lineItem);
    }
  });
  Object.entries(variantsUnits).forEach(([variantItemId, unit]: any) => {
    const lineItem: any = {};
    lineItem[variantItemId] = {};
    lineItem[variantItemId].variantItemId = variantItemId;
    let variantItem: any = {};
    // @ts-ignore
    const { unitLabel } = services.find(({ variants }: { variants: IVariant[] }) =>
      variants.find((variant: IVariant) =>
        variant.items.find((item: IVariantItem) => {
          if (item.id === +variantItemId) {
            variantItem = item;
          }
          return item.id === +variantItemId;
        })
      )
    );

    const discounts = variantsDiscounts[variantItemId] || [];

    const futureCredit = +creditVariantsUnits[variantItemId] || 0;
    const totalUnits = +unit / +variantItem.minAmount + futureCredit / +variantItem.minAmount;

    lineItem[variantItemId].quantity = +unit + futureCredit;
    lineItem[variantItemId].unit = unitLabel;
    lineItem[variantItemId].totalWithoutDiscount = +variantItem.price * totalUnits;

    if (!discounts.length) {
      lineItem[variantItemId].totalWithDiscount = lineItem[variantItemId].totalWithoutDiscount;
    }

    lineItem[variantItemId].discounts = [];
    discounts.forEach((discount: any) => {
      lineItem[variantItemId].totalWithDiscount =
        +variantItem.price * totalUnits -
        +calculateDiscount(discount.amount, discount.type, totalUnits * +variantItem.price).toFixed(2);

      lineItem[variantItemId].discounts.push({
        id: discount.id,
        value: discount.amount,
        discountType: discount.type,
        description: discount.reason,
      });
    });
    if (totalUnits !== 0) {
      lineItems.push(lineItem);
    }
  });

  dispatch({
    type: 'newServiceVisit/applyExtraCheckoutData',
    payload: {
      note,
      brilliantValue: tenders.brilliant?.value || '',
      aspireValue: tenders.aspire?.value || '',
      careCreditValue: tenders.care_credit?.value || '',
      brilliantVoucher: tenders.brilliant?.voucher || '',
      aspireVoucher: tenders.aspire?.voucher || '',
      careCreditVoucher: tenders.care_credit?.voucher || '',
      xperienceVoucher: tenders.xperience?.voucher || '',
      xperienceValue: tenders.xperience?.value || '',
      referralsChunkIndex: selectedReferralRedeemedIndexes,
      redeemableIds: referralsIds,
      referralAmount: getReferralTotal,
      accountCreditUsed: Number(new Big(+getAccountCreditUsed)),
    },
  });

  const galdermaPayload = galdermaEmail
    ? parseGaldermaProductsAndCertificates({
      patientCertificates,
      practitionerCertificates,
      galdermaProducts: galdermaApplicableProducts,
      certificateIds: showGaldermaBox ? [] : patientCertificatesSelected,
      practitionerCertificateIds: showGaldermaBox ? [] : practitionerCertificatesSelected,
      totalServicesUnits,
      services: services.filter((service: IServices) => selectedServices.includes(service.id)),
      skusSelected,
      freeTextUsed: showGaldermaBox, // freeTextUsed field
      freeTextVoucher: tenders.aspire?.voucher
        ?.replace(/[^a-zA-Z0-9 ]/g, ' ')
        ?.split(' ')
        ?.filter((s: string) => s)
        ?.join(','),
    })
    : null;

  dispatch({
    type: 'newServiceVisit/checkout',
    payload: {
      checkout: {
        patientId,
        checkout: checkoutObj,
        redeemableIds: referralsIds,
        serviceVisitId,
        lineItems,
        galdermaPayload,
      },
      payWithCardForCharge,
      successCallback,
      errorCallback,
      amount: toPay,
    },
  });
};

export const warnLineItemBelowPriceFloor = ({
  totalDefaultPrice,
  totalWithDiscount,
  service,
  noCommission,
}: {
  totalWithDiscount: number;
  totalDefaultPrice: number;
  service: IServices | IVariantItem;
  noCommission: boolean;
}) => {
  if (service) {
    const priceFloorService =
      !PRICE_FLOOR_EXCLUDED_SERVICE_NAMES.includes(service.name) &&
      ![MISC_SERVICE_GROUP_ID, CLERICAL_SERVICE_GROUP_ID].includes(
        service.serviceGroupOriginalId || service.serviceGroupId
      );
    if (priceFloorService && !noCommission) {
      return totalWithDiscount < totalDefaultPrice;
    }
  }
  return false;
};
