import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Stepper, Step, StepLabel, StepContent } from '@material-ui/core';
import { NavigationBar } from '../components/PatientProfile/NavigationBar';
import { PatientHeader } from '../components/NewServiceVisit/PatientHeader';
import { dispatch } from '../rematch';
import { Services } from '../components/NewServiceVisit/Services';
import { BeforePhotos } from '../components/NewServiceVisit/BeforePhotos';
import { ServicesUnits } from '../components/NewServiceVisit/ServicesUnits';
import { Consents } from '../components/NewServiceVisit/consents/Consents';
import { StandingOrders } from '../components/NewServiceVisit/StandingOrders';
import { AfterPhotos } from '../components/NewServiceVisit/afterPhotos/AfterPhotos';
import MedicalChartingImages from '../components/NewServiceVisit/MedicalChartingImages';
import { ServiceVisitScanner } from '../components/NewServiceVisit/scanner/ServiceVisitScanner';
import { CheckoutStep } from '../components/NewServiceVisit/checkout/CheckoutStep';
import { FooterButtons } from '../components/NewServiceVisit/FooterButtons';
import { PHOTO_TYPE, SERVICE_TYPE } from '../constants/newServiceVisit.constants';
import {
  areAnnotationsComplete,
  getSelectedAnnotatableServiceIds,
  areUnitsChosenValid,
  isAfterPhotoRequired,
  isBeforePhotoRequired,
  hasPhotosFromType,
} from '../utils/newServiceVisit.util';
import { requiredOptionsAreIncluded } from '../utils/global';
import { SQUARE_STATUS } from '../constants/square.constants';
import { HistoricalPhotos } from '../components/NewServiceVisit/HistoricalPhotos';
import { AnnotationsStep } from '../components/NewServiceVisit/annotations/AnnotationsStep';
import { IAnnotationsState } from '../interfaces/annotation.interfaces';
import IServices from '../interfaces/IServices';
import SignaturesStep from '../components/NewServiceVisit/SignaturesStep';
import { useStyles } from './newServiceVisit.styles';
import { MultipleSkeleton } from '../components/common/LoadingSkeleton';
import SaveProgressWrapper from '../components/SaveProgressWrapper';
import { PhotosConsent } from '../components/NewServiceVisit/photosConsent/PhotosConsent';
import { ROUTES } from '../constants/routes.constants';
import { hasAccessTo } from '../utils/auth.utils';
import { SERVICE_VISIT_INVENTORY_SCAN } from '../constants/actions.constants';

const NewServiceVisit = ({
  match: {
    params: { patientId, serviceVisitId },
  },
}: any) => {
  const classes = useStyles();
  const history = useHistory();
  const [enableChargeButton, setEnableChargeButton] = useState(false);

  const {
    isLoading: isLoadingPatient,
    serviceGroups,
    effects: {
      patient: { fetchServiceGroups: isLoadingServiceGroups },
    },
  } = useSelector(({ patient, loading }: any) => ({ ...patient, ...loading }));

  const annotations = useSelector((store: any) => store.annotations as IAnnotationsState);

  const statePatient = useSelector(({ patient }: any) => patient);
  const permissions = useSelector(({ auth }: any) => auth.permissions);

  const {
    services = [],
    selectedServices,
    showConsentsAlert,
    signedStandingOrders,
    serviceVisitDirty,
    checkout,
    serviceVisit,
    isLoadingServices,
    productsSelected,
    totalServicesUnits,
    totalCreditServicesUnits,
    isProgressQueueWorking,
    selectedServiceGroups,
  } = useSelector((state: any) => state.newServiceVisit);

  const { transactionStatus } = checkout;

  const hasOldAnnotations =
    serviceVisit.annotatedPhotos?.some(({ photoServices }: any) =>
      photoServices.some(({ canvasData }: any) => canvasData)
    ) || false;

  const isLoading = isLoadingPatient || isLoadingServices || isLoadingServiceGroups;

  const isRetailSelectedOnly: boolean =
    Boolean(selectedServices.length) &&
    services
      .filter((service: IServices) => selectedServices.includes(service.id))
      .every((service: IServices) => service.serviceType === SERVICE_TYPE.RETAIL);

  const isPrescriptionRetailOrRetailSelectedOnly: boolean =
    Boolean(selectedServices.length) &&
    services
      .filter((service: IServices) => selectedServices.includes(service.id))
      .every(
        (service: IServices) =>
          service.serviceType === SERVICE_TYPE.RETAIL || service.serviceType === SERVICE_TYPE.PRESCRIPTION_RETAIL
      );

  /* This constants returns true if is ONLY follow up selected
   * TODO: check if selectedServices is [Follow up, Retail]
   * and all cases that use isFollowUpSelectedOnly */

  const isFollowUpSelectedOnly: boolean =
    Boolean(selectedServices.length) &&
    services
      .filter((service: IServices) => selectedServices.includes(service.id))
      .every((service: IServices) => service.serviceType === SERVICE_TYPE.FOLLOW_UP);

  useEffect(() => {
    dispatch({
      type: 'newServiceVisit/fetchServices',
      payload: { contextServiceVisitId: serviceVisitId },
    });

    dispatch.patient.fetchServiceGroups();
    dispatch({ type: 'patient/fetchPatientData', payload: +patientId });
    dispatch({ type: 'patient/fetchServiceRequests', payload: +patientId });
    // this is needed to disable the checkboxes in step 1 if the service visit is already paid
    dispatch({ type: 'newServiceVisit/fetchCheckout', payload: { serviceVisitId } });

    return () => {
      dispatch({ type: 'newServiceVisit/cleanServiceVisitData' });
    };
  }, []);

  useEffect(() => {
    if (statePatient.id) {
      dispatch({
        type: 'newServiceVisit/getServiceVisitFromPatient',
        payload: { serviceVisitId: +serviceVisitId, currentPatientId: +patientId },
      });
    }
  }, [statePatient.id]);

  useEffect(() => {
    setEnableChargeButton(!getPendingServices().length);
  }, [services, selectedServices, productsSelected, totalServicesUnits, totalCreditServicesUnits]);

  const navigateBack = () => {
    history.push(ROUTES.PATIENT_ID(patientId));
  };

  const step1Completed = (): string => (selectedServices.length ? '' : classes.stepIncompleteWithAlert);

  const step2Completed = (): string =>
    areUnitsChosenValid(totalServicesUnits, totalCreditServicesUnits) ? '' : classes.stepIncompleteWithAlert;

  const photosIds = serviceVisit.photos.map(({ photoId }: any) => photoId);

  const step4Completed = (): string => {
    const hasBeforePhotos = hasPhotosFromType(statePatient.photos, photosIds, PHOTO_TYPE.BEFORE);
    const alertStep = isBeforePhotoRequired(selectedServiceGroups, serviceGroups, hasBeforePhotos)
      ? classes.stepIncompleteWithAlert
      : '';
    return alertStep;
  };

  const step5Completed = (): string => {
    const alertStep = isRetailSelectedOnly || isFollowUpSelectedOnly ? '' : classes.stepIncompleteWithAlert;
    return showConsentsAlert ? alertStep : '';
  };

  const step6Completed = (): string => {
    const result: { [key: number]: boolean } = {};
    selectedServices?.forEach((serviceId: number) => {
      const service = services.find(
        (s: any) => s.id === serviceId && +totalServicesUnits[serviceId] > 0 && s.hasStandingOrder
      );

      result[serviceId] = !!service;
    });

    const alertStep =
      isPrescriptionRetailOrRetailSelectedOnly || isFollowUpSelectedOnly ? '' : classes.stepIncompleteWithAlert;

    const currentUseServices = selectedServices.filter(
      (serviceId: number) => +totalServicesUnits[serviceId] > 0 && result[serviceId]
    );

    return !requiredOptionsAreIncluded(currentUseServices, signedStandingOrders) ? alertStep : '';
  };

  const hasAfterPhotos = hasPhotosFromType(statePatient.photos, photosIds, PHOTO_TYPE.AFTER);

  const step7Completed = (): string => {
    const alertStep = isAfterPhotoRequired(selectedServiceGroups, serviceGroups, hasAfterPhotos)
      ? classes.stepIncompleteWithAlert
      : '';
    return alertStep;
  };

  const step9Completed = () => (getPendingServices().length ? classes.stepIncompleteWithAlert : '');

  const getPendingServices = () => {
    const pendingServices: any = [];

    if (services.length) {
      services.forEach((service: any) => {
        const atLeastOne = +totalCreditServicesUnits[service.id] + +totalServicesUnits[service.id];

        if (
          selectedServices.includes(service.id) &&
          !service.untracked &&
          atLeastOne &&
          totalServicesUnits[service.id] > 0
        ) {
          if (service.allowPartialSale && !productsSelected.some(({ id }: any) => id === service.id)) {
            pendingServices.push({ ...service, pending: 1 });
          } else if (service.allowPartialSale) {
            const selected = productsSelected.find(({ id }: any) => id === service.id);

            const sumOfRequestedUnits = selected.assets.reduce(
              (total: number, asset: any) => total + +asset.requestedUnits,
              0
            );

            if (sumOfRequestedUnits < totalServicesUnits[service.id]) {
              pendingServices.push({ ...service, pending: 1 });
            }
          } else if (!service.allowPartialSale) {
            const serviceScannedTimes = productsSelected.find(({ id }: any) => id === service.id)?.assets?.length || 0;
            const pending = parseInt(totalServicesUnits[service.id], 10) - serviceScannedTimes;

            if (pending) {
              pendingServices.push({ ...service, pending });
            }
          }
        }
      });
    }

    return pendingServices;
  };

  const step10Completed = (): string => (transactionStatus === SQUARE_STATUS.OK ? '' : classes.stepIncompleteWithAlert);

  const step11Completed = () => {
    if (hasOldAnnotations || isFollowUpSelectedOnly || isPrescriptionRetailOrRetailSelectedOnly) {
      return '';
    }
    const currentUseServices = selectedServices.filter((serviceId: number) => +totalServicesUnits[serviceId] > 0);
    const currentAnnotatableSelectedServices = getSelectedAnnotatableServiceIds(currentUseServices, services);
    return areAnnotationsComplete(currentAnnotatableSelectedServices, annotations)
      ? ''
      : classes.stepIncompleteWithAlert;
  };

  const getFeedbackClass = (callback: any): string => (serviceVisitDirty ? callback() : '');

  const scrollToInvalidStep = () => {
    let step: string = '';
    if (step1Completed() !== '') {
      step = 'step-services';
    } else if (step2Completed() !== '') {
      step = 'step-services-units';
    } else if (step4Completed() !== '') {
      step = 'step-before-photos';
    } else if (step5Completed() !== '') {
      step = 'step-signed-consent';
    } else if (step6Completed() !== '') {
      step = 'step-orders-approved';
    } else if (step7Completed() !== '') {
      step = 'step-after-photos';
    } else if (step9Completed() !== '') {
      step = 'step-scanner';
    } else if (step10Completed() !== '') {
      step = 'step-checkout';
    } else if (step11Completed() !== '') {
      step = 'step-medical-charting-images';
    }

    if (step) {
      const element = document.getElementById(step);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  // [BF-6 Workaround] Issue with blank page (overlay Ipad only)
  useEffect(() => {
    if (!isLoading && serviceVisit.id) {
      window?.scrollTo({ top: window?.pageYOffset + 1, behavior: 'smooth' });
    }
  }, [isLoading, serviceVisit.id]);

  const showOldMedicalCharting = () => {
    const func = ({ photoServices }: any) => photoServices.some(({ canvasData }: any) => canvasData);
    return serviceVisit.annotatedPhotos?.some(func);
  };

  return (
    <main>
      <SaveProgressWrapper patientId={patientId} serviceVisitId={serviceVisitId}>
        <NavigationBar title="New Service Visit" onBackButtonClick={navigateBack} />
        {isLoadingPatient ? (
          <MultipleSkeleton />
        ) : (
          <article>
            <div className={classes.header}>
              <PatientHeader serviceVisitId={+serviceVisitId} currentPatientId={+patientId} />
            </div>

            <div className={classes.stepsSection}>
              <Stepper orientation="vertical" className={classes.stepper} connector={<></>}>
                <Step active id="step-services">
                  <StepLabel classes={{ root: `${classes.stepRoot} ${getFeedbackClass(step1Completed)}` }} />
                  <StepContent className={classes.stepContent}>
                    <Services />
                  </StepContent>
                </Step>
                <Step active id="step-services-units">
                  <StepLabel classes={{ root: `${classes.stepRoot} ${getFeedbackClass(step2Completed)}` }} />
                  <StepContent className={classes.stepContent}>
                    <ServicesUnits />
                  </StepContent>
                </Step>
                <Step active id="historical-photos">
                  <StepLabel classes={{ root: classes.stepRoot }} />
                  <StepContent className={classes.stepContent}>
                    <HistoricalPhotos />
                  </StepContent>
                </Step>
                <Step active id="step-before-photos">
                  <StepLabel classes={{ root: `${classes.stepRoot} ${getFeedbackClass(step4Completed)}` }} />
                  <StepContent className={classes.stepContent}>
                    <BeforePhotos />
                  </StepContent>
                </Step>
                <Step active id="step-signed-consent">
                  <StepLabel classes={{ root: `${classes.stepRoot} ${getFeedbackClass(step5Completed)}` }} />
                  <StepContent className={classes.stepContent}>
                    <Consents isFollowUpSelectedOnly={isFollowUpSelectedOnly} />
                  </StepContent>
                </Step>
                <Step active id="step-orders-approved">
                  <StepLabel classes={{ root: `${classes.stepRoot} ${getFeedbackClass(step6Completed)}` }} />
                  <StepContent className={classes.stepContent}>
                    <StandingOrders />
                  </StepContent>
                </Step>
                <Step active id="step-after-photos">
                  <StepLabel classes={{ root: `${classes.stepRoot} ${getFeedbackClass(step7Completed)}` }} />
                  <StepContent className={classes.stepContent}>
                    <AfterPhotos
                      isAfterPhotoRequired={isAfterPhotoRequired(selectedServiceGroups, serviceGroups, hasAfterPhotos)}
                    />
                  </StepContent>
                </Step>
                <Step active id="step-photos-consent">
                  <StepLabel classes={{ root: `${classes.stepRoot}` }} />
                  <StepContent className={classes.stepContent}>
                    <PhotosConsent />
                  </StepContent>
                </Step>
                {hasAccessTo(SERVICE_VISIT_INVENTORY_SCAN, permissions) && (
                  <Step active id="step-scanner">
                    <StepLabel classes={{ root: `${classes.stepRoot} ${getFeedbackClass(step9Completed)}` }} />
                    <StepContent className={classes.stepContent}>
                      <ServiceVisitScanner
                        serviceVisitId={serviceVisitId}
                        patientId={patientId}
                        pendingServices={getPendingServices()}
                      />
                    </StepContent>
                  </Step>
                )}
                <Step active id="step-checkout">
                  <StepLabel classes={{ root: `${classes.stepRoot} ${getFeedbackClass(step10Completed)}` }} />
                  <StepContent className={classes.stepContent}>
                    <CheckoutStep disabled={!enableChargeButton || isProgressQueueWorking} />
                  </StepContent>
                </Step>
                <Step active id="step-medical-charting-images">
                  <StepLabel classes={{ root: `${classes.stepRoot} ${getFeedbackClass(step11Completed)}` }} />
                  <StepContent className={`${classes.stepContent} ${classes.lastStep}`}>
                    {showOldMedicalCharting() ? <MedicalChartingImages /> : <AnnotationsStep />}
                  </StepContent>
                </Step>
                <div className={classes.signaturesBox}>
                  <SignaturesStep />
                </div>
              </Stepper>
            </div>

            <FooterButtons
              serviceVisitId={serviceVisitId}
              patientId={patientId}
              scrollToInvalidStep={scrollToInvalidStep}
              hasOldAnnotations={hasOldAnnotations}
              isFollowUpSelectedOnly={isFollowUpSelectedOnly}
              isRetailSelectedOnly={isRetailSelectedOnly}
              isPrescriptionRetailOrRetailSelectedOnly={isPrescriptionRetailOrRetailSelectedOnly}
            />
          </article>
        )}
      </SaveProgressWrapper>
    </main>
  );
};

export default NewServiceVisit;
