import React from 'react';
import moment from 'moment';
// eslint-disable-next-line import/no-unresolved
import Slider from 'react-slick';
import { makeStyles } from '@material-ui/core';
import { CreateRounded } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { Lines } from '../../../interfaces/annotation.interfaces';
import { IServiceVisitPhoto } from '../../../interfaces/IServiceVisits';

interface IPhotoSliderProps {
  photos: IServiceVisitPhoto[];
  setSelectedPhoto: (photo: IServiceVisitPhoto) => void;
  selectedPhoto: IServiceVisitPhoto;
  lines: Lines;
  serviceVisitOpened: boolean;
  itemsPhotos?: number;
  isLoading: boolean;
  serviceColor: string | undefined;
}

export const PhotoSlider = (props: IPhotoSliderProps) => {
  const { photos, setSelectedPhoto, selectedPhoto, lines, serviceVisitOpened, itemsPhotos, isLoading, serviceColor } =
    props;

  const useStyles = makeStyles(() => ({
    container: {
      display: 'flex',
      height: 'fit-content',
      overflow: 'auto',
    },
    photoContainer: {
      width: 80,
      height: 100,
      marginBottom: '20px',
      marginRight: 10,
      padding: '5px 6px 8.9px',
      borderRadius: '5px',
      border: 'solid 2px #12574d',
      backgroundColor: '#e7eeed',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
    },
    photo: {
      width: 64,
      height: 64,
      objectFit: 'cover',
      marginBottom: 4,
    },
    iconContainer: {
      position: 'absolute',
      right: 1,
      borderRadius: '50%',
      backgroundColor: `${serviceColor}`,
      width: '25px',
      height: '25px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      top: 1,
    },
    icon: {
      color: 'white',
      fontSize: '16px',
    },
    photoSliders: {
      width: '100%',
      paddingLeft: 0,
    },
    containerDisplaySlide: {
      display: 'flex',
      verticalAlign: 'top',
    },
  }));

  const classes = useStyles();
  const parseDateToString = (photo: IServiceVisitPhoto): string => {
    const { createdAt } = photo;
    return moment(createdAt).format('MM/DD/YYYY');
  };

  const Photo = ({ photo }: { photo: IServiceVisitPhoto }) => (
    <div
      style={{ borderColor: selectedPhoto?.id === photo.id ? '#12574d' : 'transparent' }}
      className={classes.photoContainer}
      role="presentation"
      onClick={() => {
        setSelectedPhoto(photo);
      }}
    >
      {!!lines[photo.id]?.length && (
        <div className={classes.iconContainer}>
          <CreateRounded className={classes.icon} />
        </div>
      )}
      <img src={photo.thumbnailUrl} alt="Patient" className={classes.photo} />
      <small>{parseDateToString(photo)}</small>
    </div>
  );

  const skeletonArray = Array.from({ length: photos.length || 1 });

  const skeletonPhotos = skeletonArray.map(() => (
    <div className={classes.photoContainer}>
      <Skeleton variant="rect" width={64} height={64} style={{ marginBottom: 4 }} />
      <Skeleton variant="text" width={64} height={14} />
    </div>
  ));

  const photosSlider = isLoading
    ? skeletonPhotos
    : photos
      .filter(({ id }) => (!serviceVisitOpened ? lines[id]?.length : true))
      .map((photo) => <Photo key={photo.id} photo={photo} />);

  return (
    <>
      <div className={classes.photoSliders}>
        {itemsPhotos && itemsPhotos < photosSlider.length ? (
          <Slider dots={false} infinite={false} speed={500} slidesToShow={itemsPhotos} slidesToScroll={itemsPhotos}>
            {photosSlider}
          </Slider>
        ) : (
          <div className={classes.containerDisplaySlide}>{photosSlider}</div>
        )}
      </div>
    </>
  );
};
