import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  makeStyles,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { DeleteForever as DeleteForeverIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import { IconButton } from '../common/IconButton';
import { dispatch } from '../../rematch';
import { SQUARE_STATUS } from '../../constants/square.constants';

const mapState = () => ({});

const mapDispatch = ({ newServiceVisit: { discardNewService } }: any) => ({
  discardNewService: () => discardNewService(),
});

const useStyles = makeStyles({
  buttonLeft: {
    width: '123px',
    height: '47px',
    borderRadius: '5px',
    backgroundColor: '#e7eeed',
  },
  buttonRight: {
    width: '187px',
    height: '47px',
    borderRadius: '5px',
    backgroundColor: '#fbdada',
  },
  title: {
    fontFamily: 'Messina Sans SemiBold',
    fontSize: '24px !important',
    fontWeight: 600,
    paddingLeft: '35px',
    paddingTop: '15px',
  },
  content: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '16px',
    fontHeight: 1.38,
    paddingLeft: '15px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingTop: '30px',
  },
  containerDialog: {
    width: '450px',
    height: '251px',
  },
});

const DiscardModal = (props: any) => {
  const { transactionStatus } = useSelector(({ newServiceVisit }: any) => newServiceVisit.checkout);
  const { isLoadingDiscardServiceVisit } = useSelector(({ patient }: any) => patient);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const { serviceVisitId, currentPatientId } = props;

  useEffect(
    () => () => {
      dispatch.patient.fetchCustomerConsents(currentPatientId);
    },
    [currentPatientId]
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const discard = async () => {
    dispatch({
      type: 'patient/discardServiceVisit',
      payload: {
        serviceVisitId,
        callback: () => {
          dispatch({ type: 'newServiceVisit/cleanServiceVisitData' });
          history.replace(`/patient/${currentPatientId}`);
          handleClose();
        },
      },
    });
  };

  return (
    <>
      <IconButton
        onClick={handleClickOpen}
        title="DISCARD"
        style={{ color: '#000000', backgroundColor: '#fbdada', margin: '0 3px' }}
        Icon={<DeleteForeverIcon />}
        disabled={transactionStatus === SQUARE_STATUS.OK}
      />
      <Dialog open={open} onClose={handleClose}>
        <div className={classes.containerDialog} data-cy="discardServiceVisitModal">
          <DialogTitle className={classes.title}>Discard new service visit?</DialogTitle>
          <DialogContent>
            <DialogContentText className={classes.content}>
              By discarding the current service visit you will lose your progress.
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button data-cy="noDiscardBtn" onClick={handleClose} className={classes.buttonLeft}>
              NO
            </Button>
            <Button
              data-cy="discardServiceVisitYes"
              onClick={discard}
              className={classes.buttonRight}
              disabled={isLoadingDiscardServiceVisit}
            >
              {isLoadingDiscardServiceVisit ? <CircularProgress /> : `YES, DISCARD`}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default connect(mapState, mapDispatch)(DiscardModal);
