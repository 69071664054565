import { isArray, isNil, omitBy, snakeCase } from 'lodash';
import { PossibleTransactionResponse } from 'src/hooks/queries/usePractitionersDashboard';
import { IOpenServiceVisit } from 'src/interfaces/IServiceVisits';
import { SNACKBAR_ERROR } from 'src/constants/general.constants';
import { ChartReview, ChartReviewApi, IUpdateChartNote } from 'src/interfaces/IPractitionerChartReview';
import { MedicalDirectorInfo, MedicalLicenseDetails } from 'src/interfaces/onboarding.interface';
import {
  ASSIGNABLE_PRACTITIONERS_LIST,
  MEDSPA_ADMIN_LIST,
  MEDSPA_EARNINGS_CALCULATIONS_PATH,
  MEDSPA_FLEX_EARNINGS,
  MEDSPA_PRACTITIONERS_LIST,
  MEDSPA_PRICING,
  MEDSPA_PRICING_UPDATE,
  MEDSPA_ROLE_NAME_PATH,
  MEDSPA_SUMMARIZE_TRANSACTIONS_PATH,
  MEDSPA_V4_PATH,
  MEDSPA_YEAR_STATISTICS_PATH,
  USER_GROUP_ADMINS,
  USER_GROUP_ADMINS_UPDATE,
} from '../constants/apiRoutes.constants';
import { IMedspaAdminInfo, IMedspaAdminList, MedspaAdminProps } from '../interfaces/IMedspaAdminList';
import Api from './Api';
import { IEarningResponse, IPractitionerDashboardGraphs, IResponse, parseDataForGraph } from './PractitionersDashboard';
import compile from '../utils/toastMessagesCompiler';

import { showSnackbar } from '../utils/global';

type MedspaOnboardingSteps = {
  stepsCompleted: StepsCompleted;
  completed: boolean;
  message?: string;
};

export type StepsCompleted = {
  accountSettings: number;
  providersManagement: number;
  locationsManagement: number;
  servicesManagement: number;
  makeYourFirstOrder: number;
  patientsManagement: number;
  appointmentTypes: number;
  calendarSetUp: number;
};

const MedspaAdmins = {
  async getMedspaAdmins(filters: Record<string, any>): Promise<IMedspaAdminList> {
    const { data }: { success: boolean; data: IMedspaAdminList | null } = await Api.get(
      MEDSPA_ADMIN_LIST,
      omitBy(filters, isNil)
    );
    return data || [];
  },
  async getMedspaAdminInfo(): Promise<IMedspaAdminInfo> {
    const { data }: { success: boolean; data: IMedspaAdminInfo } = await Api.get(`${MEDSPA_ADMIN_LIST}/info`);
    return data || [];
  },

  async getMedspaAdminsByUserGroup(userGroupId: string): Promise<IMedspaAdminList> {
    if (!userGroupId) {
      return [];
    }
    const {
      success,
      administrators,
    }: {
      success: boolean;
      administrators: IMedspaAdminList | null;
    } = await Api.get(USER_GROUP_ADMINS(userGroupId));

    return success ? administrators ?? [] : [];
  },

  async getAssignablePractitioners(): Promise<IMedspaAdminList> {
    const { success, data }: { success: boolean; data: IMedspaAdminList | null } = await Api.get(
      ASSIGNABLE_PRACTITIONERS_LIST
    );
    return success ? data ?? [] : [];
  },

  async getMedspaPractitioners(): Promise<IMedspaAdminList> {
    const { success, data }: { success: boolean; data: IMedspaAdminList | null } = await Api.get(
      MEDSPA_PRACTITIONERS_LIST
    );
    return success ? data ?? [] : [];
  },
  async getMedspaYearStatistics(
    userGroupId: string,
    period: string,
    practitionerId: string
  ): Promise<IPractitionerDashboardGraphs | null> {
    try {
      const practitionerParam = !Number.isNaN(Number(practitionerId)) ? `?practitioner_ids[]=${practitionerId}` : '';
      const { revenue, earnings, appointments, appointmentsAverage }: IPractitionerDashboardGraphs = await Api.get(
        `${MEDSPA_YEAR_STATISTICS_PATH(userGroupId)}${practitionerParam}`,
        { period }
      );

      const revenueDataParsed = parseDataForGraph(revenue, period);
      const earningsDataParsed = parseDataForGraph(earnings, period);
      const appointmentsDataParsed = parseDataForGraph(appointments, period);
      const appointmentsAverageDataParsed = parseDataForGraph(appointmentsAverage, period);

      return {
        revenue: {
          ...revenueDataParsed,
        },
        earnings: {
          ...earningsDataParsed,
        },
        appointments: {
          ...appointmentsDataParsed,
        },
        appointmentsAverage: {
          ...appointmentsAverageDataParsed,
        },
      };
    } catch {
      showSnackbar(
        compile('generic.error_message', {
          action: 'fetching',
          element: 'yearly statistics',
        }),
        SNACKBAR_ERROR
      );
      return null;
    }
  },
  async getMedspaSummarizedTransactions(
    userGroupId: string,
    practitionerId: string,
    query: any
  ): Promise<IResponse | null> {
    const practitionerParam = !Number.isNaN(Number(practitionerId)) ? `?practitioner_ids[]=${practitionerId}` : '';
    const { month } = query;

    const params = {
      month,
      ...query,
    };

    const {
      transactions = [],
      meta,
      error,
    } = await Api.get(`${MEDSPA_SUMMARIZE_TRANSACTIONS_PATH(userGroupId)}${practitionerParam}`, params);

    if (error) {
      showSnackbar(
        compile('generic.error_message', {
          action: 'fetching',
          element: 'transactions',
        }),
        SNACKBAR_ERROR
      );
      throw error;
    }

    return { transactions, meta };
  },
  async getPractitionersMedspaEarningsCalculations(
    userGroupId: string,
    practitionerId: string,
    month?: string
  ): Promise<IEarningResponse | null> {
    try {
      const paramsList = [];
      paramsList.push(!Number.isNaN(Number(practitionerId)) ? `practitioner_ids[]=${practitionerId}` : '');
      paramsList.push(month ? `month=${month}` : '');

      const { earningsTiers, totals, baseCalculations, firstReport } = await Api.get(
        `${MEDSPA_EARNINGS_CALCULATIONS_PATH(userGroupId)}?${paramsList.join('&')}`
      );
      return { earningsTiers, totals, baseCalculations, firstReport };
    } catch {
      showSnackbar(
        compile('generic.error_message', {
          action: 'fetching',
          element: 'earnings',
        }),
        SNACKBAR_ERROR
      );
      return null;
    }
  },

  async getMedspaRole(): Promise<string | null> {
    const { success, data }: { success: boolean; data: string | null } = await Api.get(MEDSPA_ROLE_NAME_PATH);
    return success ? data : null;
  },

  async getFlexTransactions(
    userGroupId: string,
    practitionerId: string,
    query: any
  ): Promise<PossibleTransactionResponse | null> {
    try {
      const allLocationsQuery = {
        ...query,
        location: query.location === 'all' ? undefined : query.location,
      };
      const params = omitBy(allLocationsQuery, isNil);
      const practitionerParam = !Number.isNaN(Number(practitionerId)) ? `?practitioner_ids[]=${practitionerId}` : '';
      return Api.get(`${MEDSPA_FLEX_EARNINGS(userGroupId)}${practitionerParam}`, { ...params });
    } catch {
      showSnackbar(
        compile('generic.error_message', {
          action: 'fetching',
          element: 'transactions',
        }),
        SNACKBAR_ERROR
      );
      return null;
    }
  },
  async updateMedspaServicePricing({
    variantItemId,
    serviceId,
    priceOverride,
  }: {
    variantItemId?: number;
    serviceId?: number;
    priceOverride: number;
  }): Promise<any> {
    try {
      let params: any = {
        priceOverride,
      };
      if (variantItemId) {
        params = { ...params, variantItemId };
      } else if (serviceId) {
        params = { ...params, serviceId };
      }
      return await Api.put(MEDSPA_PRICING_UPDATE, params);
    } catch {
      showSnackbar(
        compile('generic.error_message', {
          action: 'updating',
          element: 'services pricing',
        }),
        SNACKBAR_ERROR
      );
      return null;
    }
  },
  async getServicePricingHistory({ serviceId }: { serviceId: string }) {
    try {
      const response = await Api.get(MEDSPA_PRICING, {
        serviceId,
      });
      return isArray(response) ? response : [];
    } catch {
      showSnackbar(
        compile('generic.error_message', {
          action: 'fetching',
          element: 'service pricing history',
        }),
        SNACKBAR_ERROR
      );
      return [];
    }
  },
  async getVariantItemPricingHistory({ variantItemId }: { variantItemId: string }) {
    try {
      const response = await Api.get(MEDSPA_PRICING, {
        variantItemId,
      });
      return isArray(response) ? response : [];
    } catch {
      showSnackbar(
        compile('generic.error_message', {
          action: 'fetching',
          element: 'variant service pricing history',
        }),
        SNACKBAR_ERROR
      );
      return [];
    }
  },
  async getOpenServiceVisits({
    practitionerId,
    userGroupId,
  }: {
    practitionerId: string;
    userGroupId: string;
  }): Promise<IOpenServiceVisit[] | null> {
    try {
      const params = {
        practitionerId,
      };

      const { serviceVisits } = await Api.get(
        `${MEDSPA_V4_PATH}/${userGroupId}/service_visits/open_visits`,
        !Number.isNaN(Number(practitionerId)) ? params : null
      );
      return serviceVisits;
    } catch {
      showSnackbar(
        compile('generic.error_message', {
          action: 'fetching',
          element: 'service visits',
        }),
        SNACKBAR_ERROR
      );
      return [];
    }
  },
  async getChartReviews({
    userGroupId,
    practitionerId,
    page = 1,
    showSaved,
    showUnread,
  }: {
    userGroupId: string | number;
    practitionerId: string | number;
    page?: number;
    showSaved?: boolean;
    showUnread?: boolean;
  }): Promise<ChartReview[]> {
    const { success, data }: ChartReviewApi = await Api.get(
      `${MEDSPA_V4_PATH}/${userGroupId}/service_visits/service_visit_medical_notes?`,
      {
        ...(!Number.isNaN(Number(practitionerId)) ? { practitionerId } : {}),
        page: page + 1,
        saved: showSaved,
        unread: showUnread,
      }
    );
    return success ? data : [];
  },
  async updateMedspaChart(params: IUpdateChartNote, userGroupId: number): Promise<void> {
    const requestParams = {
      cosignAcknowledged: params.acknowledged,
    };
    await Api.put(
      `${MEDSPA_V4_PATH}/${userGroupId}/service_visits/service_visit_medical_notes/${params.serviceVisitId}`,
      requestParams
    );
  },
  async toggleMedspaChart({
    serviceVisitId,
    userGroupId,
    saved,
  }: {
    serviceVisitId: number;
    saved: boolean;
    userGroupId: number;
  }): Promise<void> {
    return Api.put(`${MEDSPA_V4_PATH}/${userGroupId}/service_visits/service_visit_medical_notes/${serviceVisitId}`, {
      saved,
    });
  },

  async createMedspaAdmin(params: MedspaAdminProps, userGroupId: number): Promise<void> {
    const formData = new FormData();
    Object.entries(params).forEach(([key, value]) => {
      formData.append(snakeCase(key), value);
    });
    await Api.post(USER_GROUP_ADMINS(`${userGroupId}`), formData);
  },

  async updateMedspaAdmin(params: FormData, userGroupId: number, userId: number): Promise<void> {
    await Api.put(USER_GROUP_ADMINS_UPDATE(`${userGroupId}`, userId.toString()), params);
  },
  async getMedspaAdminOnboardingProgress(userGroupId: number): Promise<MedspaOnboardingSteps | null> {
    if (!userGroupId) {
      return null;
    }
    const { data } = await Api.get(`/v4/medspa/${userGroupId}/admin_onboarding_progress`);
    return data;
  },
  async updateMedspaAdminOnboardingProgress({
    stepName,
    percentage,
    override = false,
    userGroupId,
  }: {
    stepName: string;
    percentage: number;
    override: boolean;
    userGroupId: number;
  }): Promise<MedspaOnboardingSteps> {
    const { error, data } = await Api.post(`/v4/medspa/${userGroupId}/admin_onboarding_progress`, {
      stepName,
      percentage,
      override,
    });
    if (error) {
      throw new Error(error);
    }
    return data;
  },
  async updateMedspaMedicalDirector({
    medicalDirector,
    medicalLicenseDetails,
  }: {
    medicalDirector?: MedicalDirectorInfo;
    medicalLicenseDetails?: MedicalLicenseDetails;
  }): Promise<{ success: boolean }> {
    return Api.put('/v4/medspa/medspa-medical-director', {
      medicalDirector,
      medicalLicenseDetails,
    });
  },
};

export { MedspaAdmins, MedspaAdmins as default };
