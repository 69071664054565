import React, { useState, useEffect } from 'react';
import { Button, CardContent, Grid, Typography } from '@material-ui/core';
import { uniq } from 'lodash';
import {
  ITEMS_PHOTOS_LIMIT_DESKTOP,
  MIN_ITEMS_PHOTOS_LIMIT_MOBILE,
  WIDTH_MAX_DESKTOP,
} from '../../../constants/general.constants';
import Drawer from './Drawer/Drawer';
import { PhotoSlider } from './PhotoSlider';
import { ServiceNotes } from './ServiceNotes';
import { SELECTED_PHOTO_INITIAL_STATE } from '../../../constants/annotations.constants';
import useWindowDimensions from '../../../utils/useWindowDimensions';
import { ServicesButtons } from './ServicesButtons';
import { useStyles } from '../../NewServiceVisit/annotations/annotation.styles';
import IServices from '../../../interfaces/IServices';
import Annotation from '../../../interfaces/drawer.interfaces';
import { Card } from '../../common/card';
import IServiceVisit, { IServiceVisitPhoto, ILineItem, IMedicalCharting } from '../../../interfaces/IServiceVisits';
import { IPatientView } from '../../../types/Patient';
import { Lines } from '../../../interfaces/annotation.interfaces';
import { useMedicalChartingMutation } from '../../../hooks/serviceVisit/useMedicalCharting';
import { ISaveIMedicalChartingsParams } from '../../../interfaces/params/IServiceVisitParams';

interface MedicalChartingStepProps {
  serviceVisit: IServiceVisit;
  patient: IPatientView;
  services: IServices[];
  lineItems: ILineItem[];
  medicalCharting: IMedicalCharting;
  patientPhotos: IServiceVisitPhoto[];
  selectedServiceIds: number[];
  serviceVisitId: number;
  isLoading: boolean;
  saveMedicalCharting?: boolean;
  setSaveMedicalCharting?: any;
  disabled?: boolean;
}

const MedicalChartingStep = ({
  serviceVisit,
  patient,
  services,
  lineItems,
  medicalCharting,
  patientPhotos,
  selectedServiceIds,
  serviceVisitId,
  isLoading,
  saveMedicalCharting,
  setSaveMedicalCharting,
  disabled,
}: MedicalChartingStepProps) => {
  const [fullScreen, setFullScreen] = useState(false);
  const [annotations, setAnnotations] = useState<Lines | []>([]);
  const [serviceNotes, setServiceNotes] = useState<{ [serviceId: number]: string }>({});
  const [selectedPhoto, setSelectedPhoto] = useState<IServiceVisitPhoto>({ ...SELECTED_PHOTO_INITIAL_STATE });
  const [selectedServiceId, setSelectedServiceId] = useState<number | null>(null);
  const [drawerLines, setDrawerLines] = useState<Annotation.Line[]>([]);
  const addServiceNoteMutation = useMedicalChartingMutation();

  const classes = useStyles();

  const { width } = useWindowDimensions();

  const ITEMS_PHOTOS_LIMIT_BY_SCREEN_SIZE =
    width > WIDTH_MAX_DESKTOP ? ITEMS_PHOTOS_LIMIT_DESKTOP : MIN_ITEMS_PHOTOS_LIMIT_MOBILE;

  const getInternalNote = (serviceId: number) => services.find(({ id }) => id === serviceId)?.internalNote || '';

  const getPhotos = (): IServiceVisitPhoto[] => {
    let photoIds: number[];
    let photos: IServiceVisitPhoto[];
    if (!serviceVisit.opened || disabled) {
      if (!medicalCharting.annotatedPhotos) {
        return [];
      }
      photoIds = medicalCharting.annotatedPhotos.map(({ photo }) => photo.id);
      photos = patientPhotos.filter(({ id }) => photoIds.includes(id));
    } else {
      photos = patientPhotos.filter(({ serviceVisitId: svId, photoType }) => svId === +serviceVisitId || !photoType);
    }

    return photos;
  };

  const getLayers = (): Annotation.Layer[] =>
    selectedServiceIds
      .map((serviceId) => {
        const service = services.find(({ id }) => serviceId === id);

        if (!service) {
          return null;
        }

        return {
          id: serviceId,
          name: service.name,
          color: service.color,
        };
      })
      .filter((layer): layer is Annotation.Layer => layer !== null);

  const getAnnotatableServices = (): IServices[] => {
    const servicesWithUnits = lineItems.map((lineItem) =>
      lineItem.currentUseQuantity > 0 ? lineItem.serviceId : null
    );

    return services.filter(({ id, annotatable }: IServices) => servicesWithUnits.includes(id) && annotatable);
  };

  const onSave = () => {
    const photosToSend = Object.entries(annotations).map(([photoId, lines]) => ({
      photoId: +photoId,
      annotationLines: lines,
      photoServices: lines.length > 0 ? (uniq(lines.map(({ id }: { id: number }) => id)) as number[]) : [],
    }));

    const photoServiceIds = uniq(photosToSend.flatMap(({ photoServices }) => photoServices));

    const serviceNotesToSend = Object.entries(serviceNotes)
      .map(([id, note]) => ({
        serviceId: +id,
        note,
      }))
      .filter(({ serviceId }) => photoServiceIds.includes(serviceId));

    const params: ISaveIMedicalChartingsParams = {
      serviceVisitId,
      serviceNotes: serviceNotesToSend,
      photos: photosToSend,
    };

    addServiceNoteMutation.mutateAsync(params);
    setSaveMedicalCharting(false);
  };

  useEffect(() => {
    setAnnotations([]);
    setServiceNotes({});
    if (medicalCharting.annotatedPhotos.length > 0) {
      medicalCharting.annotatedPhotos.forEach((annotatedPhoto) => {
        setAnnotations((prevAnnotations) => ({
          ...prevAnnotations,
          [annotatedPhoto.photo.id]: annotatedPhoto.annotationLines,
        }));
      });
      medicalCharting.charges.forEach(({ serviceId, note }) => {
        setServiceNotes((prevServiceNotes) => ({
          ...prevServiceNotes,
          [serviceId]: note,
        }));
      });
    }
  }, [medicalCharting]);

  useEffect(() => {
    if (patientPhotos.length) {
      setSelectedPhoto(getPhotos()[0] || SELECTED_PHOTO_INITIAL_STATE);
    }
  }, [patientPhotos, patientPhotos, medicalCharting]);

  useEffect(() => {
    if (selectedPhoto.id) {
      setDrawerLines(annotations[selectedPhoto.id] || []);
    }
  }, [selectedPhoto]);

  useEffect(() => {
    setSelectedServiceId(selectedServiceIds.includes(selectedServiceId || NaN) ? selectedServiceId : null);

    if (annotations[selectedPhoto.id]) {
      setDrawerLines(annotations[selectedPhoto.id].filter(({ id }) => selectedServiceIds.includes(id)));
    }
  }, [selectedServiceIds]);

  const ServiceButtons: React.ReactElement<any, string | React.JSXElementConstructor<any>> = (
    <ServicesButtons
      services={getAnnotatableServices()}
      setSelectedServiceId={setSelectedServiceId}
      selectedServiceId={selectedServiceId}
      isLoading={isLoading}
    />
  );

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <div className={classes.contentMedicalCharting}>
          <Typography>Medical charting</Typography>
          {serviceVisit.opened && !disabled && (
            <p style={{ color: 'gray', fontFamily: 'Messina Sans Regular', fontSize: '14px', marginTop: '6px' }}>
              If you charged <b>{`${patient.firstName}, ${patient.lastName}`}</b> for this visit, you may chart later
              via your to-do list
            </p>
          )}
        </div>
        <Grid>
          <Grid container>
            <Grid item sm={6} className={classes.annotationPhoto}>
              <Drawer
                fullScreen={fullScreen}
                setFullScreen={setFullScreen}
                imageUrl={selectedPhoto.mediumUrl}
                layers={getLayers()}
                selectedServiceId={selectedServiceId}
                initialLines={drawerLines}
                bottomButtons={ServiceButtons}
                selectedPhotoId={selectedPhoto.id}
                isLoading={isLoading}
                serviceNotes={serviceNotes}
                setServiceNotes={setServiceNotes}
                getInternalNote={getInternalNote}
                setAnnotations={setAnnotations}
                setSaveMedicalCharting={setSaveMedicalCharting}
                serviceVisitOpened={serviceVisit.opened && !disabled}
              />
              {serviceVisit.opened && !disabled && ServiceButtons}
            </Grid>
            <Grid item sm={6} className={classes.annotationForm}>
              <PhotoSlider
                photos={getPhotos()}
                setSelectedPhoto={setSelectedPhoto}
                selectedPhoto={selectedPhoto}
                lines={annotations}
                serviceVisitOpened={serviceVisit.opened && !disabled}
                itemsPhotos={ITEMS_PHOTOS_LIMIT_BY_SCREEN_SIZE}
                isLoading={isLoading}
                serviceColor={services.find(({ id }) => id === selectedServiceId)?.color}
              />
              <ServiceNotes
                servicesUsedIds={uniq(annotations[selectedPhoto.id]?.map(({ id }) => id) || [])}
                services={services}
                lineItems={lineItems}
                serviceNotes={serviceNotes}
                serviceVisitOpened={serviceVisit.opened && !disabled}
                isLoading={isLoading}
                setServiceNotes={setServiceNotes}
                setSaveMedicalCharting={setSaveMedicalCharting}
              />
            </Grid>
          </Grid>
          <div className={classes.contentButton}>
            {saveMedicalCharting && (
              <Button type="button" className={classes.saveButton} onClick={() => onSave()}>
                SAVE MEDICAL CHARTING
              </Button>
            )}
          </div>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default MedicalChartingStep;
