import React, { useEffect, useRef, useState } from 'react';
import { Box, Table, TableBody, TableContainer, makeStyles } from '@material-ui/core';
import classnames from 'classnames';
import { Skeleton } from '@material-ui/lab';
import { MultipleSkeleton, ShortMultipleSkeleton } from '../LoadingSkeleton';
import TablePaginatorHeader from './TablePaginatorHeader';
import TablePaginatorRows from './TablePaginatorRows';
import SortDirection, { ASCENDING } from '../../../types/SortDirection';
import { TableResultsProps, TablePaginatorProps } from '../../../types/TablePaginator';
import { useOnScreen } from '../../../utils/global';
import compile from '../../../utils/toastMessagesCompiler';

const TIME_AVG_TO_RENDER = 2000;
const useStyles = makeStyles(() => ({
  tableGeneral: {
    position: 'relative',
  },
  tableContainer: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    overflowWrap: 'break-word',
  },
  bottomRef: {
    width: '100%',
    marginTop: '15px',
  },
  bottomRefHidden: {
    backgroundColor: 'black',
  },
}));

const ShowMessage = ({ text }: any) => (
  <tr>
    <td colSpan={7}>
      <Box textAlign="center">
        <p>{text}</p>
      </Box>
    </td>
  </tr>
);

const TableResults = ({
  onClickRow,
  data,
  isError,
  errorMessage = '',
  columns,
  RenderDetails,
  renderDetailsByRowClick,
  displayAllDetails,
  backgroundAlternated,
}: TableResultsProps) => {
  if (isError) {
    return <ShowMessage text={errorMessage || compile('generic.server_error')} />;
  }
  if (!data) {
    return <></>;
  }

  return data.length > 0 ? (
    <>
      <TablePaginatorRows
        onClickRow={onClickRow}
        columns={columns}
        rows={data}
        RenderDetails={RenderDetails}
        renderDetailsByRowClick={renderDetailsByRowClick}
        displayAllDetails={displayAllDetails}
        backgroundAlternated={backgroundAlternated}
      />
    </>
  ) : (
    <ShowMessage text="No data to show" />
  );
};
/*
  This component is used to generate an automated pagination table
 */
const TablePaginator = ({
  isLoading,
  isFetching = false,
  isError = false,
  errorMessage = '',
  data,
  columns,
  onChangeSortBy,
  total,
  fetchNextPage,
  onClickRow,
  RenderDetails,
  renderDetailsByRowClick,
  displayAllDetails,
  backgroundAlternated = true,
}: TablePaginatorProps) => {
  const classes = useStyles();
  const refBottom = useRef<HTMLDivElement | null>(null);
  const isBottomVisible = useOnScreen(refBottom);

  const [isDataRendered, setIsDataRendered] = useState(false);
  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState<SortDirection>(ASCENDING);
  const hasMore = data.length < total;

  const onChangeSort = (column: string, direction: SortDirection) => {
    if (!onChangeSortBy) {
      return;
    }

    setSortBy(column);
    setSortDirection(direction);
    onChangeSortBy(column, direction);
  };

  useEffect(() => {
    if (!isFetching && !isLoading && fetchNextPage && hasMore && isBottomVisible && isDataRendered) {
      setIsDataRendered(false);
      fetchNextPage();
    }
  }, [isBottomVisible, isFetching, fetchNextPage, isLoading, hasMore, isDataRendered]);

  useEffect(() => {
    // Avoid request to next page if bottom skeleton is shown instantly after load first data
    setTimeout(() => {
      setIsDataRendered(true);
    }, TIME_AVG_TO_RENDER);
  }, [data.length]);

  return (
    <div className={classes.tableGeneral} style={{ height: fetchNextPage ? '70vh' : '' }}>
      {isFetching || isLoading ? <Skeleton height="15px" /> : <div style={{ height: '15px' }} />}
      <TableContainer className={classes.tableContainer} style={{ overflowX: fetchNextPage ? 'scroll' : 'auto' }}>
        <Table>
          <TablePaginatorHeader
            columns={columns}
            sortBy={sortBy}
            sortDirection={sortDirection}
            onSortChange={onChangeSort}
            showRenderDetails={renderDetailsByRowClick ? false : !!RenderDetails}
            backgroundAlternated={backgroundAlternated}
          />
          <TableBody data-cy="tableBody">
            {isLoading ? (
              <MultipleSkeleton width="90%" key={0} />
            ) : (
              <TableResults
                onClickRow={onClickRow}
                data={data}
                isError={isError}
                errorMessage={errorMessage}
                columns={columns}
                RenderDetails={RenderDetails}
                renderDetailsByRowClick={renderDetailsByRowClick}
                displayAllDetails={displayAllDetails}
                backgroundAlternated={backgroundAlternated}
              />
            )}
            {/* Div used to load next page if is focused on screen */}
            <div ref={refBottom} className={classnames({ [classes.bottomRefHidden]: !isDataRendered || isFetching })} />
            {hasMore && !isLoading && (
              <div className={classes.bottomRef}>{data.length !== 0 && <ShortMultipleSkeleton length={2} />}</div>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TablePaginator;
