import { InfiniteData, useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import { useEffect, useState } from 'react';
import {
  CLEARANCE_HISTORY,
  CLEARANCE_HISTORY_AVAILABLE_DATES,
  CLEARANCE_HISTORY_STATISTIC,
} from '../../constants/reactQuery.keys';
import { Physicians } from '../../services/Physicians';
import { ClearanceHistoryCustomerResponse } from '../../interfaces/IClearanceHistory';

export interface ClearanceParams {
  search?: string;
  endDate?: string;
  startDate?: string;
  practitionerId?: string;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
}

export const useClearanceHistory = (queryParams: ClearanceParams) => {
  const queryClient = useQueryClient();
  const [results, setResults] = useState<InfiniteData<ClearanceHistoryCustomerResponse>>();

  useEffect(() => {
    queryClient.invalidateQueries([CLEARANCE_HISTORY, queryParams]);
  }, [JSON.stringify(queryParams)]);

  const { data, isLoading, fetchNextPage, hasNextPage, isFetching, isError, refetch } = useInfiniteQuery(
    [CLEARANCE_HISTORY, queryParams],
    ({ pageParam = 1 }) => Physicians.getClearedPatients(pageParam, queryParams),
    {
      staleTime: 60000,
      getNextPageParam: (lastPage: any) => {
        if (lastPage?.meta?.currentPage < lastPage?.meta?.totalPages) {
          return lastPage.meta.currentPage + 1;
        }
        return false;
      },
    }
  );

  useEffect(() => {
    if (!isLoading && data) {
      setResults(data);
    }
  }, [data, isLoading, queryParams]);

  return {
    refetch,
    results,
    isLoading,
    isFetching,
    isError,
    fetchNextPage,
    hasNextPage,
  };
};

export const useClearanceHistoryStatistics = (year: number) =>
  useQuery([CLEARANCE_HISTORY_STATISTIC, year], async () => Physicians.getClearedPatientStatistics(year));

export const useAvailableYearsAndMonths = (providerId: number) =>
  useQuery([CLEARANCE_HISTORY_AVAILABLE_DATES, providerId], async () =>
    Physicians.getAvailableYearsAndMonths(providerId)
  );
