import React, { useContext, useEffect, useState } from 'react';
import useGetCards from 'src/hooks/queries/medspaAdmins/useMedspaPaymentMethods';
import { useSelector } from 'react-redux';
import { RootState } from 'src/rematch';
import { useUpdatePaymentSource } from 'src/hooks/queries/useInventoryOrders';
import { groupBy } from 'lodash';
import { OrderContext } from '../..';
import { CardList } from './CardList';
import OrderConfirmPanel from '../OrderConfirmPanel';

const CardOnFile = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    user: { userGroupId },
  } = useSelector(({ auth }: RootState) => auth);

  const { orderData, blockProgress, isFetching = false } = useContext(OrderContext) || {};

  const { data: creditCards, isLoading: isLoadingCards } = useGetCards(userGroupId ?? 0);
  const { mutateAsync: updatePaymentMethod } = useUpdatePaymentSource(orderData?.id ?? 0);

  const cardDetilsById = groupBy(creditCards, 'id');
  const isUpdating = isLoading || isFetching;

  const handleUpdatePaymentMethod = async (value: string): Promise<void> => {
    const creditCard = cardDetilsById[value]?.[0];

    if (creditCard) {
      const { last4, cardBrand, id: paymentSourceId } = creditCard;

      setIsLoading(true);
      try {
        await updatePaymentMethod({ last4, cardBrand, paymentSourceId });
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    blockProgress?.(isUpdating);
  }, [blockProgress, isUpdating]);

  return (
    <OrderConfirmPanel title="Payment Options">
      <CardList
        isLoading={isUpdating || isLoadingCards}
        creditCards={creditCards ?? []}
        value={orderData?.paymentSourceId}
        onChange={handleUpdatePaymentMethod}
      />
    </OrderConfirmPanel>
  );
};

export default CardOnFile;
