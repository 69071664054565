import React from 'react';
import { fixDecimals } from 'src/utils/formatNumber';
import { SQUARE_ERROR_CODES, SQUARE_STATUS } from '../../constants/square.constants';
import { Pill, PillColors } from '../common/Pill';

interface ICheckoutHeaderProps {
  transactionStatus: string;
  squareErrorCode: string;
  processedInSquare: boolean | undefined;
  toPay: number;
  amountPaid: number;
  hasPartialRefund: boolean;
}

const PaymentTag = ({
  transactionStatus,
  squareErrorCode,
  processedInSquare,
  toPay,
  amountPaid,
  hasPartialRefund,
}: ICheckoutHeaderProps) => {
  const getPillTitle = (): string => {
    const normalizedAmountPaid = fixDecimals(amountPaid, 2);
    const normalizedToPay = fixDecimals(toPay, 2);

    if (transactionStatus === SQUARE_STATUS.OK && !!normalizedAmountPaid && hasPartialRefund) {
      return 'Partially Refunded';
    }
    if (
      transactionStatus === SQUARE_STATUS.OK &&
      !!normalizedAmountPaid &&
      !!normalizedToPay &&
      normalizedAmountPaid < normalizedToPay
    ) {
      return 'Partially Paid';
    }
    if (transactionStatus === SQUARE_STATUS.OK) {
      return processedInSquare ? 'Paid' : 'Processed';
    }
    if (transactionStatus === SQUARE_STATUS.ERROR) {
      return SQUARE_ERROR_CODES[squareErrorCode];
    }
    return 'pending';
  };

  const getPillColor = (): PillColors => (transactionStatus === SQUARE_STATUS.ERROR ? 'red' : 'green');

  return <Pill title={getPillTitle()} color={getPillColor()} />;
};

export default PaymentTag;
