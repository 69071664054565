import { Appointments } from '@devexpress/dx-react-scheduler-material-ui';
import { Box, makeStyles, Tooltip } from '@material-ui/core';
import React, { useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { MedspaCalendarGFEStatusEnum } from 'src/constants/general.constants';
import { BookingTypeEnum } from './EnumBookingType';
import { ExtendedAppointmentModel } from './grouping';
import { getGFEStatusIconComponent, getGFEStatusTooltipText, gfeStatusColors } from './gfeStatusUtils';
import { AppointmentStatusEnum } from './AppointmentStatusEnum';
import { VIEW_NAME_DAY, VIEW_NAME_WEEK } from '../indexV2';
import { PhotoRequested } from '../../../DashboardPractitioner/Tabs/RowItem';
import { REQUEST_STATUS_OPTIONS } from '../../../../constants/newPhotoRequest.constants';

const { CLEARED, TO_CLEAR, TO_RECLEAR, INCOMPLETE } = MedspaCalendarGFEStatusEnum;

const useStyles = makeStyles(() => ({
  weekAppointment: {
    height: '3rem',
  },
  appointmentIcon: {
    padding: '0.1rem',
    float: 'right',
  },
  appointmentCardCommon: {
    backgroundColor: 'rgb(255, 213, 79)',
    width: '100%',
    border: '1px solid #fff',
    height: '100%',
    overflow: 'hidden',
    fontSize: '0.75rem',
    boxSizing: 'border-box',
    fontFamily: 'Roboto',
    lineHeight: '1.66',
    userSelect: 'none',
    borderRadius: '4px',
    backgroundClip: 'padding-box',
    marginBottom: '1px',
    cursor: 'pointer',
  },
  appointmentDescription: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  blockOff: {
    borderRadius: 0,
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderLeftWidth: '2px',
    borderLeftColor: '#909090',
    backgroundImage: `
      repeating-linear-gradient(
        -60deg,
        #e5e5e5 1px,
        #f0f0f0 4px,
        #f0f0f0 9px
      )`,
    [`& [class^="VerticalAppointment-content"]`]: {
      color: '#000',
    },
    '&:hover': {
      filter: 'brightness(85%)',
    },
  },
  [CLEARED]: {
    borderRadius: 0,
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderLeftWidth: '2px',
    borderLeftColor: gfeStatusColors[CLEARED].iconBGColor,
    [`& [class^="VerticalAppointment-content"]`]: {
      color: '#000',
    },
  },
  [TO_CLEAR]: {
    borderRadius: 0,
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderLeftWidth: '2px',
    borderLeftColor: gfeStatusColors[TO_CLEAR].iconBGColor,
    backgroundColor: gfeStatusColors[TO_CLEAR].appointmentBGColor,
    [`& [class^="VerticalAppointment-content"]`]: {
      color: '#000',
    },
    '&:hover': {
      filter: 'brightness(85%)',
      backgroundColor: gfeStatusColors[TO_CLEAR].appointmentBGColor,
    },
  },
  [TO_RECLEAR]: {
    borderRadius: 0,
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderLeftWidth: '2px',
    borderLeftColor: gfeStatusColors[TO_RECLEAR].iconBGColor,
    backgroundColor: gfeStatusColors[TO_RECLEAR].appointmentBGColor,
    [`& [class^="VerticalAppointment-content"]`]: {
      color: '#000',
    },
    '&:hover': {
      filter: 'brightness(85%)',
      backgroundColor: gfeStatusColors[TO_RECLEAR].appointmentBGColor,
    },
  },
  [INCOMPLETE]: {
    borderRadius: 0,
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderLeftWidth: '2px',
    borderLeftColor: gfeStatusColors[INCOMPLETE].iconBGColor,
    backgroundColor: gfeStatusColors[INCOMPLETE].appointmentBGColor,
    [`& [class^="VerticalAppointment-content"]`]: {
      color: '#000',
    },
    '&::hover': {
      filter: 'brightness(85%)',
    },
  },
  [AppointmentStatusEnum.RESERVED]: {
    backgroundColor: '#ffcdcd',
    backgroundImage: 'repeating-linear-gradient(-60deg, rgb(255 79 79 / 0%) 1px, #ffffff 4px, rgb(255 79 79 / 0%) 9px)',
  },
  unkownGfe: {
    backgroundColor: '#c3c3c3',
    '&::hover': {
      filter: 'brightness(85%)',
      backgroundColor: '#c3c3c3',
    },
  },
  pendingConfirmation: {
    backgroundImage: 'repeating-linear-gradient(-60deg, rgb(255 79 79 / 0%) 1px, #ffffff 4px, rgb(255 79 79 / 0%) 9px)',
    '&:hover': {
      filter: 'brightness(95%)',
    },
  },
  canceled: {
    backgroundImage: `
      repeating-linear-gradient(
        -60deg,
        #ffcccc 1px,
        #ffe6e6 4px,
        #ffe6e6 9px
      )`,
    '&:hover': {
      filter: 'brightness(85%)',
    },
    pointerEvents: 'none',
    opacity: 0.7,
  },
}));

const getGFEStatusComponent = (gfeStatus: MedspaCalendarGFEStatusEnum | null) => {
  if (gfeStatus === null || gfeStatus === undefined) {
    return <></>;
  }

  const IconComponent = getGFEStatusIconComponent(gfeStatus);

  return <IconComponent />;
};

const NOTES_DURATION_CUTOFF = 45;
const SHORT_APPOINTMENT_DURATION_THRESHOLD = 20;

export const AppointmentCard = ({ appointmentData, onClick, view }: any) => {
  const classes = useStyles();
  const appointment = appointmentData as ExtendedAppointmentModel;
  const gfeClassName = appointment.gfeStatus ? classes[appointment.gfeStatus] : '';
  const gfeStatusComponent = getGFEStatusComponent(appointment.gfeStatus);
  const duration = moment.duration(moment(appointment.endDate).diff(moment(appointment.startDate))).asMinutes();
  const isBlockOff = appointment.bookingType === BookingTypeEnum.BLOCK_OFF_HOUR;
  const isCanceled = appointment.status === AppointmentStatusEnum.CANCELLED;
  const isPendingConfirmation = appointment.status === AppointmentStatusEnum.PENDING_CONFIRMATION;
  const [isClicked, setIsClicked] = useState(false);

  let PatientAppointmentComponent = () => <Box />;
  if (appointment.patientName && appointment.appointmentTypeName) {
    PatientAppointmentComponent = () => (
      <Box>
        <strong>{appointment.patientName}</strong>
        <span>&nbsp;-&nbsp;{appointment.appointmentTypeName}</span>
        {!isCanceled && duration < NOTES_DURATION_CUTOFF && view !== VIEW_NAME_WEEK && (
          <pre style={{ display: 'inline-block', margin: '0 0 0 1rem' }}>{appointment.notes}</pre>
        )}
        {isCanceled && <span>&nbsp;(Canceled)</span>}
      </Box>
    );
  }
  if (isBlockOff) {
    PatientAppointmentComponent = () => (
      <Box>
        <strong>Block Off</strong>
        {!isCanceled && duration < NOTES_DURATION_CUTOFF && view !== VIEW_NAME_WEEK && (
          <pre style={{ display: 'inline-block', margin: '0 0 0 1rem' }}>{appointment.notes}</pre>
        )}
      </Box>
    );
  }

  const handleClick = (e: React.MouseEvent) => {
    if (!isCanceled && onClick) {
      setIsClicked(true);
      onClick(e);
    }
  };

  const hasPhotoRequested =
    appointment?.lastPhotoRequestStatus === REQUEST_STATUS_OPTIONS.REQUESTED ||
    appointment.lastPhotoRequestStatus === REQUEST_STATUS_OPTIONS.REJECTED;

  const dateRangeString = `${moment(appointment.startDate).format('hh:mm A')} - ${moment(appointment.endDate).format(
    'hh:mm A'
  )}`;
  const isShortAppointment =
    moment(appointment.endDate).diff(moment(appointment.startDate), 'minutes') <= SHORT_APPOINTMENT_DURATION_THRESHOLD;

  const tooltipTitle = `${dateRangeString}${
    appointment.appointmentTypeName ? ` - ${appointment.appointmentTypeName}` : ''
  }${appointment.patientName ? `. ${appointment.patientName}` : ''}`;

  return (
    <Box
      className={classNames(classes.appointmentCardCommon, {
        [classes.blockOff]: isBlockOff,
        [classes.canceled]: isCanceled,
        [classes.pendingConfirmation]: isPendingConfirmation,
        [gfeClassName]: true,
        [classes.reserved]: appointment.status === AppointmentStatusEnum.RESERVED,
      })}
      onClick={handleClick}
    >
      {!isCanceled && (
        <Box className={classes.appointmentIcon}>
          <Tooltip
            title={getGFEStatusTooltipText(appointment.gfeStatus)}
            placement="top"
            arrow
            disableHoverListener={isClicked}
          >
            <Box>{gfeStatusComponent}</Box>
          </Tooltip>
          {hasPhotoRequested && <PhotoRequested />}
        </Box>
      )}
      <Tooltip title={tooltipTitle} placement="top" arrow disableHoverListener={isClicked}>
        <Box padding="0.25rem">
          <Box fontSize="0.65rem" className={classes.appointmentDescription}>
            {isShortAppointment ? (
              <Box>
                {moment(appointment.startDate).format('hh:mm A')} <strong>{appointment.patientName || ''}.</strong>{' '}
                {appointment.appointmentTypeName}
              </Box>
            ) : (
              dateRangeString
            )}
          </Box>
          {!isShortAppointment && <PatientAppointmentComponent />}
          {!isCanceled && appointment.notes && duration >= NOTES_DURATION_CUTOFF && view !== VIEW_NAME_WEEK && (
            <Box>
              <pre>{appointment.notes}</pre>
            </Box>
          )}
        </Box>
      </Tooltip>
    </Box>
  );
};

export const AppointmentCellComponent = ({ onClick, ...restProps }) => {
  const { data: appointmentData } = restProps;

  return (
    <Appointments.Appointment {...restProps} onClick={onClick}>
      <AppointmentCard appointmentData={appointmentData} />
    </Appointments.Appointment>
  );
};
const MedspaCalendarAppointments = ({ view }: { view: string }) => (
  <Appointments
    containerComponent={(props) => {
      const { children, style } = props;

      if (view === VIEW_NAME_WEEK) {
        const appointment = children[0]?.props?.params?.data;
        // Hidden to prevent styles errors, and present to be triggered by id
        return <Box id={`${appointment?.bookingType}-${appointment?.id}`}>{children}</Box>;
      }
      return (
        <Box
          style={{
            ...style,
            // TODO: ADD CONSTANT FOR DAY
            width: view === VIEW_NAME_DAY ? `calc(${style.width} - 5rem)` : style.width,
          }}
        >
          {children}
        </Box>
      );
    }}
    appointmentComponent={(props) => <AppointmentCellComponent {...props} />}
  />
);

export default MedspaCalendarAppointments;
