import React from 'react';
import { Skeleton } from '@material-ui/lab';

export const MultipleSkeleton = ({ length = 10, addPosition = true, width = '95%', margin = '20px' }) => {
  const header = [<Skeleton key={1} height={100} width="100%" />];
  return (
    <div style={{ margin, width, position: addPosition ? 'absolute' : 'initial' }}>
      {[header].concat(
        Array(length)
          .fill(1)
          .map((_) => <Skeleton key={`s-${_}-${Math.random()}`} height={40} width="100%" />)
      )}
    </div>
  );
};

export const ShortMultipleSkeleton = ({ length = 4 }) => (
  <div style={{ margin: '20px 0', width: '100%' }}>
    {Array(length)
      .fill(1)
      .map((_) => (
        <Skeleton key={`ss-${_}-${Math.random()}`} height={40} width="100%" />
      ))}
  </div>
);
