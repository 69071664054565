import { Box, CircularProgress, Divider, IconButton } from '@material-ui/core';
import React, { useState } from 'react';
import { EHRTypography } from 'src/components/ui/v1';
import { useDestroyResourcesFile, useMarkAsReadResourcesFile, useResourcesFiles } from 'src/hooks/useResourceFiles';
import { ResourceFile } from 'src/services/resources/resourcesFiles';
import TrashCan from 'src/components/common/icons/TrashCan';
import DocumentIcon from 'src/components/common/icons/DocumentIcon';
import EditIcon from 'src/components/common/icons/EditIcon';
import { hasAccessTo } from 'src/utils/auth.utils';
import { RESOURCE_CREATE, RESOURCE_DESTROY, RESOURCE_UPDATE } from 'src/constants/actions.constants';
import { useSelector } from 'react-redux';
import { UpsertResourcesFilesModal } from './UpsertResourcesFilesModal';
import { useResourcesStyles } from './resources.styles';
import { ResourcesToolbar } from '.';
import { DeleteResourceDialog } from './DeleteResourceDialog';

interface ResourcesTabProps {}

const CATEGORY_ID = 0;

const ResourcesTab = (_props: ResourcesTabProps) => {
  const permissions = useSelector(({ auth }: any) => auth.permissions);
  const classes = useResourcesStyles();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [selectedResource, setSelectedResource] = useState<ResourceFile | null>(null);
  const { resources, isLoading } = useResourcesFiles(CATEGORY_ID);
  const { mutate: deleteResource } = useDestroyResourcesFile();
  const { mutate: markAsReadResource } = useMarkAsReadResourcesFile();
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [resourceToDelete, setResourceToDelete] = useState<ResourceFile | null>(null);

  const groupedResources = React.useMemo(
    () =>
      resources.reduce((acc, resource) => {
        if (!acc[resource.section]) {
          acc[resource.section] = [];
        }
        acc[resource.section].push(resource);
        return acc;
      }, {} as Record<string, ResourceFile[]>),
    [resources]
  );

  if (isLoading) {
    return <CircularProgress />;
  }

  const handleResourceMarkAsRead = (resource: ResourceFile) => {
    if (resource.new) {
      markAsReadResource(resource.id);
    }
  };

  const handleDeleteResource = (resource: ResourceFile) => {
    setResourceToDelete(resource);
    setOpenDeleteDialog(true);
  };

  if (resources?.length === 0) {
    return (
      <Box className={classes.resourcesContainer}>
        {hasAccessTo(RESOURCE_CREATE, permissions) && (
          <ResourcesToolbar
            isLoading={isLoading}
            onClick={() => {
              setSelectedResource(null);
              setDialogOpen(true);
            }}
          />
        )}
        <Box className={classes.emptyState}>
          <h3>Welcome to Resources Tab</h3>
          <p>It looks like you haven&apos;t added any resources yet. </p>
          <p>
            Click on <strong>Add Content</strong> button above to add your first content.
          </p>
        </Box>
        <UpsertResourcesFilesModal onClose={() => setDialogOpen(false)} open={dialogOpen} />
      </Box>
    );
  }

  return (
    <Box className={classes.resourcesContainer}>
      {hasAccessTo(RESOURCE_CREATE, permissions) && (
        <ResourcesToolbar
          isLoading={isLoading}
          onClick={() => {
            setSelectedResource(null);
            setDialogOpen(true);
          }}
        />
      )}

      {Object.entries(groupedResources).map(([section, sectionResources]) => (
        <React.Fragment key={section}>
          <Box>
            <EHRTypography dataCy={`title-${section.toLowerCase()}`} variant="h6">
              {section}
            </EHRTypography>
          </Box>
          <Box className={classes.resourcesListContainer}>
            <EHRTypography dataCy={`title-${section.toLowerCase()}-filename`} variant="description">
              File Name
            </EHRTypography>
            {sectionResources.map((resource: ResourceFile, index) => (
              <>
                <Box
                  key={resource.id}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  onClick={() => handleResourceMarkAsRead(resource)}
                >
                  <Box display="flex" alignItems="center" gridGap="1rem">
                    <DocumentIcon />
                    <a href={resource.documentLink} target="_blank" rel="noreferrer" className={classes.resourceLink}>
                      <p>{resource.title}</p>
                    </a>
                    {!hasAccessTo(RESOURCE_CREATE, permissions) && resource.new && (
                      <Box className={classes.tag}>
                        <EHRTypography
                          variant="label"
                          dataCy={`resource-new-${resource.id}`}
                          style={{ color: '#6B4000' }}
                        >
                          NEW
                        </EHRTypography>
                      </Box>
                    )}
                  </Box>

                  <Box data-testid={`resource ${resource.id} action items`}>
                    {hasAccessTo(RESOURCE_UPDATE, permissions) && (
                      <IconButton
                        style={{ padding: '8px' }}
                        onClick={() => {
                          setSelectedResource(resource);
                          setDialogOpen(true);
                        }}
                        data-testid={`edit resource ${resource.id}`}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                    {hasAccessTo(RESOURCE_DESTROY, permissions) && (
                      <IconButton
                        style={{ padding: '8px' }}
                        onClick={() => handleDeleteResource(resource)}
                        data-testid={`delete resource ${resource.id}`}
                      >
                        <TrashCan />
                      </IconButton>
                    )}
                  </Box>
                </Box>
                {index < sectionResources.length - 1 && <Divider />}
              </>
            ))}
          </Box>
        </React.Fragment>
      ))}

      <UpsertResourcesFilesModal onClose={() => setDialogOpen(false)} open={dialogOpen} resource={selectedResource} />

      <DeleteResourceDialog
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(false);
          setResourceToDelete(null);
        }}
        onDelete={() => {
          if (resourceToDelete) {
            deleteResource(resourceToDelete.id);
          }
        }}
      />
    </Box>
  );
};

export default ResourcesTab;
