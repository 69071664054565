import React, { useState } from 'react';
import { PaymentForm, CreditCard } from 'react-square-web-payments-sdk';
import { CircularProgress } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useStyles } from './CreditCardForm.styles';
import { dispatch } from '../../../rematch';
import compile from '../../../utils/toastMessagesCompiler';

interface CreditCardFormProps {
  locationId: string;
  patientId: string;
  onCardAdded: (squareCreditCardId: string, cb: () => void) => void;
  buttonText?: string;
  loading?: boolean;
}

const CreditCardForm = ({ locationId, patientId, onCardAdded, buttonText, loading }: CreditCardFormProps) => {
  const classes = useStyles();
  const [processing, setProcessing] = useState<boolean>(false);

  const onSubmitForm = (tokenResult: any) => {
    setProcessing(true);
    if (tokenResult.status === 'OK') {
      const squareCreditCardNonce = tokenResult.token;
      dispatch({
        type: 'patient/addCustomerCreditCard',
        payload: {
          patientId,
          squareCreditCardNonce,
          successCallback: (data?: any) => {
            onCardAdded(data?.squareCreditCardId, () => setProcessing(false));
          },
          errorCallback: () => {
            setProcessing(false);
          },
        },
      });
    } else {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'saving',
            element: 'the credit card',
          }),
          type: 'error',
        },
      });
      setProcessing(false);
    }
  };

  const applicationId = process.env.REACT_APP_SQUARE_APPLICATION_ID || '';

  if (!applicationId || !locationId) {
    return <Skeleton height={150} />;
  }
  return (
    <div className="w-full mb-12">
      <div className={`flex ${classes.creditCardForm}`}>
        <PaymentForm applicationId={applicationId} locationId={locationId} cardTokenizeResponseReceived={onSubmitForm}>
          <CreditCard
            includeInputLabels
            buttonProps={{
              isLoading: processing || loading,
              css: {
                background: '#12574D',
                width: '300px',
              },
            }}
          >
            {processing || loading ? (
              <>
                Processing
                <CircularProgress size={20} className={classes.processing} />
              </>
            ) : (
              <>{buttonText || 'Add Card'}</>
            )}
          </CreditCard>
        </PaymentForm>
        {processing || (loading && <div className={classes.buttonOverlay} />)}
      </div>
    </div>
  );
};

export default CreditCardForm;
