import React, { useEffect, useCallback, useState } from 'react';
import { Box, Button, Dialog, Divider, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { xor } from 'lodash';
import { useStyles } from './modalPoints.styles';
import ModalTitles from './ModalTitles';
import ProductLocation from './ProductLocation';
import ConvertCertificate from './ConvertCertificate';
import CertificateConfirmationCode from './CertificateConfirmationCode';
import { dispatch } from '../../../rematch';
import { CertificationPoints } from '../../../types/PatientPointCertificatesState';
import { ModalPointsStateType } from '../../../constants/modalPoints.constants';
import PatientCertificatesList from './PatientCertificateList';
import PractitionerCertificatesList from './PractitionerCertificatesList';
import { getGaldermaDiscountByCredits, parseGaldermaProductsAndCertificates } from '../../../utils/galderma.utils';
import IServices from '../../../interfaces/IServices';
import { SQUARE_STATUS } from '../../../constants/square.constants';
import { CUSTOMER_ROLE, PRACTITIONER_ROLE } from '../../../constants/general.constants';

interface Props {
  open: boolean;
  closeCallback: () => void;
  state: ModalPointsStateType;
}

const ModalPoints = (props: Props) => {
  const classes = useStyles();
  const { open, closeCallback, state } = props;

  const [isGaldermaUserFetched, setIsGaldermaUserFetched] = useState<boolean>(false);
  const { totalServicesUnits, selectedServices, isLoading, isLoadingFetchCheckout, isLoadingProgressingSaving } =
    useSelector(({ newServiceVisit }: any) => newServiceVisit);
  const { services } = useSelector((store: any) => store.common);
  const { checkout } = useSelector((store: any) => store.newServiceVisit);
  const { galdermaEmail, id: patientId } = useSelector(({ patient }: any) => patient);
  const {
    practitionerCertificates,
    patientPoints,
    patientCertificates,
    isLoadingPatientCertificates,
    isLoadingPractitionerCertificates,
    galdermaProducts,
    isLoadingToggleCertificate,
    treatmentConfigured,
    toggleModal,
    skusSelected,
  } = useSelector(({ patientPointCertificates }: any) => patientPointCertificates);
  const [practitionerCertificatesSelected, setPractitionerCertificatesSelected] = useState<number[]>([]);
  const [patientCertificatesSelected, setPatientCertificatesSelected] = useState<number[]>([]);

  useEffect(() => {
    dispatch({ type: 'patientPointCertificates/fetchGaldermaProducts' });

    return () => {
      dispatch.patientPointCertificates.setTreatmentConfigured(false);
    };
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const configureTreatment = (showError: boolean = false) => {
    if (
      isLoading ||
      checkout.galdermaApplied ||
      checkout.transactionStatus === SQUARE_STATUS.OK ||
      !galdermaEmail ||
      isLoadingPatientCertificates ||
      isLoadingPractitionerCertificates ||
      isLoadingProgressingSaving ||
      isLoadingFetchCheckout ||
      !Object.entries(totalServicesUnits).length ||
      !services.length ||
      !galdermaProducts.length
    ) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const galdermaPayload = {
      customerId: patientId,
      ...parseGaldermaProductsAndCertificates({
        patientCertificates: [],
        practitionerCertificates: [],
        galdermaProducts,
        certificateIds: [],
        practitionerCertificateIds: [],
        totalServicesUnits,
        services: services.filter(({ id }: IServices) => selectedServices.includes(id)),
        skusSelected,
      }),
    };

    // if (galdermaPayload.products.length) {
    //   dispatch({
    //     type: 'patientPointCertificates/configureAndFetchCertificates',
    //     payload: { galdermaPayload, showError },
    //   });
    // }
  };

  const reloadPointsData = useCallback(() => {
    dispatch({ type: 'patientPointCertificates/fetchPatientPoints', payload: galdermaEmail });
    dispatch({ type: 'patientPointCertificates/setPatientPointsToRedeem', payload: 0 });
    dispatch({ type: 'patientPointCertificates/setPatientSMSCodeSent', payload: false });
  }, [galdermaEmail, totalServicesUnits, services, galdermaProducts]);

  // Reconfigure treatment if the selection changes
  useEffect(() => {
    if (Object.keys(skusSelected).length) {
      configureTreatment(toggleModal);
    }
  }, [skusSelected]);

  useEffect(() => {
    // Note: do not move configureTreatment inside of reloadPointsData
    // or will display the info message each time
    if (galdermaEmail && !treatmentConfigured) {
      if (!isGaldermaUserFetched) {
        setIsGaldermaUserFetched(true);
        reloadPointsData();
      }
      configureTreatment(toggleModal);
    }
  }, [
    patientId,
    galdermaEmail,
    galdermaProducts,
    totalServicesUnits,
    services,
    isLoading,
    checkout.galdermaApplied,
    checkout.transactionStatus,
    toggleModal,
    isLoadingProgressingSaving,
    isLoadingFetchCheckout,
  ]);

  const validCertificateCallback = (certificateId: number, type: string) => {
    // Once cert combination is approved it's added to the xxxCertificatesSelected
    if (type === PRACTITIONER_ROLE) {
      setPractitionerCertificatesSelected(xor(practitionerCertificatesSelected, [certificateId]));
    } else {
      setPatientCertificatesSelected(xor(patientCertificatesSelected, [certificateId]));
    }
  };

  const handleChangePatientCertificates = (certificateId: number) => {
    dispatch({
      type: 'patientPointCertificates/validateToggleCertificateChecked',
      payload: {
        certificateId,
        tempPatientCertsSelected: patientCertificatesSelected,
        tempPractitionerCertsSelected: practitionerCertificatesSelected,
        patientId,
        type: CUSTOMER_ROLE,
        successCallback: validCertificateCallback,
      },
    });
  };

  const handleChangePractitionerCertificates = (certificateId: number) => {
    dispatch({
      type: 'patientPointCertificates/validateToggleCertificateChecked',
      payload: {
        certificateId,
        tempPatientCertsSelected: patientCertificatesSelected,
        tempPractitionerCertsSelected: practitionerCertificatesSelected,
        patientId,
        currentCertificatesSelected: practitionerCertificatesSelected,
        type: PRACTITIONER_ROLE,
        successCallback: validCertificateCallback,
      },
    });
  };

  const handleClickConvertCertificate = (item: CertificationPoints) => {
    dispatch({ type: 'patientPointCertificates/setPatientPointsToRedeem', payload: item.points });
  };

  const confirm = () => {
    dispatch.patientPointCertificates.setPatientCertificateChecked(patientCertificatesSelected);
    dispatch.patientPointCertificates.setPractitionerCertificatesChecked(practitionerCertificatesSelected);
    // Set Galderma Discount to state
    dispatch({ type: 'patientPointCertificates/setConfirmSelectedCertificates', payload: true });
    closeCallback();
  };

  const galdermaPatientAmount = getGaldermaDiscountByCredits(patientCertificatesSelected, patientCertificates);
  const galdermaPractitionerAmount = getGaldermaDiscountByCredits(
    practitionerCertificatesSelected,
    practitionerCertificates
  );

  return (
    <Dialog open={open} onClose={closeCallback} style={{ borderRadius: '9px' }}>
      <Box>
        <Box display="flex" mt="16px" mr="16px">
          <Box marginLeft="auto">
            <IconButton onClick={closeCallback}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Divider />
        <Box className={classes.container}>
          <Box mb="22px">
            <ModalTitles title="Treatment Location" subtitle="Select product and treatment location" />
            <Box my={3}>
              <ProductLocation />
            </Box>
          </Box>
        </Box>
        <Box className={classes.container}>
          <Box mb="22px">
            <ModalTitles title="Patient Points and Certificates" subtitle="Redeem these points for a certificate" />
            <Box my={3}>
              <Box>
                <Box>
                  {patientPoints > 0 ? (
                    <Box mb={2} display="flex">
                      <ModalTitles
                        title={`This patient has ${patientPoints} points = $${(patientPoints * 0.1).toFixed(0)}`}
                        subtitle="An SMS will be sent to the patient to confirm the use of these points"
                        smallTitle
                      />
                      <ConvertCertificate handleClick={handleClickConvertCertificate} />
                    </Box>
                  ) : (
                    <ModalTitles title="This patient has 0 points = $0" smallTitle />
                  )}
                </Box>
                <CertificateConfirmationCode
                  configureTreatment={configureTreatment}
                  reloadPointsData={reloadPointsData}
                />
                <Box>
                  <Box component="p" color="#393B3F" fontSize="16px" fontFamily="Messina Sans Regular">
                    Patient certificates available to apply discounts:
                  </Box>
                  <Box component="p" color="#393B3F" fontSize="14px" fontFamily="Messina Sans Regular">
                    <i>
                      These are all the certificates that are valid to redeem for this treatment. If you wish to see all
                      available certificates for this patient, please check their patient profile
                    </i>
                  </Box>
                  <PatientCertificatesList
                    patientCertificatesSelected={patientCertificatesSelected}
                    onChange={handleChangePatientCertificates}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box className={classes.container}>
        <Box mb="22px">
          <ModalTitles
            title="Provider promotion certificates"
            subtitle="Apply these certificates to your patient"
            boxContainerStyles={{ marginTop: 3 }}
          />
          <Box my={3}>
            {state === ModalPointsStateType.NO_POINTS_AVAILABLE && (
              <ModalTitles title="No promotions or certificates are available" smallTitle />
            )}
            <Box>
              <PractitionerCertificatesList
                practitionerCertificatesSelected={practitionerCertificatesSelected}
                onChange={handleChangePractitionerCertificates}
              />
              <Box my={4} textAlign="center" bgcolor="#E7EEED" paddingY={2} borderRadius="6px">
                <Box fontSize="21px" fontFamily="Messina Sans Bold" color="#393B3F">
                  Total discount: ${(galdermaPatientAmount + galdermaPractitionerAmount).toFixed(2)}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box pt={1} textAlign="center">
          <Box>
            <Button variant="outlined" color="primary" style={{ marginRight: '1rem' }} onClick={closeCallback}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" disabled={isLoadingToggleCertificate} onClick={confirm}>
              Confirm
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ModalPoints;
