import { createModel } from '@rematch/core';
import { queryClient } from 'src/initializers/queryClient';
import { AuthState } from '../types/AuthState';
import axiosInstance from '../utils/axios';
import { RootModel } from './rootModel';
import { isSessionExpired } from '../utils/auth.utils';
import apiRoutes from '../constants/apiRoutes.constants';
import { showGlobalErrorSnackbar } from '../utils/global';
import { IFeatureSetting } from '../interfaces/IFeatureSetting';

export const auth = createModel<RootModel>()({
  state: {
    userType: localStorage.getItem('identity.userType') || null,
    userGroupId: localStorage.getItem('identity.userGroupId') || null,
    userGroupName: localStorage.getItem('identity.userGroupName') || null,
    linkedAccounts:
      !!localStorage.getItem('identity.token.linkedAccounts') &&
      localStorage.getItem('identity.token.linkedAccounts') !== 'undefined' &&
      localStorage.getItem('identity.token.linkedAccounts') !== '[object Object]'
        ? JSON.parse(localStorage.getItem('identity.token.linkedAccounts') || '[]')
        : [],
    isSuccess: null,
    isFirstLogin: null,
    isAuthenticate: !!localStorage.getItem('identity.token.access-token'),
    messageError: '',
    name: '',
    firstName: '',
    lastName: '',
    id: null,
    userId: null,
    email: '',
    permissions: [],
    featureList: [],
    featureSettings: [],
    user: {},
    isLoading: !!localStorage.getItem('identity.token.access-token'),
    practitionerInformation: {
      dashboardLink: null,
      socialMediaReviewsInfo: null,
    },
    setFirstPasswordSuccess: false,
    roleId: null,
    roleName: null,
    onboardingEnabled: false,
    paymentProcessor: '',
  } as AuthState,
  reducers: {
    updateState(state: any, payload: any) {
      return { ...state, ...payload };
    },
  },
  effects: (dispatch: any) => ({
    async setPassword(body: { password: string; token: string }) {
      dispatch.auth.updateState({ isLoading: true });
      try {
        const response = await axiosInstance.post(apiRoutes.PRACTITIONER_SET_PASSWORD, body);
        if (!response.data.success) {
          showGlobalErrorSnackbar({ params: { action: 'Seting', element: 'the password' } });
          dispatch.auth.updateState({ isLoading: false });
          return;
        }

        dispatch.auth.updateState({ setFirstPasswordSuccess: true, isLoading: false });
      } catch (error) {
        dispatch.auth.updateState({ isLoading: false });
      }
    },
    async login(payload: { email: string; password: string }) {
      const { email, password } = payload;

      try {
        localStorage.clear();
        sessionStorage.clear();
        const response = await axiosInstance.post('/identities/sign-in', {
          provider: 'email',
          email,
          password,
        });

        if (response.status !== 200) {
          throw new Error(response.statusText);
        }

        if (response.data.error) {
          dispatch.auth.updateState({ isSuccess: false, messageError: response.data.error });
          return;
        }

        localStorage.setItem('identity.token.access-token', response.data.meta.token.accessToken);
        localStorage.setItem('identity.token.token-type', response.data.meta.token.tokenType);
        localStorage.setItem('identity.token.client', response.data.meta.token.client);
        localStorage.setItem('identity.token.uid', response.data.meta.token.uid);
        localStorage.setItem('identity.token.expiry', response.data.meta.token.expiry);
        localStorage.setItem('identity.token.userGroupId', response.data.identity.userGroupId);
        localStorage.setItem('identity.token.userGroupName', response.data.identity.userGroupName);
        localStorage.setItem('identity.userType', response.data.identity.userType);
        localStorage.setItem('identity.token.roleName', response.data.identity.roleName);
        localStorage.setItem('identity.token.linkedAccounts', JSON.stringify(response.data.identity.linkedAccounts));

        sessionStorage.setItem('identity.token.uid', response.data.meta.token.uid);
        sessionStorage.setItem('identity.roleId', response.data.identity.roleId);

        dispatch.auth.updateState({
          isAuthenticate: true,
          isSuccess: true,
          userType: response.data.identity.userType,
          permissions: response.data.identity.permissions,
          featureList: response.data.identity.featureList,
          featureSettings: response.data.identity.featureSettings,
          name: `${response.data.identity.firstName} ${response.data.identity.lastName}`,
          firstName: response.data.identity.firstName,
          lastName: response.data.identity.lastName,
          id: response.data.identity.id,
          userId: response.data.identity.user?.id || response.data.identity.userId,
          userGroupId: response.data.identity.userGroupId,
          userGroupName: response.data.identity.userGroupName,
          email: response.data.identity.email,
          practitionerInformation: {
            dashboardLink: response.data.identity?.practitionerInformation?.dashboardLink,
            socialMediaReviewsInfo: response.data.identity?.practitionerInformation?.socialMediaReviewsInfo,
          },
          paymentProcessor: response.data.identity.paymentProcessor,
          roleId: response.data.identity.roleId,
          roleName: response.data.identity.roleName,
          linkedAccounts: response.data.identity.linkedAccounts,
          isFirstLogin: response.data.identity.isFirstLogin,
          onboardingEnabled: response.data.identity.onboardingEnabled,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
    loginFromCreatePassword(payload: { data: any }) {
      localStorage.setItem('identity.token.access-token', payload.data.meta.token.accessToken);
      localStorage.setItem('identity.token.token-type', payload.data.meta.token.tokenType);
      localStorage.setItem('identity.token.client', payload.data.meta.token.client);
      localStorage.setItem('identity.token.uid', payload.data.meta.token.uid);
      localStorage.setItem('identity.token.expiry', payload.data.meta.token.expiry);
      localStorage.setItem('identity.userType', payload.data.identity.userType);
      localStorage.setItem('identity.token.linkedAccounts', JSON.stringify(payload.data.identity.linkedAccounts));

      sessionStorage.setItem('identity.token.uid', payload.data.meta.token.uid);
      sessionStorage.setItem('identity.roleId', payload.data.identity.roleId);
      sessionStorage.setItem('identity.roleName', payload.data.identity.roleName);

      dispatch.auth.updateState({
        isAuthenticate: true,
        isSuccess: true,
        userType: payload.data.identity.userType,
        permissions: payload.data.identity.permissions,
        featureList: payload.data.identity.featureList,
        featureSettings: payload.data.identity.featureSettings,
        name: `${payload.data.identity.firstName} ${payload.data.identity.lastName}`,
        firstName: payload.data.identity.firstName,
        lastName: payload.data.identity.lastName,
        id: payload.data.identity.id,
        userId: payload.data.identity.userId,
        userGroupId: payload.data.identity.userGroupId,
        userGroupName: payload.data.identity.userGroupName,
        email: payload.data.identity.email,
        practitionerInformation: {
          dashboardLink: payload.data.identity?.practitionerInformation?.dashboardLink,
          socialMediaReviewsInfo: payload.data.identity?.practitionerInformation?.socialMediaReviewsInfo,
        },
        paymentProcessor: payload.data.identity.paymentProcessor,
        roleId: payload.data.identity.roleId,
        roleName: payload.data.identity.roleName,
        linkedAccounts: payload.data.identity.linkedAccounts,
      });
    },
    loginFromOldEHR(payload: { data: any }) {
      const { data } = payload;
      localStorage.setItem('identity.token.access-token', data.at);
      localStorage.setItem('identity.token.token-type', data.tt);
      localStorage.setItem('identity.token.client', data.c);
      localStorage.setItem('identity.token.uid', data.u);
      localStorage.setItem('identity.token.expiry', data.e);
      localStorage.setItem('identity.userType', data.ea);

      sessionStorage.setItem('identity.token.uid', data.u);

      dispatch.auth.updateState({
        isAuthenticate: true,
        isSuccess: true,
        userType: data.userType,
        permissions: data.permissions,
        featureList: data.featureList,
        featureSettings: data.featureSettings,
        name: `${data.firstName} ${data.lastName}`,
        firstName: data.identity.firstName,
        lastName: data.identity.lastName,
        id: data.id,
        userId: data.user.id,
        userGroupId: data.userGroupId,
        email: data.email,
        practitionerInformation: {
          dashboardLink: data?.practitionerInformation?.dashboardLink,
          socialMediaReviewsInfo: data?.practitionerInformation?.socialMediaReviewsInfo,
        },
        paymentProcessor: data.paymentProcessor,
      });
    },
    async updateFeatures(payload: { newFeatures: IFeatureSetting[] }) {
      const { newFeatures } = payload;
      dispatch.auth.updateState({ featureList: newFeatures });
    },
    async logout() {
      localStorage.clear();
      sessionStorage.clear();
      queryClient.clear();
      sessionStorage.setItem('ehr_loggedOut', '1');
      dispatch.auth.updateState({ isAuthenticate: false, isSuccess: null });
    },
    async currentUser() {
      try {
        dispatch.auth.updateState({ isLoading: true });

        if (isSessionExpired()) {
          localStorage.clear();
          sessionStorage.clear();
          dispatch.auth.updateState({ isAuthenticate: false, isSuccess: null, isLoading: false });
          return;
        }

        const requestHeaders: any = {
          'access-token': localStorage.getItem('identity.token.access-token'),
          'token-type': localStorage.getItem('identity.token.token-type'),
          client: localStorage.getItem('identity.token.client'),
          uid: localStorage.getItem('identity.token.uid'),
          expiry: localStorage.getItem('identity.token.expiry'),
        };
        if (sessionStorage.getItem('identity.token.uid')) {
          requestHeaders['uid-session'] = sessionStorage.getItem('identity.token.uid');
        }
        const masqueradeFromIdentity = JSON.parse(localStorage.getItem('masqueradeFromIdentity') || '{}');
        if (masqueradeFromIdentity?.accessToken) {
          requestHeaders['from-masquerade-access-token'] = masqueradeFromIdentity.accessToken;
          requestHeaders['from-masquerade-token-type'] = masqueradeFromIdentity.tokenType;
          requestHeaders['from-masquerade-client'] = masqueradeFromIdentity.client;
          requestHeaders['from-masquerade-uid'] = masqueradeFromIdentity.uid;
          requestHeaders['from-masquerade-expiry'] = masqueradeFromIdentity.expiry;
        }

        const response = await axiosInstance.get(`${process.env.REACT_APP_API_RAILS_URL}/identities/current`, {
          headers: requestHeaders,
        });

        if (response.data.error) {
          dispatch.auth.updateState({ isAuthenticate: false });
          return;
        }

        if (response.status !== 200) {
          throw new Error(response.statusText);
        }

        dispatch.auth.updateState({
          isAuthenticate: true,
          isSuccess: true,
          isLoading: false,
          userType: response.data.identity.userType,
          userGroupId: response.data.identity.userGroupId,
          userGroupName: response.data.identity.userGroupName,
          permissions: response.data.identity.permissions,
          featureList: response.data.identity.featureList,
          featureSettings: response.data.identity.featureSettings,
          name: `${response.data.identity.firstName} ${response.data.identity.lastName}`,
          firstName: response.data.identity.firstName,
          lastName: response.data.identity.lastName,
          id: response.data.identity.id,
          userId: response.data.identity.user?.id || response.data.identity.userId,
          email: response.data.identity.email,
          user: response.data.identity.user,
          practitionerInformation: {
            dashboardLink: response.data.identity?.practitionerInformation?.dashboardLink,
            socialMediaReviewsInfo: response.data.identity?.practitionerInformation?.socialMediaReviewsInfo,
          },
          paymentProcessor: response.data.identity.paymentProcessor,
          roleId: response.data.identity.roleId,
          roleName: response.data.identity.roleName,
          onboardingEnabled: response.data.identity.onboardingEnabled,
          linkedAccounts: response.data.identity.linkedAccounts,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        dispatch.auth.updateState({ isLoading: false });
      }
    },
  }),
});
