import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppBar } from '@material-ui/core';
import MedspaAdminInventory from 'src/components/DashboardMedspaAdmin/Inventory';
import { useSelector } from 'react-redux';
import { RootState } from 'src/rematch';
import { PORTRAIT_ASCEND } from 'src/constants/general.constants';
import { TabCustom } from './TodoListTabs/tabGreen.styles';
import OrderDashboard from './OrderingTab';
import { useStyles } from './tabs.styles';
import { PRACTITIONER_BASE_PATH } from '../../../routes/practitionerRoutes';
import { a11yProps, InventoryTabCustom, TabPanel } from './InventoryTab';

const tabsObj = new Map([
  ['new-order', 0],
  ['orders', 1],
]);

const InventoryTabNonLegacy = ({ state }: { state: string }) => {
  const history = useHistory();
  const location = useLocation();
  const [value, setValue] = React.useState(tabsObj.get(state) || 0);
  const { roleName } = useSelector(({ auth }: RootState) => auth);
  const classes = useStyles();

  useEffect(() => {
    const urlTab = location.pathname.split('/').slice(-1)[0];
    setValue(tabsObj.get(urlTab) || 0);
  }, [location.pathname]);

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    const values = Array.from(tabsObj.keys());
    history.push(`${PRACTITIONER_BASE_PATH}/ordering/${values[newValue]}`);
    setValue(newValue);
  };

  const isAscend = roleName === PORTRAIT_ASCEND;

  return isAscend ? (
    <MedspaAdminInventory />
  ) : (
    <>
      <AppBar position="static">
        <InventoryTabCustom value={value} onChange={handleChange} aria-label="simple tabs example">
          <TabCustom
            {...a11yProps(0)}
            label={
              <div className={classes.inventoryTab} data-testid="newOrderTab" data-cy="newOrderTab">
                Place an Order
              </div>
            }
          />
          <TabCustom
            {...a11yProps(1)}
            label={
              <div className={classes.inventoryTab} data-cy="orderHistoryTab">
                Order History
              </div>
            }
          />
        </InventoryTabCustom>
      </AppBar>
      <TabPanel value={value} index={0}>
        <OrderDashboard />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MedspaAdminInventory />
      </TabPanel>
    </>
  );
};

export default InventoryTabNonLegacy;
