import React, { useState } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { useStyles } from './CreditCardCard.styles';
import { DeleteCreditCardModal } from './DeleteCreditCardModal';
import { dispatch } from '../../../rematch';
import CreditCardIcon from '../../../components/PatientProfile/CreditCards/CreditCardIcon';
import { ISquareCard } from '../../../interfaces/ISquareCard.interfaces';
import ISquareSubscription from '../../../interfaces/ISquareSubscription';
import { SubscriptionCardWarningModal } from './SubscriptionCardWarningModal';
import { BEAUTY_BANK_SUBSCRIPTION_STATUS } from '../../../constants/beautyBank.constants';

interface CreditCardCardProps {
  card: ISquareCard;
  patientId: string;
  onCardRemoved: () => void;
  handleMakeCardDefault: (cb: () => void) => void;
  isDefault: boolean;
  subscription?: ISquareSubscription;
}

export const CreditCardCard = ({
  card,
  patientId,
  onCardRemoved,
  isDefault,
  handleMakeCardDefault,
  subscription,
}: CreditCardCardProps) => {
  const classes = useStyles();
  const [openDeleteCardModal, setOpenDeleteCardModal] = useState<boolean>(false);
  const [openSubscriptionWarningModal, setOpenSubscriptionWarningModal] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const onMakeDefault = () => {
    setLoading(true);
    dispatch({
      type: 'patient/setCustomerDefaultCreditCard',
      payload: {
        patientId,
        creditCardId: card.id,
        successCallback: () => {
          handleMakeCardDefault(() => setLoading(false));
        },
      },
    });
  };

  const handleRemoveCard = () => {
    setDeleting(true);
    onCardRemoved();
  };

  const handleOpenDeleteCardModal = () => {
    if (
      subscription &&
      subscription?.status === BEAUTY_BANK_SUBSCRIPTION_STATUS.ACTIVE &&
      subscription?.cardId === card.id
    ) {
      setOpenSubscriptionWarningModal(true);
      setOpenDeleteCardModal(true);
    } else {
      setOpenDeleteCardModal(true);
    }
  };

  return (
    <div className={classes.cardContainer}>
      <div className={classes.cardInfo}>
        <div>
          <h3 className={classes.cardNumber}>xxxx xxxx xxxx {card.last4}</h3>
          <p>
            Exp. {card.expMonth}/{card.expYear}
          </p>
        </div>
        <div className={classes.textRight}>
          <CreditCardIcon cardBrand={card.cardBrand} />
          {isDefault && <div className={classes.cardDefault}>Default</div>}
        </div>
      </div>
      <div className={classes.cardActions}>
        <Button className={classes.cardButton} onClick={handleOpenDeleteCardModal}>
          {deleting ? <CircularProgress size={20} /> : 'Delete'}
        </Button>
        <Button className={classes.cardButton} disabled={isDefault || loading} onClick={onMakeDefault}>
          {loading ? <CircularProgress size={20} /> : 'Make Default'}
        </Button>
      </div>
      <DeleteCreditCardModal
        open={openDeleteCardModal}
        handleCloseCallback={() => setOpenDeleteCardModal(false)}
        creditCardId={card.id}
        patientId={patientId}
        onCardRemoved={handleRemoveCard}
      />
      <SubscriptionCardWarningModal
        open={openSubscriptionWarningModal}
        handleCloseCallback={() => setOpenSubscriptionWarningModal(false)}
        patientId={patientId}
      />
    </div>
  );
};
