/* eslint-disable @typescript-eslint/indent */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Big from 'big.js';

import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { SQUARE_STATUS } from 'src/constants/square.constants';
import { useStyles } from './containerCheckout.style';
import { dispatch } from '../../rematch';
import { calculateDiscount, checkoutInfo, formatNumber, getDataParameter } from '../../utils/checkout.utils';
import { CHECKOUT_ACCOUNT_CREDIT_ERROR } from '../../constants/checkout.constants';
import { MultipleSkeleton } from '../../components/common/LoadingSkeleton';
import { CheckoutHeader } from './CheckoutHeader';
import { OtherTenders } from './OtherTenders/OtherTenders';
import ReferralsSelector from '../../components/PatientProfile/Referrals/ReferralsSelector';
import LineItemTable from './LineItems/LineItemsTable';
import { DiscountsSubTotal } from './DiscountsSubTotal/DiscountsSubTotal';
import { RemainingToPay } from './DiscountsSubTotal/RemainingToPay';
import { Footer } from './Footer/Footer';
import FutureCredits from './FutureCredits';
import { AddNote } from './AddNote/AddNote';
import IServices from '../../interfaces/IServices';
import { parseGaldermaProductsAndCertificates } from '../../utils/galderma.utils';
import { PatientPointCertificatesState } from '../../types/PatientPointCertificatesState';
import { ModalPointsStateType } from '../../constants/modalPoints.constants';
import ModalPoints from './ModalPoints';

import compile from '../../utils/toastMessagesCompiler';
import { AccountCredit } from './OtherTenders/AccountCredit';

import IVariant, { IVariantItem } from '../../interfaces/IVariants';
import ConfirmModal from '../../components/common/ConfirmModal';
import SaveProgressWrapper from '../../components/SaveProgressWrapper';
import { CheckoutLineItem } from '../../types/CheckoutLineItem';
import IServiceGroup from '../../interfaces/IServiceGroup';
import { AccountCreditErrorModal } from './AccountCreditErrorModal';
import { ACCOUNT_CHARGE_VISIT_TYPE } from '../../services/ServiceVisit';
import { CHECKOUT } from '../../constants/reactQuery.keys';
import Checkout from '../../services/Checkout';
import ProccessingPaymentInSquareModal from './ProccessingPaymentInSquareModal/ProccessingPaymentInSquareModal';
import { hasAccessTo } from '../../utils/auth.utils';
import { READ_REFERRAL } from '../../constants/actions.constants';

const IS_PROD = process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION';

export default function ContainerCheckout({
  match: {
    params: { patientId, serviceVisitId },
  },
}: any) {
  const classes = useStyles();
  const history = useHistory();
  const { effects } = useSelector((store: any) => store.loading);
  const { services } = useSelector((store: any) => store.common);
  const { serviceGroups, galdermaEmail } = useSelector(({ patient }: any) => patient);
  const patientPointCertificates = useSelector((state: any) => state.patientPointCertificates);
  const newServiceVisit = useSelector((state: any) => state.newServiceVisit);
  const {
    servicesUnits,
    creditServicesUnits,
    variantsUnits,
    creditVariantsUnits,
    totalServicesUnits,
    checkout,
    isLoading,
    futureCreditUsed,
    futureVariantCreditUsed,
    selectedServices,
    isLoadingFetchCheckout,
    isLoadingGetServiceVisitFromPatient,
    isLoadingProgressingSaving,
    productsSelected,
    serviceVisit,
  } = newServiceVisit;

  const {
    servicesDiscounts,
    variantsDiscounts,
    taxes,
    tips,
    referralsChunkIndex,
    redeemableIds,
    checkoutLineItems,
    transactionStatus,
    squareErrorCode,
  } = checkout;

  const {
    patientCertificates,
    practitionerCertificates,
    patientCertificatesSelected,
    practitionerCertificatesSelected,
    galdermaApplicableProducts,
    skusSelected,
  } = patientPointCertificates as PatientPointCertificatesState;
  const { id, currentBalance } = useSelector(({ patient }: any) => patient);
  const searchParams = new URLSearchParams(useLocation().search);
  const [note, setNote] = useState<string>('');
  const [transactionInProgress, setTransactionInProgress] = useState<boolean>(false);
  const processingPaymentIntervalRef = useRef<number | undefined>();
  const [tenders, setTenders] = useState<any>({});

  const [accountCreditUsed, setAccountCreditUsed] = useState<number | string>('');

  const { isLoading: isLoadingPermissions, permissions } = useSelector(({ auth }: any) => auth);

  const [referralsIds, setReferralsIds] = useState<number[]>([]);
  const [isReceivingPayment, setIsReceivingPayment] = useState<boolean>(false);
  const [selectedReferralRedeemedIndexes, setSelectedReferralRedeemedIndexes] = useState<number[]>();
  const [accountCreditErrorModalOpen, setAccountCreditErrorModalOpen] = useState<boolean>(false);
  const {
    toggleModal,
    isLoadingProducts: isLoadingGaldermaProducts,
    isLoadingPatientCertificates,
  } = useSelector((state: any) => state.patientPointCertificates);
  const [showGaldermaBox, setShowGaldermaBox] = useState<boolean>(false);

  const [openPayStagingModal, setOpenPayStagingModal] = useState<boolean>(false);

  const [accountChargeLineItems, setAccountChargeLineItems] = useState<CheckoutLineItem[]>([]);
  const [proccessingPaymentInSquare, setProccessingPaymentInSquare] = useState<boolean>(false);

  const { data } = useQuery([CHECKOUT, serviceVisitId], () => Checkout.getCheckout(serviceVisitId));

  const isDataCompletelyLoaded = !(
    isLoading ||
    isLoadingFetchCheckout ||
    !newServiceVisit.serviceVisit.id ||
    isLoadingGetServiceVisitFromPatient ||
    isLoadingProgressingSaving ||
    transactionInProgress ||
    isLoadingGaldermaProducts ||
    isLoadingPatientCertificates ||
    !services.length
  );

  const clearProcessingPaymentIntervalRef = () => {
    clearInterval(processingPaymentIntervalRef.current);
    processingPaymentIntervalRef.current = undefined;
  };

  useEffect(() => {
    dispatch({
      type: 'common/fetchServices',
      payload: { contextServiceVisitId: serviceVisitId },
    });

    dispatch({
      type: 'newServiceVisit/fetchCheckout',
      payload: {
        serviceVisitId,
        successCallback: (result: boolean) => {
          setTransactionInProgress(result);
        },
      },
    });
    dispatch.patient.fetchPatientData(patientId);
    dispatch.newServiceVisit.fetchDiscounts();

    window.document.onvisibilitychange = () => {
      if (document.visibilityState === 'visible' && localStorage.getItem('squareTransaction')) {
        localStorage.removeItem('squareTransaction');
        window.location.reload();
      }
    };

    return () => {
      dispatch({ type: 'newServiceVisit/cleanServiceVisitData' });
      window.document.onvisibilitychange = null;
      clearProcessingPaymentIntervalRef();
    };
  }, []);

  useEffect(() => {
    if (patientId) {
      if (hasAccessTo(READ_REFERRAL, permissions) && !isLoadingPermissions) {
        dispatch({ type: 'referrals/getRedeemedCustomers', payload: { customerId: patientId } });
      }
      dispatch.patient.fetchServiceGroups();
    }
  }, [patientId, isLoadingPermissions]);

  useEffect(() => {
    if (id) {
      dispatch({
        type: 'newServiceVisit/getServiceVisitFromPatient',
        payload: { serviceVisitId: +serviceVisitId, currentPatientId: id },
      });
    }
  }, [id]);

  useEffect(() => {
    setNote(checkout.note);
  }, [checkout.note]);

  const checkoutInfoHash = useMemo(
    () =>
      checkoutInfo({
        services,
        serviceVisit: newServiceVisit,
        tenders,
        accountCreditUsed,
        accountChargeLineItems,
        referralsIds,
      }),
    [services, newServiceVisit, tenders, accountCreditUsed, accountChargeLineItems, referralsIds]
  );

  const { total, toPay, subTotal, totalDiscounts, otherTendersTotal, getAccountCreditUsed, getReferralTotal } =
    checkoutInfoHash;

  useEffect(() => {
    if (checkout.accountCreditUsed) {
      // In the case when account credit was used to pay in full for a checkout with retail services but they then
      // remove those services (since they cannot use account credit on them) we should update the account credit used
      // accordingly.
      const priceTotal = total;
      if (priceTotal > 0 && checkout.accountCreditUsed > priceTotal) {
        setAccountCreditUsed(priceTotal);
      } else {
        setAccountCreditUsed(checkout.accountCreditUsed);
      }
    }
  }, [checkout.accountCreditUsed, servicesUnits]);

  useEffect(() => {
    setReferralsIds(redeemableIds);
  }, [redeemableIds]);

  useEffect(() => {
    setSelectedReferralRedeemedIndexes(referralsChunkIndex);
  }, [referralsChunkIndex]);

  useEffect(() => {
    if (transactionStatus === SQUARE_STATUS.OK) {
      clearProcessingPaymentIntervalRef();
      setTransactionInProgress(false);
    }
  }, [transactionStatus]);

  useEffect(() => {
    if (
      !isLoading &&
      transactionStatus !== SQUARE_STATUS.OK &&
      futureCreditUsed.length &&
      futureCreditUsed.some(({ used }: any) => used) &&
      futureVariantCreditUsed.length &&
      futureVariantCreditUsed.some(({ used }: any) => used) &&
      Object.entries(servicesDiscounts).length &&
      Object.entries(variantsDiscounts).length
    ) {
      futureCreditUsed.forEach(({ serviceId }: any) => {
        dispatch({
          type: 'newServiceVisit/clearDiscountByServiceId',
          payload: serviceId,
        });
      });
      futureVariantCreditUsed.forEach(({ variantItemId }: any) => {
        dispatch({
          type: 'newServiceVisit/clearDiscountByvariantItemId',
          payload: variantItemId,
        });
      });

      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('checkout.discounts_removed_future_credit'),
          type: 'info',
          duration: 10000,
        },
      });
    }
  }, [isLoading, transactionStatus, futureCreditUsed, futureVariantCreditUsed]);

  const serviceGroupIdMap = useMemo(() => {
    if (serviceGroups.length) {
      return serviceGroups.reduce((obj: any, serviceGroup: IServiceGroup) =>
        Object.assign(
          obj,
          {
            [serviceGroup.id]: serviceGroup,
          },
          {}
        )
      );
    }
    return null;
  }, [serviceGroups]);

  const serviceUsingDynamicPricingMap = useMemo(() => {
    if (services.length && serviceGroups.length && serviceGroupIdMap) {
      return services.reduce((obj: any, service: IServices) =>
        Object.assign(
          obj,
          {
            [service.id]: serviceGroupIdMap[service.serviceGroupId]?.useDynamicPricing,
          },
          {}
        )
      );
    }
    return null;
  }, [services, serviceGroups, serviceGroupIdMap]);

  useEffect(() => {
    if (checkoutLineItems?.length && serviceUsingDynamicPricingMap) {
      setAccountChargeLineItems(
        checkoutLineItems.filter((lineItem: CheckoutLineItem) => serviceUsingDynamicPricingMap[lineItem.serviceId])
      );
    }
  }, [checkoutLineItems, serviceUsingDynamicPricingMap]);

  const hasCurrentServiceUnits = Object.values(totalServicesUnits).some((value: any) => +value > 0);

  const isAccountChargeServiceVisit = useMemo(
    () => serviceVisit?.visitType === ACCOUNT_CHARGE_VISIT_TYPE,
    [serviceVisit]
  );

  const payWithCardForCharge = useMemo(() => {
    const squareCardId = searchParams.get('squareCardId');
    return isAccountChargeServiceVisit && squareCardId ? squareCardId : null;
  }, [isAccountChargeServiceVisit, searchParams]);

  const openSquarePOS = (): void => {
    const amount = `${Math.round(toPay * 100)}`;
    const state: string = JSON.stringify({ serviceVisitId });
    const ids = `${patientId}-${serviceVisitId}`;
    const squareNotes = note ? `${ids} - ${note}` : ids;
    const dataParameter = getDataParameter(amount, squareNotes, state);

    window.location.href = `square-commerce-v1://payment/create?data=${encodeURIComponent(
      JSON.stringify(dataParameter)
    )}`;
    setTransactionInProgress(true);
    setProccessingPaymentInSquare(true);
  };

  const unscannedProducts = (): any[] =>
    productsSelected
      .filter((product: any) => !product.untracked && !product.assets.length)
      .map((product: any) => product.name);

  const handleCheckoutError = (error: string | undefined) => {
    if (error?.includes(CHECKOUT_ACCOUNT_CREDIT_ERROR)) {
      setAccountCreditErrorModalOpen(true);
    }
    setIsReceivingPayment(false);
    clearProcessingPaymentIntervalRef();
  };

  const navigateToCheckoutWithCard = () => {
    history.push(`/patient/${patientId}/serviceVisit/${serviceVisitId}/checkoutWithCard`);
  };

  const afterCheckoutCallback = (processingPayment: boolean) => {
    if (processingPayment) {
      setIsReceivingPayment(true);
      const interval = setInterval(() => {
        dispatch({ type: 'newServiceVisit/fetchCheckout', payload: { serviceVisitId, interval: true } });
        if (hasAccessTo(READ_REFERRAL, permissions)) {
          dispatch({ type: 'referrals/getRedeemedCustomers', payload: { customerId: patientId } });
        }
      }, 5000);
      processingPaymentIntervalRef.current = interval as unknown as number;
      if (!isAccountChargeServiceVisit) {
        return IS_PROD ? openSquarePOS : () => setOpenPayStagingModal(true);
      }
      return null;
    }
    return navigateToCheckoutWithCard;
  };

  const initiateTransaction = ({ processingPayment = true }: { processingPayment: boolean }): void => {
    const missingScannedServices = unscannedProducts();
    if (missingScannedServices.length) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'please re scan the following services:',
            element: missingScannedServices.join(', '),
          }),
          type: 'error',
        },
      });
      return;
    }

    const checkoutObj: any = {};
    checkoutObj.patientId = patientId;
    checkoutObj.note = note;
    checkoutObj.subTotal = Number(new Big(+subTotal));
    checkoutObj.taxes = taxes;
    checkoutObj.total = Number(new Big(+total));
    checkoutObj.accountCreditUsed = Number(new Big(+getAccountCreditUsed));
    checkoutObj.discounts = Number(new Big(+totalDiscounts));
    checkoutObj.otherTendersTotal = Number(new Big(+(otherTendersTotal + getReferralTotal)));
    checkoutObj.toPay = Number(new Big(+toPay).toString());

    checkoutObj.tenders = tenders;
    if (referralsIds.length) {
      checkoutObj.tenders.referral = {
        type: 'referral',
        voucher: '',
        value: getReferralTotal,
      };
    }

    const lineItems: any = [];
    Object.entries(servicesUnits).forEach(([serviceId, unit]: any) => {
      const lineItem: any = {};
      lineItem[serviceId] = {};
      lineItem[serviceId].serviceId = serviceId;

      const { price, unitLabel, defaultPrice } = services.find((service: any) => +serviceId === service.id);
      const discounts = servicesDiscounts[serviceId] || [];

      const futureCredit = +creditServicesUnits[serviceId] || 0;
      const totalUnits = +unit + futureCredit;

      lineItem[serviceId].quantity = +unit + futureCredit;
      lineItem[serviceId].unit = unitLabel;
      lineItem[serviceId].totalWithoutDiscount = +price * totalUnits;
      lineItem[serviceId].totalWithDefaultPrice = +defaultPrice * totalUnits;

      if (!discounts.length) {
        lineItem[serviceId].totalWithDiscount = lineItem[serviceId].totalWithoutDiscount;
      }

      lineItem[serviceId].discounts = [];
      discounts.forEach((discount: any) => {
        lineItem[serviceId].totalWithDiscount =
          +price * totalUnits - +calculateDiscount(discount.amount, discount.type, totalUnits * +price).toFixed(2);

        lineItem[serviceId].discounts.push({
          id: discount.id,
          value: discount.amount,
          discountType: discount.type,
          description: discount.reason,
        });
      });
      if (totalUnits !== 0) {
        lineItems.push(lineItem);
      }
    });
    Object.entries(variantsUnits).forEach(([variantItemId, unit]: any) => {
      const lineItem: any = {};
      lineItem[variantItemId] = {};
      lineItem[variantItemId].variantItemId = variantItemId;
      let variantItem: any = {};
      const { unitLabel } = services.find(({ variants }: { variants: IVariant[] }) =>
        variants.find((variant: IVariant) =>
          variant.items.find((item: IVariantItem) => {
            if (item.id === +variantItemId) {
              variantItem = item;
            }
            return item.id === +variantItemId;
          })
        )
      );

      const discounts = variantsDiscounts[variantItemId] || [];

      const futureCredit = +creditVariantsUnits[variantItemId] || 0;
      const totalUnits = +unit / +variantItem.minAmount + futureCredit / +variantItem.minAmount;

      lineItem[variantItemId].quantity = +unit + futureCredit;
      lineItem[variantItemId].unit = unitLabel;
      lineItem[variantItemId].totalWithoutDiscount = +variantItem.price * totalUnits;
      lineItem[variantItemId].totalWithDefaultPrice = +variantItem.defaultPrice * totalUnits;

      if (!discounts.length) {
        lineItem[variantItemId].totalWithDiscount = lineItem[variantItemId].totalWithoutDiscount;
      }

      lineItem[variantItemId].discounts = [];
      discounts.forEach((discount: any) => {
        lineItem[variantItemId].totalWithDiscount =
          +variantItem.price * totalUnits -
          +calculateDiscount(discount.amount, discount.type, totalUnits * +variantItem.price).toFixed(2);

        lineItem[variantItemId].discounts.push({
          id: discount.id,
          value: discount.amount,
          discountType: discount.type,
          description: discount.reason,
        });
      });
      if (totalUnits !== 0) {
        lineItems.push(lineItem);
      }
    });

    dispatch({
      type: 'newServiceVisit/applyExtraCheckoutData',
      payload: {
        note,
        brilliantValue: tenders.brilliant?.value || '',
        aspireValue: tenders.aspire?.value || '',
        careCreditValue: tenders.care_credit?.value || '',
        brilliantVoucher: tenders.brilliant?.voucher || '',
        aspireVoucher: tenders.aspire?.voucher || '',
        careCreditVoucher: tenders.care_credit?.voucher || '',
        xperienceVoucher: tenders.xperience?.voucher || '',
        xperienceValue: tenders.xperience?.value || '',
        referralsChunkIndex: selectedReferralRedeemedIndexes,
        redeemableIds: referralsIds,
        referralAmount: getReferralTotal,
        accountCreditUsed: Number(new Big(+getAccountCreditUsed)),
      },
    });

    const galdermaPayload = galdermaEmail
      ? parseGaldermaProductsAndCertificates({
          patientCertificates,
          practitionerCertificates,
          galdermaProducts: galdermaApplicableProducts,
          certificateIds: showGaldermaBox ? [] : patientCertificatesSelected,
          practitionerCertificateIds: showGaldermaBox ? [] : practitionerCertificatesSelected,
          totalServicesUnits,
          services: services.filter((service: IServices) => selectedServices.includes(service.id)),
          skusSelected,
          freeTextUsed: showGaldermaBox, // freeTextUsed field
          freeTextVoucher: tenders.aspire?.voucher
            ?.replace(/[^a-zA-Z0-9 ]/g, ' ')
            ?.split(' ')
            ?.filter((s: string) => s)
            ?.join(','),
        })
      : null;

    if (processingPayment && transactionStatus !== SQUARE_STATUS.OK && payWithCardForCharge) {
      setTransactionInProgress(true);
    }

    dispatch({
      type: 'newServiceVisit/checkout',
      payload: {
        checkout: {
          patientId: id,
          checkout: checkoutObj,
          redeemableIds: referralsIds,
          serviceVisitId,
          lineItems,
          galdermaPayload,
        },
        payWithCardForCharge: processingPayment && transactionStatus !== SQUARE_STATUS.OK ? payWithCardForCharge : null,
        successCallback: afterCheckoutCallback(processingPayment),
        errorCallback: handleCheckoutError,
        amount: toPay,
      },
    });
  };

  // When the interval receives the ok payment we refresh the checkout page to load the Galderma certificates correctly
  useEffect(() => {
    if (transactionStatus === SQUARE_STATUS.OK && isReceivingPayment) {
      window.location.reload();
    }
  }, [transactionStatus, isReceivingPayment]);

  const getOverlay = (): object => ({
    opacity: transactionInProgress || transactionStatus === SQUARE_STATUS.OK ? '0.5' : undefined,
    pointerEvents: transactionInProgress || transactionStatus === SQUARE_STATUS.OK ? 'none' : undefined,
  });

  const getRemainingTotalLabel = (): string => {
    if (transactionStatus === SQUARE_STATUS.OK) {
      return data?.processedInSquare ? 'Processed with Square' : 'Processed';
    }
    return 'Remaining to pay';
  };

  const handleToggleModal = () => {
    dispatch({ type: 'patientPointCertificates/setToggleModal' });
  };

  const payStaging = (): void => {
    dispatch({
      type: 'newServiceVisit/payStaging',
      payload: {
        serviceVisitId,
        patientId,
        amount: toPay,
        errorCallback: () => clearProcessingPaymentIntervalRef(),
      },
    });
    setOpenPayStagingModal(false);
    setTransactionInProgress(true);
  };

  if (
    Object.values(effects.newServiceVisit).some((loading: any) => loading) ||
    isLoadingFetchCheckout ||
    !serviceVisit?.id
  ) {
    return <MultipleSkeleton />;
  }

  return (
    <>
      <SaveProgressWrapper patientId={patientId} serviceVisitId={serviceVisitId}>
        <div className={classes.root}>
          <CheckoutHeader
            patientId={patientId}
            transactionStatus={transactionStatus}
            squareErrorCode={squareErrorCode}
            lastCheckoutTransactionCreatedAt={data?.lastCheckoutTransactionCreatedAt}
            processedInSquare={data?.processedInSquare}
            toPay={data?.toPay || 0}
            amountPaid={data?.amountPaid || 0}
            hasPartialRefund={data?.hasPartialRefund || false}
          />
          <div style={getOverlay()}>
            <LineItemTable accountChargeLineItems={accountChargeLineItems} />
            <DiscountsSubTotal
              subTotal={formatNumber(subTotal)}
              discounts={formatNumber(totalDiscounts)}
              total={formatNumber(total)}
            />
            {!payWithCardForCharge && <FutureCredits />}
            <OtherTenders
              setTenders={setTenders}
              setReferrals={setReferralsIds}
              toPay={toPay}
              totalTenderValue={otherTendersTotal + getReferralTotal}
              total={total}
              setReferralsGroupIndexes={setSelectedReferralRedeemedIndexes}
              showGaldermaBox={showGaldermaBox}
              setShowGaldermaBox={setShowGaldermaBox}
              discounts={totalDiscounts}
              onlyFutureUseCredit={!hasCurrentServiceUnits}
            />
            <AccountCredit
              showCurrentBalance={transactionStatus !== SQUARE_STATUS.OK}
              accountCreditUsed={accountCreditUsed}
              onChange={setAccountCreditUsed}
              currentBalance={currentBalance}
              toPay={toPay}
            />
            <AddNote note={note} setNote={setNote} />
            <ReferralsSelector showReferredLabelButton showFormIfCannotReferrer={false} />
            <RemainingToPay
              accountCreditUsed={
                transactionStatus === SQUARE_STATUS.OK ? checkout.accountCreditUsed : getAccountCreditUsed
              }
              totalLabel={getRemainingTotalLabel()}
              amountPaid={data?.amountPaid || 0}
              toPay={+toPay}
              tips={formatNumber(tips)}
            />
          </div>
        </div>
        <Footer
          initiateTransaction={initiateTransaction}
          transactionStatus={transactionStatus}
          transactionInProgress={transactionInProgress}
          processingPaymentIntervalRef={processingPaymentIntervalRef}
          isValidationLoading={!isDataCompletelyLoaded}
          totalAmount={formatNumber(+toPay)}
          isServiceVisitOpen={newServiceVisit.serviceVisit.opened}
          processedInSquare={!!data?.processedInSquare}
          isAccountChargeServiceVisit={isAccountChargeServiceVisit}
          toPay={+toPay}
        />
        <ConfirmModal
          title="Square SANDBOX"
          contentText="do you want to create a payment in the sandbox environment?"
          openDialog={openPayStagingModal}
          setOpenDialog={setOpenPayStagingModal}
          onClickConfirm={payStaging}
          confirmText="Pay"
          onClickCancel={clearProcessingPaymentIntervalRef}
        />
        <ProccessingPaymentInSquareModal proccessingPaymentInSquare={proccessingPaymentInSquare} />
        <ModalPoints
          open={toggleModal}
          state={ModalPointsStateType.POINTS_AVAILABLE}
          closeCallback={handleToggleModal}
        />
        <AccountCreditErrorModal
          open={accountCreditErrorModalOpen}
          onClose={() => setAccountCreditErrorModalOpen(false)}
        />
      </SaveProgressWrapper>
    </>
  );
}
